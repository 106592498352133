import React, { useState } from 'react';

import Header2 from "../home/Appbar2";

import WebFooter from "../../components/Web-footer";
import { Box, Grid, Typography, IconButton, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import "../../assets/css/funddetails.css"
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';



import SbiIcon from "../../assets/images/sbi.png"

// import WebFooter from "../../";



function Dashboard() {

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    return (
        <>
            <Header2 />

            <Grid className=" pan-card-margin border-top">
                <Grid item xs={12} md={12}>

                    <Box className='d-flex back-bread mt-3 mb-3' >
                        <KeyboardArrowLeftIcon /><Typography>Back</Typography>
                    </Box>
                    <Divider className="custom-hr" />
                </Grid>
            </Grid>


            <Grid container className="wrapper oder-card-margin" spacing={3}>

                <Grid item xs={12} md={8} sm={12} className='cardpl'>

                    <div className='receipt-wrapper-box2'>

                        <div className="scheme-container oder-details-status">
                            <div className="scheme-icon"> <img src={SbiIcon} alt='SbiIcon.png' /> </div>
                            <div className="scheme-content">
                                <h3 className="scheme-title">SBI PSU Direct Plan Growth</h3>
                                <p className="scheme-subtitle">New SIP</p>
                            </div>
                            <div className="scheme-amount">₹500</div>
                        </div>

                        <Divider className='status-heading-divider2' variant="fullWidth" />

                        <div className='oder-details-status'>


                            <Grid container className="transaction-row">

                                <Grid item xs={3} md={2} sm={2} lg={2}>
                                    <div className="transaction-label">Placed On</div>

                                </Grid>
                                <Grid item xs={9} md={5} sm={6} lg={6}>
                                    <div className="transaction-value">30 Sep ‘24, 10:55 AM</div>
                                </Grid>


                                <Grid item xs={3} md={2} sm={2} lg={2}>
                                    <div className="transaction-label">Paid Via</div>

                                </Grid>
                                <Grid item xs={9} md={3} sm={2} lg={2}>
                                    <div className="transaction-value">HDFC BANK</div>
                                </Grid>


                            </Grid>


                            <Grid container className="transaction-row">

                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label" style={{ marginTop: '4px' }}>Order ID</div>

                                </Grid>

                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">
                                        ABCD1234567890123456789012
                                        <IconButton
                                            size="small"
                                            className="copy-button"
                                            onClick={() => handleCopy('12345678')}
                                        >
                                            <ContentCopyIcon fontSize="small" className='copyicon' />
                                        </IconButton>
                                    </div>
                                </Grid>

                            </Grid>

                        </div>
                    </div>

                </Grid>

                <Grid item xs={12} md={4} sm={12}>

                    <div className="payment-status-container">

                        <div className="d-flex align-items-center justify-content-between oder-details-status payment-status-header">

                            <h2 className="payment-status-title">Status</h2>

                            <div className="payment-status-badge">
                                <ErrorIcon color="error" />
                                <span>Failed</span>
                            </div>

                        </div>

                        <Divider className='status-heading-divider2' variant="fullWidth" />


                        <Box className='oder-details-status' mb={3}>


                            <div className="payment-failure-item">
                                <ErrorIcon color="error" />
                                <div className="payment-failure-content">
                                    <div className="payment-failure-message">
                                        Payment failed
                                    </div>
                                    <div className="payment-failure-timestamp">
                                        30 Sep '24, 10:55 AM
                                    </div>
                                </div>
                            </div>

                            <div className="payment-refund-notice">
                                Any amount deducted will be refunded to your account in 3-5 working days.
                            </div>

                        </Box>

                    </div>
                </Grid>

            </Grid>

        </>
    );
}

export default Dashboard;
