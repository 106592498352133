import {
  GET_EXPLORE_FUNDS_LIST,
  GET_AMC_LISTS,
  GET_EXPLORE_FUNDS_DETAIL,
} from "../actions/types";

const initialState = {
  exploreFundsList: [],
  amcLists: [],
  exploreFundsDetail: {},
  loading: false,
};

const exploreFundsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPLORE_FUNDS_LIST:
      return { ...state, loading: false, exploreFundsList: action.payload };
    case GET_AMC_LISTS:
      return { ...state, loading: false, amcLists: action.payload };
    case GET_EXPLORE_FUNDS_DETAIL:
      return { ...state, loading: false, exploreFundsDetail: action.payload };
    // Add more cases for other actions here...
    default:
      return state;
  }
};

export default exploreFundsReducers;
