import React, { useState } from "react";
import {
    Box,
    Button,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Adhar from "../../assets/images/adharcardimg.svg";

const AnnualIncomeForm = () => {
    const [step, setStep] = useState(1); // Step state to manage navigation
    const [selectedIncome, setSelectedIncome] = useState("");
    const [checked, setChecked] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    const incomeRanges = [
        "Upto 1 Lakh",
        "1 Lakh - 5 Lakh",
        "5 Lakh - 10 Lakh",
        "10 Lakh - 25 Lakh",
        "25 Lakh - 50 Lakh",
        "50 Lakh - 1 Crore",
        "1 Crore - 5 Crore",
        "More than 5 Crore",
    ];

    const maritalStatuses = ["Single", "Married"];

    // Handle income selection
    const handleIncomeSelect = (income) => {
        setSelectedIncome(income);
        if (checked) {
            setStep(2); // Move to Marital Status step if checkbox is checked
        }
    };

    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
        // If checkbox is checked, and the conditions for the next step are met, move to the next step
        if (step === 1 && selectedIncome && e.target.checked) {
            setStep(2); // Move to Marital Status step if income is selected
        } else if (step === 2 && selectedStatus && e.target.checked) {
            setStep(3); // Move to Aadhaar OTP step if marital status is selected
        }
    };

    // Handle back navigation
    const handleBack = () => {
        setStep((prevStep) => prevStep - 1);
    };

    const handleMaritalStatusSelect = (status) => {
        setSelectedStatus(status);
        // Check if the checkbox is checked to move to next step
        if (checked) {
            setStep(3); // Move to Aadhaar OTP verification step if checkbox is checked
        }
    };


    return (
        <>
            {step === 1 && (
                <>
                    <IconButton aria-label="back" className="back-arrowbtn">
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        What’s your annual income?
                    </Typography>
                    <Grid container spacing={2}>
                        {incomeRanges.map((income, index) => (
                            <Grid item xs={6} key={index}>
                                <Button
                                    className="occupation-btns"
                                    variant={selectedIncome === income ? "contained" : "outlined"}
                                    fullWidth
                                    onClick={() => handleIncomeSelect(income)}
                                    sx={{
                                        textTransform: "none",
                                        color: selectedIncome === income ? "#fff" : "#000",
                                    }}
                                >
                                    {income}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                color="primary"
                                className="custom-checkbox"
                            />
                        }
                        label="I do not have any active stock broking account linked with another broker."
                        sx={{ mt: 2 }}
                    />
                </>
            )}

            {step === 2 && (
                <>
                    <IconButton aria-label="back" onClick={handleBack} className="back-arrowbtn">
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        What’s your marital status?
                    </Typography>
                    <Grid container spacing={2}>
                        {maritalStatuses.map((status, index) => (
                            <Grid item xs={6} key={index}>
                                <Button
                                    className="occupation-btns"
                                    variant={selectedStatus === status ? "contained" : "outlined"}
                                    fullWidth
                                    onClick={() => handleMaritalStatusSelect(status)}
                                    sx={{
                                        textTransform: "none",
                                        color: selectedStatus === status ? "#fff" : "#000",
                                    }}
                                >
                                    {status}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider className="custom-hr next-btn-margin" sx={{ mt: 4 }} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckboxChange}
                                color="primary"
                                className="custom-checkbox"
                            />
                        }
                        label="I am not politically-exposed (PEP) or related to PEP."
                        sx={{ mt: 2 }}
                    />
                </>
            )}
            {step === 3 && (
                <>
                    <CardContent>
                        <IconButton aria-label="back" onClick={handleBack} className="back-arrowbtn">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Verify Aadhaar using OTP
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Enter your Aadhaar and verify using OTP sent to your Aadhaar linked mobile number.
                        </Typography>
                        {/* Display Aadhaar verification section */}
                        <Box className='pan-border'>
                            <img
                                src={Adhar}
                                alt="Coming Soon!"
                                className="w-100"
                            />

                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Button className='start-sipbtn ' fullWidth sx={{ ml: 1 }}>
                                Continue
                            </Button>
                        </Box>
                    </CardContent>
                </>
            )}
        </>
    );
};

export default AnnualIncomeForm;
