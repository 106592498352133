import React from 'react';
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    IconButton,
    Stack,
    Divider
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import Header2 from "../home/Appbar2";

import "../../assets/css/funddetails.css"
import "../../assets/css/onbording.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function TransactionList() {


    const transactions = [
        {
            date: "4 Nov '24",
            title: 'SBI PSU Direct Plan Growth',
            amount: '₹500',
            type: 'SIP Instalment',
            status: 'Completed',
            statusColor: 'green',
        },
        {
            date: "30 Sep '24",
            title: 'SBI PSU Direct Plan Growth',
            amount: '₹500',
            type: 'New SIP',
            status: 'Completed',
            statusColor: 'green',
        },
        {
            date: "30 Sep '24",
            title: 'SBI PSU Direct Plan Growth',
            amount: '₹500',
            type: 'New SIP',
            status: 'Failed',
            statusColor: 'red',
        },
    ];

    return (

        <>

            <Header2 />

            <div className="wrapper pan-card-margin border-top">

                {/* <Box className="mt-10">
                </Box> */}

                <Grid container spacing={2} className="transaction-container">
                    {/* Filters Section */}

                    <Grid item xs={12} md={3} >

                        <Box className="filters-section">


                            <Box className="filters-header">
                                <Typography variant="subtitle1" className="filters-title">
                                    Filters
                                </Typography>
                                <button className="clear-button">Clear All</button>
                            </Box>
                            <Divider />
                            <Box className="filter-options">
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Investment orders"
                                    className="filter-checkbox"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Redeem orders"
                                    className="filter-checkbox"
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Switch orders"
                                    className="filter-checkbox"
                                />
                            </Box>

                        </Box>

                    </Grid>

                    {/* Transactions List */}

                    {/* <Grid item xs={12} md={9} className="transactions-section">
                        {[
                            {
                                date: '4 Nov \'24',
                                name: 'SBI PSU Direct Plan Growth',
                                amount: '₹500',
                                type: 'SIP Instalment',
                                status: 'completed'
                            },
                            {
                                date: '30 Sep \'24',
                                name: 'SBI PSU Direct Plan Growth',
                                amount: '₹500',
                                type: 'New SIP',
                                status: 'completed'
                            },
                            {
                                date: '30 Sep \'24',
                                name: 'SBI PSU Direct Plan Growth',
                                amount: '₹500',
                                type: 'New SIP',
                                status: 'failed'
                            }
                        ].map((transaction, index) => (
                            <Box key={index} className="transaction-item">
                                <Grid container alignItems="center">
                                    <Grid item xs={2}>
                                        <Typography className="transaction-date">
                                            {transaction.date}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className="transaction-name">
                                            {transaction.name}
                                        </Typography>
                                        <Typography className="transaction-type">
                                            {transaction.type}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className="transaction-amount">
                                            {transaction.amount}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className="status-section">
                                        <span className={`status-indicator ${transaction.status}`} />
                                        <Typography className="status-text">
                                            {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                                        </Typography>
                                        <IconButton className="chevron-button">
                                            <ChevronRight />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Grid> */}


                    <Grid item xs={12} md={9}>


                        <div className="transaction-list">
                            {transactions.map((transaction, index) => (
                                <div key={index} className="transaction-item">
                                    <Grid container spacing={2} className="transaction-grid" mb={2} justifyContent='space-between'>
                                        <Grid item xs={12}>
                                            <Typography mb={2} className="transaction-date font-14">
                                                {transaction.date}
                                            </Typography>
                                            <Divider />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography className="transaction-title">
                                                {transaction.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" className="transaction-amount">
                                                {transaction.amount}
                                            </Typography>
                                            <Typography variant="caption" className="transaction-type">
                                                {transaction.type}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            key={index}
                                            className="transaction-status-container"
                                        >
                                            <div className="status-info">
                                                <Typography
                                                    variant="body2"
                                                    className="transaction-status"
                                                    mr={2}
                                                >
                                                    {transaction.status}
                                                </Typography>
                                                <span
                                                    className={`status-dot ${transaction.status === 'Completed' ? 'green' : 'red'}`}
                                                ></span>
                                            </div>
                                            <ArrowForwardIosIcon mr={3} className="arrow-icon" fontSize="small" />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    {/* {index < transactions.length - 1 && <Divider />} */}
                                </div>
                            ))}
                        </div>

                    </Grid>


                </Grid>

            </div>

        </>

    );
}

