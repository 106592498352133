import axios from "axios";
import { GET_WATCH_LIST, DELETE_WATCH_LIST } from "./types";
import { toast } from "react-toastify";
import { getLocalStorageUserToken } from "../utils/userData";
const token = getLocalStorageUserToken();

export const getWatchList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_WATCH_LIST }); // Dispatching a loading state
    try {
      //   const token =
      //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNmYxNTFmZmUyYTFhYmMxM2I2MGI4ZDU0MjJhYzY1NzI1ZjJlMTIxNWE1MDI2N2ZkYzI5NDY5ZDAwNDYxYTU1YmFlODc4ZTJkNTVlYmJjOWYiLCJpYXQiOjE3MzY5MjUxMzYuMDExNDgzLCJuYmYiOjE3MzY5MjUxMzYuMDExNDg2LCJleHAiOjE3Njg0NjExMzYuMDAwMjQyLCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.U_pCrJ_QqOBsy2r8apTi8AmhGK9TTpWGFMKScMebx_Rw4V8OjGUvxZTE_1Tpi_fWWLiBuhmvU4Z3vlvxUN55j8ZOU-WzVhNj9ChTtGl-Uca3qBkQSydQ0zAU7cHShmFJbkoLd3L3iUJ2xHOSzQrzzlp7UzJQSPIS709elmUQ_kq9volzBsLeF5FVzln6yxhmabKBbwU2FoXVyKThFI5Y0k6JGtfsbc21Ec5WS9G6PryUwVzqFSBvgchfVGY9KsColTPhI6x8LQLy16IwRjNSogrAbYTWFTZQQjGpfQfTwCTBTWzYTNuFurmoA42gPr-GVbyFz5vFgI6Z0ALRuE_0r_CEM0Gpoah_uM6nTotkvlo9dUk2UYUbzvK2SpGRZFgc19pynFV7R9Si5kMwL0SmgYf1OmZqQ_e804HfJ6clMLRC9zQ9BafiZPAHNZXwQTRWxQ8--IpDo9802VGU45_Ph90KnofKu5nScxLkfAkG5JLWtq41Ep15uKWf9yvZANlFcIapZ_WB5TH5cbW7LQ7_KU8eOjTAXEempjRfU33tSDYODe_MZah6DUP87-Dm_ZdVNk503uAKY8xl4a3uv3xEpEA9fvpCJl3IBTeunM4ir35asnmfsquFEs48x6Ymj5U2JxBCEZkwJxXo3rYSvLrC-Nt7b9qv3wOQHNNq742tp3o";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}watchlist/show`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: GET_WATCH_LIST, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_WATCH_LIST, error: error.message }); // Dispatching an error state if there's a problem
    }
  };
};

export const deleteWatchList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_WATCH_LIST }); // Dispatching a loading state
    // API call to delete watchlist item
    try {
      //   const token =
      //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNmYxNTFmZmUyYTFhYmMxM2I2MGI4ZDU0MjJhYzY1NzI1ZjJlMTIxNWE1MDI2N2ZkYzI5NDY5ZDAwNDYxYTU1YmFlODc4ZTJkNTVlYmJjOWYiLCJpYXQiOjE3MzY5MjUxMzYuMDExNDgzLCJuYmYiOjE3MzY5MjUxMzYuMDExNDg2LCJleHAiOjE3Njg0NjExMzYuMDAwMjQyLCJzdWIiOiI3Iiwic2NvcGVzIjpbXX0.U_pCrJ_QqOBsy2r8apTi8AmhGK9TTpWGFMKScMebx_Rw4V8OjGUvxZTE_1Tpi_fWWLiBuhmvU4Z3vlvxUN55j8ZOU-WzVhNj9ChTtGl-Uca3qBkQSydQ0zAU7cHShmFJbkoLd3L3iUJ2xHOSzQrzzlp7UzJQSPIS709elmUQ_kq9volzBsLeF5FVzln6yxhmabKBbwU2FoXVyKThFI5Y0k6JGtfsbc21Ec5WS9G6PryUwVzqFSBvgchfVGY9KsColTPhI6x8LQLy16IwRjNSogrAbYTWFTZQQjGpfQfTwCTBTWzYTNuFurmoA42gPr-GVbyFz5vFgI6Z0ALRuE_0r_CEM0Gpoah_uM6nTotkvlo9dUk2UYUbzvK2SpGRZFgc19pynFV7R9Si5kMwL0SmgYf1OmZqQ_e804HfJ6clMLRC9zQ9BafiZPAHNZXwQTRWxQ8--IpDo9802VGU45_Ph90KnofKu5nScxLkfAkG5JLWtq41Ep15uKWf9yvZANlFcIapZ_WB5TH5cbW7LQ7_KU8eOjTAXEempjRfU33tSDYODe_MZah6DUP87-Dm_ZdVNk503uAKY8xl4a3uv3xEpEA9fvpCJl3IBTeunM4ir35asnmfsquFEs48x6Ymj5U2JxBCEZkwJxXo3rYSvLrC-Nt7b9qv3wOQHNNq742tp3o";
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}watchlist/remove`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: DELETE_WATCH_LIST, payload: response.data });
      dispatch(getWatchList({ id: payload?.id }));
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      dispatch({ type: DELETE_WATCH_LIST, error: error.message }); // Dispatching an error state if there's a problem
      toast.error(error.message);
    }
  };
};

export const addedWatchList = (payload) => {
  return async (dispatch) => {
    // API call to add watchlist item
    try {
      // const token = process.env.REACT_APP_API_TOKEN;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}watchlist/update`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch(getWatchList({ id: payload.id }));
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching watchlist items:", error.message);
      toast.error(error.message);
    }
  };
};
