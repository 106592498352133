// StepUpSIPPopup.js
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    MenuItem,
    Select,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    IconButton,
    TextField,
    ButtonGroup,
    Grid,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PercentIcon from '@mui/icons-material/Percent'; // Import Percent Icon
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";



const ChangeAmountDatePopup = ({ open, handleClose, }) => {
    const [selectedDate, setSelectedDate] = useState(6); // Default selected date
    const [amount, setAmount] = useState(5000); // Default SIP amount
    const [showCalendar, setShowCalendar] = useState(true); // State to toggle calendar visibility

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const [value, setValue] = useState(0); // Store the value of the amount or percentage
    const handleInputChange = (event) => {
        setValue(event.target.value);
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth maxWidth="xs"
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Edit SIP amount & date</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

            </DialogTitle>
            <DialogContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} >
                    <Box display='flex' justifyContent='space-between' alignItems='center' mr={3}>
                        <Typography className='font-14' mr={1}>New SIP Amount</Typography>
                    </Box>
                    {/* Conditional rendering based on selected option */}
                    <Box className='sip-aamount-box' display="flex" alignItems="center" justifyContent="space-between">
                        <CurrencyRupeeIcon fontSize="small" />
                        <TextField
                            value={value}
                            onChange={handleInputChange}
                            variant="standard"
                            sx={{ width: 80 }}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{
                                style: { textAlign: "right", color: 'rgba(11, 36, 194, 1)' }, // Align text to the right
                            }}
                            type="number"
                        />
                    </Box>
                </Box>
                {/* SIP Date */}
                <Box mb={2}>
                    <Box display='flex' justifyContent='space-between'>

                        <Typography variant="subtitle1">New SIP date</Typography>


                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography
                                variant="body1"
                                className='text-blue'
                            >
                                {selectedDate}th of every month
                            </Typography>
                            <IconButton onClick={toggleCalendar}>
                                {showCalendar ? <ExpandLessIcon className='text-blue' /> : <ExpandMoreIcon className='text-blue' />}
                            </IconButton>
                        </Box>
                    </Box>
                    {showCalendar && (
                        <Grid container spacing={1} columns={6} justifyContent="center" className='popup-calender-border' mt={2}>
                            {[...Array(30)].map((_, index) => {
                                const date = index + 1; // Calculate the date (1-30)
                                const isActive = selectedDate === date; // Check if this date is the selected one

                                return (
                                    <Grid item xs={1} key={index}>
                                        <Button
                                            onClick={() => handleDateChange(date)} // Handle date change on click
                                            className={`calender-btn-popup ${isActive ? 'active' : 'inactive'}`} // Apply active or inactive class
                                        >
                                            {date}
                                        </Button>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Box>

            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                <Button
                    variant="contained"
                    fullWidth
                    className="start-sipbtn"
                    onClick={handleClose}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeAmountDatePopup;
