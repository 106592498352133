import axios from "axios";
import { GET_NOTIFICATION_LIST } from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
const token = getLocalStorageUserToken();

export const getNotificationList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_NOTIFICATION_LIST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}notifications`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: GET_NOTIFICATION_LIST, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATION_LIST, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching explore funds list:", error.message);
    }
  };
};

export const markAsRead = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}notifications/read`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch(getNotificationList(payload));
    } catch (error) {
      console.error("Error marking notification as read:", error.message);
    }
  };
};
