import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Link,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  MenuItem,
  Divider,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
} from "@mui/material";
import UPI from "../assets/images/upi.svg";
import HDFC from "../assets/images/htdf.svg";
import Info from "../assets/images/info.svg";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EventIcon from "@mui/icons-material/Event";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Autopay from "./autopay";
import Arrow from "../assets/images/arrow.png";
import Authorisedotp from "./authorised-otp";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function Invest({ onBack, hideHeader = false, investValue }) {
  // console.log("v", investValue);
  const [tabValue, setTabValue] = useState(0);
  const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [showAutopay, setShowAutopay] = useState(false); // State to toggle Autopay component
  const [showAuthorisedOTP, setShowAuthorisedOTP] = useState(false); // State for AuthorisedOTP component

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const validationSchema = Yup.object({
    amount: Yup.number()
      .required("Amount is required")
      .min(100, "Minimum SIP amount should be 100")
      .max(100000, "Maximum SIP amount should be 100000"),
    date:
      tabValue === 0 // Only validate the date if Monthly SIP tab is selected
        ? Yup.string()
            .matches(/^\d{1,2}(st|nd|rd|th)$/, "Enter a valid date like 8th")
            .required("SIP date is required")
        : Yup.string(), // No validation for One-Time tab
  });

  const handleAutoPayClick = () => {
    setShowAutopay(true); // Show Autopay component
  };

  const handleStartSIP = () => {
    setShowAuthorisedOTP(true); // Show AuthorisedOTP component
  };

  const handleBackToInvest = () => {
    setShowAutopay(false); // Navigate back from Autopay
    setShowAuthorisedOTP(false); // Navigate back from AuthorisedOTP
  };

  // Conditionally render components
  if (showAutopay) {
    return <Autopay onBack={handleBackToInvest} />;
  }

  if (showAuthorisedOTP) {
    return <Authorisedotp onBack={handleBackToInvest} />;
  }
  const handleFormSubmit = (values) => {
    console.log("Form Submitted with values: ", values);
    handleStartSIP();
  };

  return (
    <>
      {console.log("investValue", investValue)}
      <Formik
        initialValues={{ amount: "", date: "" }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {investValue ? (
          ({ values, handleChange, handleSubmit }) => (
            <Form>
              {!hideHeader && (
                <>
                  <Typography
                    align="center"
                    className="font-600 watchlist-textpadding"
                    sx={{ mt: 2 }}
                  >
                    {investValue}
                  </Typography>
                  <Divider className="custom-hr" sx={{ my: 2 }} />
                </>
              )}
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                  className="sip-tabs"
                >
                  <Tab label="Monthly SIP" />
                  <Tab label="One-Time" />
                </Tabs>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                className="watchlist-textpadding"
              >
                <Typography>
                  SIP Amount <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  className="sip-aamount-box"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CurrencyRupeeIcon fontSize="small" />
                  {/* <Typography sx={{ fontWeight: 'bold' }}>5000</Typography> */}
                  <Field
                    as={TextField}
                    name="amount"
                    variant="standard"
                    size="small"
                    placeholder="0"
                    value={values.amount}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <ErrorMessage
                  name="amount"
       x           component="div"
                  className="text-error"
                  style={{
                    color: "red",
                    fontSize: "16px",
                    marginRight: "10px",
                    whiteSpace: "nowrap",
                  }}
                />
              </Box>
              {tabValue === 0 && (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    className="watchlist-textpadding"
                  >
                    <Typography>
                      Monthly SIP Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      className="sip-aamount-box"
                      justifyContent="space-between"
                    >
                      <EventIcon fontSize="small" />
                      <Field
                        as={TextField}
                        name="date"
                        variant="standard"
                        size="small"
                        placeholder="8th"
                        value={values.date}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <ErrorMessage
                      name="date"
                      component="div"
                      className="text-error"
                      style={{
                        color: "red",
                        fontSize: "16px",
                        marginRight: "10px",
                        whiteSpace: "nowrap",
                      }}
                    />
                  </Box>
                </>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                gutterBottom
              >
                Next SIP instalment is on 8th Nov
              </Typography>
              <Divider className="custom-hr" sx={{ my: 2 }} />
              <Box>
                <Box className="d-flex justify-between watchlist-textpadding">
                  <Box>
                    <Typography className="font-600 ">
                      Pay via AutoPay
                    </Typography>

                    <Typography sx={{ mb: 2, mt: 2 }} className="d-flex">
                      <img src={HDFC} alt="hdfc" />
                      <Typography sx={{ ml: 1 }}> HDFC BANK....1234</Typography>
                    </Typography>
                  </Box>
                  <IconButton onClick={handleAutoPayClick}>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
              <Divider className="custom-hr" sx={{ my: 2 }} />
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ mb: 2, mt: 2 }}
                className="watchlist-textpadding"
              >
                <Button className="add-cartbtn" fullWidth sx={{ mr: 1 }}>
                  Add to Cart
                </Button>
                <Button
                  className="start-sipbtn"
                  fullWidth
                  sx={{ ml: 1 }}
                  type="submit"
                >
                  Start SIP
                </Button>
              </Box>
            </Form>
          )
        ) : (
          <>
            <>
              <div style={{ padding: "40px 0" }}>
                <div className="arrow-container">
                  <img src={Arrow} alt="Arrow" className="arrow-image" />
                </div>
                <p style={{ textAlign: "center", paddingTop: "10px" }}>
                  Select your mutual fund to <br />
                  view investing options
                </p>
              </div>
            </>
          </>
        )}
      </Formik>
    </>
  );
}

export default Invest;
