import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  CardActions,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import Signature from "../../assets/images/signature-svg.svg"
import Signature2 from "../../assets/images/signature-2.png";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const SelectBank = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const sigCanvas = useRef(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureDataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      console.log("Saved Signature: ", signatureDataURL);
      alert("Signature saved successfully!");
      // Save signatureDataURL to your backend or handle it as needed
    }
  };

  return (
    <>
      <div className="pin-setup-header">
        <Header2 />
      </div>

      <div className="bank-selection-container">
        <Card className="bank-selection-card bob-selection-card">
          <CardContent className="bob-card-content">
            <div className="dob-container">
              <KeyboardBackspaceIcon className="back-button-kyc" />

              <Typography className="form-title">
                Submit your signature
              </Typography>

              {/* <Typography variant="subtitle2" className="form-subtitle">
                                Provide your valid signature for verification
                            </Typography> */}

              <div
                className="d-flex align-items-center justify-content-center"
                style={{ marginTop: "40px" }}
              >
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 300,
                    height: 300,
                    className: "signature-canvas",
                  }}
                />
              </div>
            </div>
          </CardContent>

          <CardActions className="kyc-card-actions">
            <Stack
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              direction="row"
              spacing={3}
            >
              <Button
                variant="contained"
                onClick={clearSignature}
                className="signature-reset"
              >
                Reset
              </Button>

              <Button
                variant="contained"
                onClick={saveSignature}
                className="signature-submit"
              >
                Submit
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </div>
    </>
  );
};

export default SelectBank;
