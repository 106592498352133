import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import Frame from "../../src/assets/images/Frame.svg";
import { Link } from "react-router-dom";


const NomineeDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
        mb: 3,
        overflowY: "auto",
        border: "1px solid rgba(237, 237, 237, 1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
    >
      <Box mt={6} mb={3}>
        <img
          src={Frame}
          alt="No Nominee Illustration"
        />
      </Box>
      <Typography
        mb={3}
        variant="h6"
        sx={{ marginBottom: 2 }}
      >
        No nominee added
      </Typography>

      <Box mb={6}>
        <Button
          variant="contained"
          color="primary"
          className="start-sipbtn"
          component={Link}
          to="/addnominee"
        >
          Declare Nominee
        </Button>
      </Box>
    </Box>
  );
};

export default NomineeDetails;
