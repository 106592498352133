import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  Button,
  IconButton,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import BankDetails from "../../assets/images/BankDetails.svg";
import CustomerSupport from "../../assets/images/CustomerSupport.svg";
import Reports from "../../assets/images/Reports.svg";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Settings from "../../assets/images/Settings.svg";
import AllOrder from "../../assets/images/AllOrder.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DialogTitle from "@mui/material/DialogTitle";
import MisshikaLogo from "../../assets/images/Logo.svg";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./Login";
import LoginPopup from "./LoginPopup";
import "../../assets/css/Login.css";
import { useLocation } from "react-router-dom";
import "../../assets/css/header.css"; // External CSS
import Profile from "../../assets/images/profileicon.svg";
import AppBarMobile from "./AppbarMobile";
import { LocalGroceryStoreOutlined } from "@mui/icons-material";
import { getLocalStorageUserData, logoutUser } from "../../utils/userData";

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  // const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  // const handleClose1 = () => {
  //     setOpen(false);
  // };

  // ----------//

  // New popup Logic

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null); // State for "More" menu
  const userData = getLocalStorageUserData();

  const handleAllOrdersClick = () => {
    navigate("/allorders");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMoreMenuOpen = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreMenuClose = () => {
    setMoreAnchorEl(null);
  };

  return (
    <>
      {/* Desktop for Mobile Views */}
      <div className="desktop-content">
        <AppBar position="static" className="header-container web-header-bm">
          <Toolbar className="toolbar">
            <div className="logo-mishika">
              <a href="/">
                <img src={MisshikaLogo} alt="logo" />
              </a>
            </div>
            <Box className="nav-links">
              <Button
                variant="text"
                component={Link}
                to="/about-us"
                // className="nav-link"
                className={`nav-link ${
                  location.pathname === "/about-us" ? "selected" : ""
                }`}
              >
                About Us
              </Button>
              <Button
                variant="text"
                component={Link}
                to="/contact"
                //className="nav-link"
                className={`nav-link ${
                  location.pathname === "/contact" ? "selected" : ""
                }`}
              >
                Contact Us
              </Button>

              {/* More Dropdown */}
              <Button
                variant="text"
                className="nav-link"
                onClick={handleMoreMenuOpen}
              >
                More Urls
              </Button>

              <Menu
                anchorEl={moreAnchorEl}
                open={Boolean(moreAnchorEl)}
                onClose={handleMoreMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  component={Link}
                  to="/pan-details"
                  onClick={handleMoreMenuClose}
                >
                  PAN Details
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/occupation-form"
                  onClick={handleMoreMenuClose}
                >
                  Occupation
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/setuppin"
                  onClick={handleMoreMenuClose}
                >
                  Set up PIN
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/selectbank"
                  onClick={handleMoreMenuClose}
                >
                  Select Bank
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/dob"
                  onClick={handleMoreMenuClose}
                >
                  DOB
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/verifylocation"
                  onClick={handleMoreMenuClose}
                >
                  Verify location
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/selfiekyc"
                  onClick={handleMoreMenuClose}
                >
                  Selfie KYC
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/signature"
                  onClick={handleMoreMenuClose}
                >
                  Signature
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/nominee"
                  onClick={handleMoreMenuClose}
                >
                  Nominee
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/finishsetup"
                  onClick={handleMoreMenuClose}
                >
                  Finish account setup
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/mutualfund-details"
                  onClick={handleMoreMenuClose}
                >
                  Mutual Fund Details
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/allmutualfunds"
                  onClick={handleMoreMenuClose}
                >
                  All Mutual Funds
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/orderdetails"
                  onClick={handleMoreMenuClose}
                >
                  Order Details
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/externalfunds"
                  onClick={handleMoreMenuClose}
                >
                  Import external funds
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/allorders"
                  onClick={handleMoreMenuClose}
                >
                  All Orders
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/orderdetailscmplt"
                  onClick={handleMoreMenuClose}
                >
                  New SIP Completed
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/rderdetailsfailed"
                  onClick={handleMoreMenuClose}
                >
                  New SIP - Failed
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/view-transactions"
                  onClick={handleMoreMenuClose}
                >
                  View Transactions
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/start-swp"
                  onClick={handleMoreMenuClose}
                >
                  Start SWP
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/start-stp"
                  onClick={handleMoreMenuClose}
                >
                  Start STP
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/switch-fund"
                  onClick={handleMoreMenuClose}
                >
                  Switch Funds
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/mysips"
                  onClick={handleMoreMenuClose}
                >
                  My SIPs
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/sip-details"
                  onClick={handleMoreMenuClose}
                >
                  SIP Details
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/bankdetails"
                  onClick={handleMoreMenuClose}
                >
                  Bank Details
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/addbank"
                  onClick={handleMoreMenuClose}
                >
                  Add bank
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/addbankverify"
                  onClick={handleMoreMenuClose}
                >
                  Add bank verify
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/editifsc"
                  onClick={handleMoreMenuClose}
                >
                  Edit IFSC code
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/userdetails"
                  onClick={handleMoreMenuClose}
                >
                  Sidebar Menu
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/helpsupport"
                  onClick={handleMoreMenuClose}
                >
                  Help & Support
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/mycart"
                  onClick={handleMoreMenuClose}
                >
                  My Cart
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/watchlist"
                  onClick={handleMoreMenuClose}
                >
                  Watchlist
                </MenuItem>
              </Menu>
            </Box>
            {/* <Button variant="contained" className="login-button" onClick={handleClickOpen}>Login / Register</Button> */}
            {userData ? (
              <Box className="profile-menu-container">
                <Tooltip title="Profile">
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img src={Profile} alt="profile icon" />
                  </IconButton>
                </Tooltip>

                {/* <Button variant="contained" className="login-button login-btn-mobile" onClick={handleClickOpen}>
                            Login / Register</Button> */}
              </Box>
            ) : (
              <Button
                variant="contained"
                className="login-button"
                onClick={handleClickOpen}
              >
                Login / Register
              </Button>
            )}

            <LoginPopup open={open} onClose={handleClose} />
          </Toolbar>
        </AppBar>
      </div>
      {/* Desktop for Mobile Views */}
      {/* ------------------------------ */}
      {/* Desktop for Mobile Views */}
      <div className="mobile-content">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <div>
              <AppBarMobile />
            </div>
            <div className="logo-mishika-mobile">
              <Link to="/">
                <img src={MisshikaLogo} alt="logo" />
              </Link>
            </div>
          </Stack>

          <Box className="profile-menu-container">
            {userData ? (
              <>
                <Tooltip title="Profile">
                  <IconButton
                    color="inherit"
                    onClick={() => navigate("/dashboard")}
                  >
                    <img src={Profile} alt="profile icon" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <Button
                variant="contained"
                className="login-button login-btn-mobile"
                onClick={handleClickOpen}
              >
                Login / Register
              </Button>
            )}
          </Box>
        </Stack>
      </div>
      {/* Sidebar for Mobile Views */}
      {/* <Dialog
                className="custom-dialog"
                open={open}
                onClose={handleClose1}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose1}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{ p: 0 }}
                >
                    <Login />
                </DialogContent>
            </Dialog > */}
    </>
  );
}
