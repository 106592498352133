import React, { useRef, useState, Fragment } from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { Box, styled } from "@mui/material";

function OTP({ separator, length, value, formik, inputStyles }) {
  const inputRefs = useRef(new Array(length).fill(null));

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput?.focus();
  };

  const handleChange = (event, index) => {
    const val = event.target.value;
    if (/^\d*$/.test(val)) {
      const newValue =
        value.substring(0, index) + val + value.substring(index + 1);
      formik.setFieldValue("otp", newValue);
      //   onChange(newValue);
      if (val && index < length - 1) focusInput(index + 1);
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !value[index] && index > 0) {
      focusInput(index - 1);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mb: 2 }}>
      {new Array(length).fill(null).map((_, index) => (
        <Fragment key={index}>
          <BaseInput
            slots={{ input: StyledInput }}
            slotProps={{
              input: {
                ref: (el) => (inputRefs.current[index] = el),
                value: value[index] || "",
                onChange: (e) => handleChange(e, index),
                onKeyDown: (e) => handleKeyDown(e, index),
                inputMode: "numeric",
                maxLength: 1,
                style: inputStyles, // apply custom styles here
              },
            }}
          />
          {index < length - 1 && separator}
        </Fragment>
      ))}
    </Box>
  );
}

export default function OTPInput({ inputStyles, separatorWidth = 8, formik }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <OTP
        separator={<span style={{ width: separatorWidth }} />}
        value={formik?.values?.otp}
        length={6}
        formik={formik}
        inputStyles={inputStyles}
      />
    </Box>
  );
}

const StyledInput = styled("input")({
  width: "40px",
  height: "40px",
  fontSize: "1.2rem",
  textAlign: "center",
  border: "none",
  borderBottom: "2px solid #C4C4C4",
  backgroundColor: "transparent",
  color: "#000",
  "&:focus": {
    borderBottom: "2px solid #0047FF",
    outline: "none",
  },
  "@media (max-width: 600px)": {
    "&": {
      // Add this to ensure specificity
      width: "22px !important",
      height: "22px",
    },
  },
});

// const StyledInput = styled('input')({
//     width: '40px',
//     height: '40px',
//     fontSize: '1.2rem',
//     textAlign: 'center',
//     border: 'none',
//     borderBottom: '2px solid #C4C4C4',
//     backgroundColor: 'transparent',
//     color: '#000',
//     '&:focus': {
//         borderBottom: '2px solid #0047FF',
//         outline: 'none',
//     },
// });
