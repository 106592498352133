// mui base input opt

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "../../assets/css/Login.css";
import AuthGoogle from "./authgoogle";
import OTPInput from "./opt";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkedcircle from "../../assets/images/Checkedcircle.svg";
import axios from "axios";
import { sendLoginEmail } from "../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { sanitizeInput } from "../../utils/sanitixeInput";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordVerifyOtp,
  mailOtpVerification,
  registerViaEmail,
  reSendOtp,
  setUserPassword,
  userLogin,
  userRegisterViaGoogle,
  userVerifyLogin,
} from "../../actions/userActions";
import { getTime } from "../../utils/userData";

export default function SignInSide(props) {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1); // Step to manage UI display
  const [isForgotPassword, setIsForgotPassword] = useState(false); // Flag to show forgot password UI
  const [disabledOtp, setDisabledOtp] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showTerms, setShowTerms] = useState(true); // To manage the display of terms text
  const [disable, setDisable] = useState(false);
  const [verifyUserData, setVerifyUserData] = useState("");
  const [message, setMessage] = useState("");
  const [sendOtp, setSendOtp] = useState(false);
  const [otp_time, setOtp_time] = useState(getTime());
  const [attempt, setAttempt] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.users);

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .test(sanitizeTest("Email"))
      .email("Enter a valid email address.")
      .required("Email is required"),
    login_with:
      isForgotPassword == true
        ? Yup.string()
            .oneOf(["Password", "otp"], "Invalid login method") // Ensure login_with is one of the expected values
            .required("Login method is required")
        : "",
    password: Yup.string().when("login_with", (login_with, schema) => {
      return login_with[0] === "Password"
        ? schema.required("Password is required")
        : schema;
    }),
    new_password:
      step == 3
        ? Yup.string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters")
            .matches(
              /[A-Z]/,
              "Password must contain at least one uppercase letter"
            )
            .matches(
              /[a-z]/,
              "Password must contain at least one lowercase letter"
            )
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(
              /[!@#$%^&*]/,
              "Password must contain at least one special character"
            )
        : "",
    password_confirm:
      step == 3
        ? Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("new_password")], "Passwords must match")
        : "",
    otp: Yup.string().when("verifyOtp", (verifyOtp, schema) => {
      return verifyOtp[0] === true
        ? schema
            .required("OTP is required")
            .length(6, "OTP must be exactly 6 digits")
        : schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: "",
      verifyOtp: false,
      new_password: "",
      password_confirm: "",
      login_with: "otp",
      did_agree: false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisable(true);
      // const payload = {
      //   ...values,
      //   dob: formatDate,
      //   id: userData?.id,
      //   completed_wizard_step: 0,
      // };
      if (!isForgotPassword && step == 1) {
        //call verify email api aleady exiest or not
        // if()
        console.log("verifyUserData", verifyUserData);

        const registerViaEmailPayload = {
          email: values?.email,
        };
        dispatch(registerViaEmail(registerViaEmailPayload))
          .then((response) => {
            if (
              response.status == true &&
              response.message == "Gmail Id already exixts." &&
              response.is_email_verified == 1
            ) {
              toast.success("Gmail is alreday Register, Please login");
              setStep(4);
              setDisable(false);
            } else if (response.status == true) {
              setOtp_time(response?.otp_time);
              setAttempt(response?.attempt);
              if (
                response.is_email_verified == 0 &&
                response.message ==
                  "OTP sent over your Email. OTP is valid for 2 minutes only."
              ) {
                toast.success(response.message);
              } else {
                toast.success(response.message);
              }
              setStep(2);
              setDisable(false);
            }
          })
          .catch(() => {
            setDisable(false);
          });

        // setStep(2); // if user first time login render verify otp screen
        // setStep(4); // user login as a register user call otp and password screen
      }
      // if (
      //   step == 1 &&
      //   values.login_with === "otp" &&
      //   values.verifyOtp == false
      // ) {
      //   // call send otp api
      //   formik.setFieldValue("verifyOtp", true);
      // }

      if (!isForgotPassword && step == 2) {
        const mailOtpVerificationPayload = {
          email: values?.email,
          email_verification: 1,
          otp: values?.otp,
          otp_time: otp_time,
          attempt: attempt,
        };
        dispatch(mailOtpVerification(mailOtpVerificationPayload))
          .then((response) => {
            if (response.status) {
              toast.success(response?.message);
              setStep(3); // if user first time login render set password screen
            } else {
              if (response.message == "Invalid OTP") {
                setAttempt((attempt) => attempt + 1);
              }
              toast.error(response?.message);
            }
            setDisable(false);
          })
          .catch((error) => {
            setDisable(false);
          });
        //call verify otp api
      }

      if (!isForgotPassword && step == 3) {
        // call login api and render dashboard page
        // setStep(0); // render dashboard page
        const userPasswordPayload = {
          email: values?.email,
          new_password: values?.new_password,
          password_confirm: values?.password_confirm,
        };
        dispatch(setUserPassword(userPasswordPayload))
          .then((response) => {
            if (response.status) {
              setMessage("Password set successfully..");
              setOpen(true);
              // props.onClose();
              setDisable(false);
            } else {
              setDisable(false);
            }
          })
          .catch((error) => {
            setDisable(false);
          });
      }
      if (!isForgotPassword && step == 4) {
        // call with password or otp api
        const loginPayloadWithPassword = {
          email: values?.email,
          password: values?.password,
          login_with: "Password",
        };
        const loginPayloadWithOtp = {
          email: values?.email,
          login_with: "otp",
        };
        if (sendOtp == true) {
          const userVerifyLoginPayload = {
            email: values?.email,
            otp: values?.otp,
            otp_time: users?.otp_time,
            attempt: attempt,
          };
          dispatch(userVerifyLogin(userVerifyLoginPayload))
            .then((response) => {
              if (response.status == true) {
                props.onClose();
                setDisable(false);
              } else {
                setAttempt(response?.attempt);
                setDisable(false);
              }
            })
            .catch((error) => {
              setDisable(false);
            });
        } else {
          dispatch(
            userLogin(
              values?.login_with == "otp"
                ? loginPayloadWithOtp
                : loginPayloadWithPassword
            )
          )
            .then((response) => {
              if (response.status == true) {
                if (values?.login_with == "otp") {
                  setAttempt(response?.attempt);
                  setSendOtp(true);
                  formik.setFieldValue("verifyOtp", true);
                } else {
                  props.onClose();
                }

                setDisable(false);
              } else {
                if (values?.login_with == "otp") {
                  setSendOtp(false);
                  setDisable(false);
                  formik.setFieldValue("verifyOtp", false);
                } else {
                  props.onClose();
                }
              }
            })
            .catch((error) => {
              setSendOtp(false);
              setDisable(false);
              formik.setFieldValue("verifyOtp", false);
            });
        }

        // props.onClose();
      }

      if (isForgotPassword && step == 1) {
        // call with set forgot password otp api
        // formik.setFieldValue("verifyOtp", true);
        const reSendOtpPayload = {
          email: values?.email,
        };
        dispatch(reSendOtp(reSendOtpPayload))
          .then((response) => {
            if (response.status == true) {
              formik.setFieldValue("verifyOtp", true);
              toast.success(response.message);
              setStep(2);
              setOtp_time(response?.otp_time);
              setAttempt(response.attempt);
              setDisable(false);
            } else {
              toast.error(response.message);
              setDisable(false);
            }
          })
          .catch((error) => {
            setDisable(false);
          });
      }

      if (isForgotPassword && step == 2) {
        // call with set forgot password otp api
        const forgotPasswordVerifyOtpPayload = {
          email: values?.email,
          otp: values?.otp,
          otp_time: otp_time,
          attempt: attempt,
        };
        dispatch(forgotPasswordVerifyOtp(forgotPasswordVerifyOtpPayload))
          .then((response) => {
            if (response.status) {
              toast.success(response.message);
              setStep(3);
            } else {
              toast.error(response.message);
            }
          })
          .catch((error) => {
            setDisable(false);
          });
      }

      if (isForgotPassword && step == 3) {
        // call with update password api
        const userPasswordPayload = {
          email: values?.email,
          new_password: values?.new_password,
          password_confirm: values?.password_confirm,
        };
        dispatch(setUserPassword(userPasswordPayload))
          .then((response) => {
            if (response.status) {
              setMessage("Password update successfully..");
              setOpen(true);
              // props.onClose();
              setDisable(false);
            } else {
              setDisable(false);
            }
          })
          .catch((error) => {
            setDisable(false);
          });
      }
      // dispatch(userOnboardingStoreData(payload, userData?.id));
    },
  });

  const reSend = () => {
    setDisabledOtp(true);
    if (!disabledOtp) {
      const reSendOtpPayload = {
        email: formik?.values?.email,
      };
      dispatch(reSendOtp(reSendOtpPayload))
        .then((response) => {
          if (response.status == true) {
            toast.success(response.message);
            setOtp_time(response?.otp_time);
            setAttempt(response.attempt);
            setTimeout(() => {
              setDisabledOtp(false);
            }, 60000);
          } else {
            toast.error(response.message);
          }
        })
        .catch((error) => {});
    }
  };
  const sendLoginEmailToUser = async () => {
    const loadingToast = toast.loading("Processing...");

    try {
      // if (email === "" || email === undefined || email === null) {
      //   toast.error("Email is required");
      //   return false;
      // }
      // let values = {
      //   email: email,
      // };
      const response = await axios.post(sendLoginEmail, { email });
      if (response.data.success) {
        toast.success("Thank you for reaching out!");
      } else {
        toast.error(
          "There was an error sending your message. Please try again later."
        );
      }
    } catch (error) {
      console.error(error);
      toast.dismiss(loadingToast);
      toast.error(
        "There was an error sending your message. Please try again later."
      );
    } finally {
      toast.dismiss(loadingToast);
      // props.onClose(); this code was uncommented
      setEmail("");
    }
  };

  // Handle Forgot Password Link Click
  const handleForgotPasswordClick = () => {
    formik.resetForm();
    setIsForgotPassword(true);
    setStep(1); // Reset to step 1 when navigating to Forgot Password flow
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/");
    props.onClose();
  };

  return (
    <>
      <Grid container className="dialog-main-box">
        <Grid item xs={false} sm={4} md={6}>
          <Box className="banner-image"></Box>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <div className="form-paper">
            {/* <Typography component="h1" variant="h5" className='login-text'>
                            {isForgotPassword ? 'Forgot Password?' : 'Login'}
                        </Typography> */}
            <Typography component="h1" variant="h5" className="login-text">
              {isForgotPassword
                ? step === 1
                  ? "Forgot Password?"
                  : "Reset Password"
                : step == 2
                ? "Verify Email"
                : "Login/Register"}
            </Typography>
            {step === 1 && !isForgotPassword && (
              <>
                <AuthGoogle />
                <Box className="mb-3">
                  <label>OR,</label>
                </Box>
              </>
            )}
            <form className="sign-form" onSubmit={formik.handleSubmit}>
              <Grid container>
                {/* Always display Email Field */}
                <Grid item xs={12}>
                  <label>Email</label>
                  <TextField
                    id="email"
                    fullWidth
                    value={formik.values.email}
                    name="email"
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder="Enter your Email"
                    className="email-input inputbg"
                    sx={{ mt: 2 }}
                    disabled={step == 1 ? false : true}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </Grid>
                {/* Forgot Password flow */}
                {isForgotPassword && (
                  <>
                    {step === 1 && (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          className="login-button-submit"
                          type="submit"
                          sx={{ mt: 2 }}
                        >
                          Send OTP
                        </Button>
                      </Grid>
                    )}
                    {step === 2 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <label>Enter a 6-digit OTP sent to this Email</label>
                          <Box className="d-flex align-items-center justify-content-start">
                            <OTPInput
                              value={formik.values.otp}
                              length={6}
                              formik={formik}
                            />
                          </Box>
                          {formik.touched.otp && formik.errors.otp ? (
                            <div className="text-danger">
                              {formik.errors.otp}
                            </div>
                          ) : null}
                          <p style={{ color: "#3E4154" }}>
                            Didn't receive OTP?{" "}
                            <span
                              style={{
                                color: "#0B24C2",
                                textDecoration: "underline",
                              }}
                              onClick={reSend}
                            >
                              Resend
                            </span>
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="login-button-submit"
                            type="submit"
                            sx={{ mt: 2 }}
                          >
                            Change Password
                          </Button>
                        </Grid>
                      </>
                    )}

                    {step === 3 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <label>Enter New Password</label>
                          <TextField
                            id="new-password"
                            fullWidth
                            variant="outlined"
                            name="new_password"
                            placeholder="Enter new password"
                            type="password"
                            className="password-input inputbg"
                            value={formik?.values?.new_password}
                            onChange={formik?.handleChange}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        {formik.touched.new_password &&
                        formik.errors.new_password ? (
                          <div className="text-danger">
                            {formik.errors.new_password}
                          </div>
                        ) : null}
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <label>Confirm New Password</label>
                          <TextField
                            id="confirm-password"
                            fullWidth
                            variant="outlined"
                            placeholder="Confirm new password"
                            type="password"
                            name="password_confirm"
                            className="password-input inputbg"
                            value={formik?.values?.password_confirm}
                            onChange={formik?.handleChange}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        {formik.touched.password_confirm &&
                        formik.errors.password_confirm ? (
                          <div className="text-danger">
                            {formik.errors.password_confirm}
                          </div>
                        ) : null}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            className="login-button-submit"
                            type="submit"
                            // onClick={handleUpdatePasswordClick}
                            sx={{ mt: 2 }}
                          >
                            Update Password
                          </Button>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {/* Login flow */}
                {!isForgotPassword && (
                  <>
                    {/* Step 2: Radio Buttons for OTP/Password selection */}
                    {step === 4 && (
                      <>
                        <Grid item xs={12}>
                          <FormControl sx={{ mt: 3 }}>
                            <label>Login With</label>
                            <RadioGroup
                              row
                              value={formik.values.login_with}
                              onChange={(e) => {
                                formik?.setFieldValue(
                                  "login_with",
                                  e.target.value
                                );
                              }}
                              sx={{ my: 1 }}
                              aria-labelledby="login-method-radio-buttons-group-label"
                              name="login_with"
                            >
                              <FormControlLabel
                                value="otp"
                                control={<Radio />}
                                label="OTP"
                                checked={formik?.values.login_with === "otp"}
                              />
                              <FormControlLabel
                                value="Password"
                                control={<Radio />}
                                label="Password"
                                checked={
                                  formik?.values.login_with === "Password"
                                }
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {formik.values.login_with === "otp" && (
                          <>
                            <Grid item xs={12}>
                              <label>
                                Enter a 6-digit OTP sent to this Email
                              </label>
                              <Box className="d-flex align-items-center justify-content-start">
                                <OTPInput
                                  value={formik.values.otp}
                                  length={6}
                                  formik={formik}
                                />
                              </Box>
                              {formik.touched.otp && formik.errors.otp ? (
                                <div className="text-danger">
                                  {formik.errors.otp}
                                </div>
                              ) : null}
                              <p style={{ color: "#3E4154" }}>
                                Didn't receive OTP?{" "}
                                <span
                                  style={{
                                    color: "#0B24C2",
                                    textDecoration: "underline",
                                  }}
                                  onClick={reSend}
                                >
                                  Resend
                                </span>
                              </p>
                            </Grid>
                          </>
                        )}
                        {formik.values.login_with === "Password" && (
                          <Grid item xs={12}>
                            <label>Password</label>
                            <TextField
                              id="password"
                              fullWidth
                              variant="outlined"
                              placeholder="Enter your Password"
                              type="password"
                              name="password"
                              className="password-input inputbg"
                              value={formik?.values?.password}
                              onChange={formik?.handleChange}
                              sx={{ mt: 1 }}
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-danger">
                                {formik.errors.password}
                              </div>
                            ) : null}
                          </Grid>
                        )}
                        {formik.values.login_with === "Password" &&
                          step === 4 && (
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className="forgot-password-link"
                                onClick={handleForgotPasswordClick}
                                sx={{
                                  mt: 2,
                                  cursor: "pointer",
                                  color: "#0B24C2",
                                }}
                              >
                                Forgot Password?
                              </Typography>
                            </Grid>
                          )}
                      </>
                    )}

                    {step == 3 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <label>Enter New Password</label>
                          <TextField
                            id="new-password"
                            fullWidth
                            variant="outlined"
                            name="new_password"
                            placeholder="Enter new password"
                            type="password"
                            className="password-input inputbg"
                            value={formik?.values?.new_password}
                            onChange={formik?.handleChange}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        {formik.touched.new_password &&
                        formik.errors.new_password ? (
                          <div className="text-danger">
                            {formik.errors.new_password}
                          </div>
                        ) : null}
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <label>Confirm New Password</label>
                          <TextField
                            id="confirm-password"
                            fullWidth
                            variant="outlined"
                            placeholder="Confirm new password"
                            type="password"
                            name="password_confirm"
                            className="password-input inputbg"
                            value={formik?.values?.password_confirm}
                            onChange={formik?.handleChange}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        {formik.touched.password_confirm &&
                        formik.errors.password_confirm ? (
                          <div className="text-danger">
                            {formik.errors.password_confirm}
                          </div>
                        ) : null}
                      </>
                    )}

                    {step == 2 && (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <label>Enter a 6-digit OTP sent to this Email</label>
                        <Box className="d-flex align-items-center justify-content-start">
                          <OTPInput
                            value={formik.values.otp}
                            length={6}
                            formik={formik}
                          />
                        </Box>
                        {formik.touched.otp && formik.errors.otp ? (
                          <div className="text-danger">{formik.errors.otp}</div>
                        ) : null}
                        <p style={{ color: "#3E4154" }}>
                          Didn't receive OTP?{" "}
                          <span
                            style={{
                              color: "#0B24C2",
                              textDecoration: "underline",
                            }}
                            onClick={reSend}
                          >
                            Resend
                          </span>
                        </p>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className="login-button-submit"
                        type="submit"
                        disabled={disable}
                        sx={{ mt: 2 }}
                      >
                        {step === 1
                          ? "Continue"
                          : formik.values.login_with === "otp" &&
                            step == 4 &&
                            sendOtp == false
                          ? "Send OTP"
                          : "Submit"}
                      </Button>
                    </Grid>
                    {/* Forgot Password Link: Show only below the password field */}
                  </>
                )}
                {/* Terms Text: Show only on step 1 and hide after continue */}
                {showTerms && step === 1 && (
                  <Grid item xs={12}>
                    <Typography variant="body2" className="login-terms">
                      By continuing, I agree to the{" "}
                      <span>
                        <b>
                          {" "}
                          <a
                            href="/terms-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            {" "}
                            Terms of Use{" "}
                          </a>
                        </b>
                      </span>{" "}
                      <br />
                      and{" "}
                      <span>
                        <b>
                          {" "}
                          <a
                            href="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            Privacy Policy.
                          </a>
                        </b>
                      </span>
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Box mt={5}></Box>
            </form>
          </div>
        </Grid>
      </Grid>

      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: "dialog-container",
        }}
      >
        <DialogTitle>
          <img
            src={Checkedcircle}
            className="dialog-circleicon"
            alt="Checkedcircle"
          />
        </DialogTitle>
        <DialogContent className="change-pass-content">
          <Typography variant="h6" className="dialog-title-pass">
            {message}
          </Typography>
        </DialogContent>
        <DialogActions className="change-dialog-actions">
          <Button
            onClick={handleClose}
            variant="contained"
            className="dialog-button-change"
          >
            Continue to Login
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
