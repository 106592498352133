import {
  USER_LOGIN,
  USER_VERIFY_LOGIN,
  USER_FORGOT_PASSWORD,
  USER_CHANGE_PASSWORD,
  REGISTER_VIA_EMAIL,
  USER_START,
  MAIL_OTP_VERIFICATION,
  SET_USER_PASSWORD,
  RESEND_OTP,
  FORGOT_PASSWORD_VERIFY_OTP,
  USER_SET_PIN,
  REGISTER_VIA_GOOGLE,
  USER_MOBILE_NUMBER_SENDOTP,
  USER_MOBILE_NUMBER_VERIFYOTP,
} from "./types";
import axiosInstance from "../api/axios";
import { encryptData } from "../utils/userData";
import { toast } from "react-toastify";
import { getLocalStorageUserToken } from "../utils/userData";
const token = getLocalStorageUserToken();
// Action to fetch posts
export const userLogin = (payload, type) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}login`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_LOGIN, payload: response.data });
      if (response?.data?.status == true) {
        if (response?.data?.token) {
          const encryptedUserData = encryptData(response.data?.data);
          const encryptedUserToken = encryptData(response.data?.token);
          const encryptedUserId = encryptData(response.data?.data?.id);
          localStorage.setItem("userData", encryptedUserData);
          localStorage.setItem("token", encryptedUserToken);
          localStorage.setItem("userId", encryptedUserId);

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 400);
        }
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
      return response?.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_LOGIN, error: error.message });
    }
  };
};

export const userVerifyLogin = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}verify-login`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_VERIFY_LOGIN, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        if (response?.data?.token) {
          const encryptedUserData = encryptData(response.data?.data);
          const encryptedUserToken = encryptData(response.data?.token);
          const encryptedUserId = encryptData(response.data?.data?.id);
          localStorage.setItem("userData", encryptedUserData);
          localStorage.setItem("token", encryptedUserToken);
          localStorage.setItem("userId", encryptedUserId);

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 400);
        }
      } else {
        sessionStorage.setItem("attempt", response?.data?.attempt);
        toast.error(response.data.message);
      }
      return response.data;
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_VERIFY_LOGIN, error: error.message });
    }
  };
};

export const userForgotPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}forgot-password`,
        payload
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_FORGOT_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_FORGOT_PASSWORD, error: error.message });
    }
  };
};

export const userChangePassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}change-password`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      // Assuming the response data is in response.data
      dispatch({ type: USER_CHANGE_PASSWORD, payload: response.data });
      if (response?.data?.status !== false) {
        toast.success(response?.data?.message);
        localStorage.removeItem("userData");
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // Handling errors (e.g., network error or server error)
      dispatch({ type: USER_CHANGE_PASSWORD, error: error.message });
    }
  };
};

export const registerViaEmail = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}register-via-mail`,
        payload
      );
      dispatch({ type: REGISTER_VIA_EMAIL, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: REGISTER_VIA_EMAIL, error: error.message });
    }
  };
};

export const mailOtpVerification = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}verify-otp`,
        payload
      );
      dispatch({ type: MAIL_OTP_VERIFICATION, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: MAIL_OTP_VERIFICATION, error: error.message });
    }
  };
};

export const setUserPassword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}change-password`,
        payload
      );
      dispatch({ type: SET_USER_PASSWORD, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: SET_USER_PASSWORD, error: error.message });
    }
  };
};

export const reSendOtp = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}resend-otp`,
        payload
      );
      dispatch({ type: RESEND_OTP, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: RESEND_OTP, error: error.message });
    }
  };
};

export const forgotPasswordVerifyOtp = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}verify-otp`,
        payload
      );
      dispatch({ type: FORGOT_PASSWORD_VERIFY_OTP, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: FORGOT_PASSWORD_VERIFY_OTP, error: error.message });
    }
  };
};

export const userSetPin = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}user/setup-pin`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: USER_SET_PIN, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: USER_SET_PIN, error: error.message });
    }
  };
};

export const userRegisterViaGoogle = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}register-via-google`,
        payload
      );
      if (response?.data?.status == true) {
        if (response?.data?.message == "Gmail Id already exixts.") {
          toast.success("User login succesfully..");
        } else {
          toast.success(response.message);
        }
        if (response?.data?.token) {
          const encryptedUserData = encryptData(response.data?.user);
          const encryptedUserToken = encryptData(response.data?.token);
          const encryptedUserId = encryptData(response.data?.user?.id);
          localStorage.setItem("userData", encryptedUserData);
          localStorage.setItem("token", encryptedUserToken);
          localStorage.setItem("userId", encryptedUserId);

          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 400);
        }
      }
      dispatch({ type: REGISTER_VIA_GOOGLE, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: REGISTER_VIA_GOOGLE, error: error.message });
    }
  };
};

export const userMobileNumber = (payload) => {
  return async (dispatch) => {
    dispatch({ type: USER_START });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}send-otp-mob`,
        payload,
        {
          headers: {
            Authorization: `${token}`, // Pass the token here
          },
        }
      );
      dispatch({ type: USER_MOBILE_NUMBER_SENDOTP, payload: response.data });
      return response.data;
    } catch (error) {
      dispatch({ type: USER_MOBILE_NUMBER_SENDOTP, error: error.message });
    }
  };
};
