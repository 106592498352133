import React, { useState } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Import the jwt-decode library
import "../../assets/css/Login.css";
import { userRegisterViaGoogle } from "../../actions/userActions";
import { useDispatch } from "react-redux";

export default function GoogleAuth() {
  // Success callback after successful login
  const dispatch = useDispatch();
  const handleSuccess = (response) => {
    // Decode the Google token and extract user data
    const decodedData = jwtDecode(response.credential);

    console.log("decodedData", decodedData);

    if (decodedData) {
      const payload = {
        email: decodedData?.email,
        email_verified: decodedData?.email_verified,
      };
      dispatch(userRegisterViaGoogle(payload));
    }
  };

  // Error callback for failed login
  const handleError = () => {
    console.log("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId="437510069713-s43bc6srg86i2ta4aloddq9f8eh2k2vu.apps.googleusercontent.com">
      <div
        className="w-full max-w-md mx-auto authgoogle"
        style={{ marginBottom: "30px" }}
      >
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          type="standard"
          theme="filled_blue"
          size="large"
          text="signin_with"
          shape="rectangular"
          locale="en"
        />
      </div>
    </GoogleOAuthProvider>
  );
}
