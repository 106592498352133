import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    Divider,

} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import Fund1 from '../assets/images/birla.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Invest from '../components/investsip';
import Redeem from '../components/redeem';
import { useNavigate } from 'react-router-dom';

function ViewTransation() {
    const [showRedeem, setShowRedeem] = useState(false);
    const navigate = useNavigate();

    const backButton =()=>{
        navigate("/dashboard")
    }

    // Function to handle the Redeem button click
    const handleRedeemClick = () => {
        setShowRedeem(true); // Show the Redeem component
    };

    // Function to navigate back to the Invest component
    const handleBack = () => {
        setShowRedeem(false); // Show the original content
    };

    return (
        <>
            <Header2 />
            <Grid className=" pan-card-margin  border-top">
                <Grid item xs={12} md={12}>
                    <Box className='d-flex back-bread mt-3 mb-3' >
                        <KeyboardArrowLeftIcon /><Typography onClick={backButton} style={{cursor:'pointer'}}>Back</Typography>
                    </Box>
                    <Divider className="custom-hr" />
                </Grid>
            </Grid>
            <div className="wrapper pan-card-margin pan-topmar">
                <Grid container textAlign="start" spacing={2}>
                    <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>
                        {/* <Grid container textAlign="start" spacing={2}> */}
                        <Box className="watchlist-card">
                            <Box className="card-content">
                                <Box>
                                    <Box className='d-flex trans-cardpad' sx={{ mt: 3 }}>
                                        <img src={Fund1} alt="fund card img" className='birla-cardimg' />
                                        <Box sx={{ ml: 2 }}>
                                            <Typography variant='h5' fontWeight={600} className='card-head-text'>Aditya Birla Sun Life PSU Equity Fund Direct Growth</Typography>
                                            <Typography className='font-14' sx={{ mt: 1 }}>Folio Number: <span style={{ fontWeight: "600" }}>123456789012</span></Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ my: 2 }} />
                                    <Box className='d-flex trans-cardpad' sx={{ mt: 2, mb: 2 }}>
                                        <Grid container textAlign="start" spacing={4} justifyContent='space-between'>
                                            <Grid item>
                                                <Grid container textAlign="start" spacing={5}>
                                                    <Grid item xs={6} md={6} className='card-wrap'>
                                                        <Typography sx={{ mb: 1 }}>Current Amount</Typography>
                                                        <Typography sx={{ mb: 1 }}>Invested Amount</Typography>
                                                        <Typography sx={{ mb: 1 }}>Returns</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} >
                                                        <Typography sx={{ mb: 1 }} ><b>₹466</b></Typography>
                                                        <Typography sx={{ mb: 1 }} ><b>₹500</b></Typography>
                                                        <Typography className='text-red' sx={{ mb: 1 }}><b>-₹34 (-6.80%)</b></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid container textAlign="start" spacing={6}>
                                                    <Grid item xs={6} md={6} className='card-wrap'>
                                                        <Typography sx={{ mb: 1 }}>Balanced Units</Typography>
                                                        <Typography sx={{ mb: 1 }}>Current NAV</Typography>
                                                        <Typography sx={{ mb: 1 }}>Average NAV</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} md={6} >
                                                        <Typography sx={{ mb: 1 }}><b>13.495</b></Typography>
                                                        <Typography sx={{ mb: 1 }}><b>34.5398</b></Typography>
                                                        <Typography sx={{ mb: 1 }}><b>37.05</b></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider sx={{ my: 2 }} />
                                    <Box className='trans-cardpad text-end' sx={{ mb: 2 }}>
                                        <Button className='add-cartbtn' onClick={handleRedeemClick} >
                                            Redeem
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Grid container textAlign="start" spacing={2} sx={{ mt: 6 }}>
                            <Grid item xs={12} md={12} >
                                <Box sx={{ mb: 2 }}>
                                    <Typography variant="h6" className="explore-sections-head" >Transaction History</Typography>
                                </Box>
                                <Box className="watchlist-card">
                                    <Box className="card-content">
                                        <Box>
                                            <Box className='d-flex trans-cardpad' sx={{ mt: 2, mb: 2 }}>
                                                <Grid container textAlign="start" spacing={4} justifyContent='space-between'>
                                                    <Grid item xs={12} md={4} lg={4}>
                                                        <Grid container textAlign="start" spacing={5}>
                                                            <Grid item xs={6} md={6} className='card-wrap'>
                                                                <Typography sx={{ mb: 1 }}><b>Invested</b></Typography>
                                                                <Typography sx={{ mb: 1 }}>30 Sep 2024</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={4}>
                                                        <Grid container textAlign="start" spacing={6}>
                                                            <Grid item className='card-wrap'>
                                                                <Typography sx={{ mb: 1 }}>Units</Typography>
                                                                <Typography sx={{ mb: 1 }}> NAV</Typography>
                                                            </Grid>
                                                            <Grid item >
                                                                <Typography sx={{ mb: 1 }}><b>13.495</b></Typography>
                                                                <Typography sx={{ mb: 1 }}><b>34.5398</b></Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} lg={4} display='flex' >
                                                        <Grid container textAlign="start" spacing={5} justifyContent='end'>
                                                            <Grid item>
                                                                <Typography className='text-end' sx={{ mb: 1 }} ><b>₹500</b></Typography>
                                                                <Typography className='text-green' sx={{ mb: 1 }}>COMPLETED</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="watchlist-card" sx={{ mt: 3 }}>
                                    <Box className="card-content">
                                        <Box className='d-flex justify-between trans-cardpad' sx={{ mt: 2, mb: 2 }}>
                                            <Box>
                                                <Typography>
                                                    <b> Start Systematic Withdrawal Plan</b>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <KeyboardArrowRightIcon className='text-blue' />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="watchlist-card" sx={{ mt: 3 }}>
                                    <Box className="card-content">
                                        <Box className='d-flex justify-between trans-cardpad' sx={{ mt: 2, mb: 2 }}>
                                            <Box>
                                                <Typography>
                                                    <b> Start Systematic Transfer Plan</b>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <KeyboardArrowRightIcon className='text-blue' />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* </Grid> */}
                    </Grid>
                    <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
                        <Box className="watchlist-card">
                            {showRedeem ? (
                                <Redeem onBack={handleBack} /> // Show Redeem component
                            ) : (
                                <Invest hideHeader={true} /> // Show Invest component
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </div>
            {/* <DashboardInvestment />
            <PopularFunds />
            <WebFooter /> */}
        </>
    );
}

export default ViewTransation;
