import React, { useEffect, useRef, useState } from "react";

import "../assets/css/footer.css";
import logo from "../assets/images/footer-logo.svg";
import facebook from "../assets/images/facebook-icon.svg";
import insta from "../assets/images/instagram-icon.svg";
// import twitter from "../assets/images/twitter-icon.svg";
import linkedin from "../assets/images/linkedin-logo.svg";
import email from "../assets/images/email.png";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Gplay from "../assets/images/gplay.svg";
import Appstore from "../assets/images/appstore.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  const emailRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    // Check if the element is overflowing
    const emailElement = emailRef.current;
    if (emailElement.scrollWidth > emailElement.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, []);

  return (
    <div id="footer">
      <div className="wrapper">
        <Box className="first-Col space-sm">
          <Card className="footer-card">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo} alt="footer-logo" />
              </Link>
            </div>
            <Box className="img-container">
              <div className="Col">
                <div className="list-container2 list-container1">
                  <div className="footer-contact-content list-container">
                    {/* <img
                      className="social-icons-footer"
                      src={email}
                      alt="email"
                    /> */}
                    <div className="social-icons-footer">
                      <MailOutlineIcon />
                    </div>
                    <a
                      href="mailto:service@misshikacapital.com"
                      target="_balcnk"
                      style={{ textDecoration: "none" }}
                      className="footer-link-light footer-email-link"
                      ref={emailRef}
                      title={isTruncated ? "service@misshikacapital.com" : ""} // Show title only if truncated
                    >
                      service@misshikacapital.com
                    </a>
                  </div>
                  {/* <div className="social-icons">
                    <div className="social-icon-single">
                      <img
                        className="icons-footer"
                        src={facebook}
                        alt="email"
                      />
                    </div>
                    <div className="social-icon-single">
                      <a target="_blank" className="footer-link-light">
                        <img
                          className="icons-footer"
                          src={insta}
                          alt="instagram"
                        />
                      </a>
                    </div>
                    <div className="social-icon-single">
                      <img
                        className="icons-footer"
                        src={linkedin}
                        alt="email"
                      />
                    </div>
                  </div> */}
                  {/* ----------------------------- */}
                  <div className="social-icons1">
                    <Box>
                      <Stack direction="row" spacing={2}>
                        <a
                          href="https://www.linkedin.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mui-icon-container linkedin"
                        >
                          <LinkedInIcon className="icon-size" />
                        </a>
                        <a
                          href="https://www.facebook.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mui-icon-container facebook"
                        >
                          <FacebookSharpIcon className="icon-size" />
                        </a>
                        <a
                          href="https://www.instagram.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mui-icon-container instagram"
                        >
                          <InstagramIcon className="icon-size" />
                        </a>
                      </Stack>
                    </Box>
                  </div>
                </div>
              </div>
            </Box>
          </Card>
          <Card className="footer-card">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Company</a>
                <a href="/about-us" className="footer-link-light">
                  About Us
                </a>
                <a href="/contact" className="footer-link-light">
                  Contact us
                </a>
                {/* <Link
                  to="/mutualfund-details"
                  state={{
                    serviceType: "other",
                  }}
                  className="footer-link-light"
                >
                  Privacy Policy
                </Link>
                <a href="/mutualfund-details" className="footer-link-light">
                  Terms & Conditions
                </a> */}
              </div>
            </Box>
          </Card>
          <Card className="footer-card">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Mutual Funds</a>
                <a href="/explore" className="footer-link-light">
                  Explore Funds
                </a>
                <a href="/mutualfund-details" className="footer-link-light">
                  High Return Funds
                </a>
                <Link
                  to="/mutualfund-details"
                  state={{
                    serviceType: "other",
                  }}
                  className="footer-link-light"
                >
                  SIP with 500
                </Link>
                <a href="/mutualfund-details" className="footer-link-light">
                  Tax Saving
                </a>
                <a href="/mutualfund-details" className="footer-link-light">
                  Large Cap
                </a>
                <a href="/mutualfund-details" className="footer-link-light">
                  Mid Cap
                </a>
                <a href="/mutualfund-details" className="footer-link-light">
                  Small Cap
                </a>
              </div>
            </Box>
          </Card>
          <Card className="footer-card quick-link-custom">
            <Box className="img-container">
              <div className="list-container">
                <a className="no-hover">Quick Links</a>
                <a href="/privacy-policy" className="footer-link-light">
                  Privacy Policy
                </a>
                <a href="/terms-conditions" className="footer-link-light">
                  Terms & Conditions
                </a>
              </div>
            </Box>
          </Card>
        </Box>
        <Divider />
        {/* --------------- */}
        <div className="footer-bottom">
          <div className="botton-container">
            <div>
              <p className="copyright">
                © 2024 Misshika Capital, All rights reserved.
                <span>
                  {" "}
                  Site by{" "}
                  <a
                    className="sideby-links"
                    href="https://www.codeplateau.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Codeplateau{" "}
                  </a>{" "}
                </span>
              </p>
              {/* <div>
                <img src={Gplay} alt="gplay" />
                <img src={Appstore} alt="app store" />
              </div> */}
            </div>
            <section className="popular-section">
              <p className="foot-title">MOST POPULAR ON MISSHIKA CAPITAL</p>
              <p className="subsection-title">POPULAR MUTUAL FUNDS:</p>
              <div className="link-list">
                <span>QUANT SMALL CAP FUND | </span>
                <span>ICICI PRUDENTIAL COMMODITIES FUND | </span>
                <span>NIPPON INDIA SMALL CAP FUND | </span>
                <span>PARAG PARIKH FLEXI CAP FUND | </span>
                <span>GROWW NIFTY TOTAL MARKET INDEX FUND | </span>
                <span>SBI SMALL MIDCAP FUND | </span>
                <span>TATA DIGITAL INDIA FUND | </span>
                <span>AXIS SMALL CAP FUND | </span>
                <span>ICICI PRUDENTIAL TECHNOLOGY FUND | </span>
                <span>HDFC INDEX FUND SENSEX PLAN | </span>
                <span>HDFC SMALL CAP FUND | </span>
                <span>AXIS EQUITY FUND | </span>
                <span>CANARA ROBECO SMALL CAP FUND | </span>
                <span>TATA SMALL CAP FUND | </span>
                <span>UTI NIFTY FUND</span>
              </div>
            </section>

            <section className="companies-section">
              <p className="subsection-title">MUTUAL FUNDS COMPANIES:</p>
              <div className="link-list">
                <span>SBI | </span>
                <span>AXIS | </span>
                <span>HDFC | </span>
                <span>UTI | </span>
                <span>NIPPON INDIA | </span>
                <span>ICICI PRUDENTIAL | </span>
                <span>TATA | </span>
                <span>KOTAK | </span>
                <span>DSP | </span>
                <span>CANARA ROBECO | </span>
                <span>SUNDARAM | </span>
                <span>MIRAE ASSET | </span>
                <span>IDFC | </span>
                <span>FRANKLIN TEMPLETON | </span>
                <span>PPFAS | </span>
                <span>MOTILAL OSWAL | </span>
                <span>INVESCO | </span>
                <span>EDELWEISS | </span>
                <span>ADITYA BIRLA SUN LIFE | </span>
                <span>LIC | </span>
                <span>HSBC | </span>
                <span>NAVI | </span>
                <span>QUANTUM | </span>
                <span>UNION | </span>
                <span>ITI | </span>
                <span>MAHINDRA MANULIFE | </span>
                <span>360 ONE | </span>
                <span>BOI | </span>
                <span>TAURUS | </span>
                <span>JM FINANCIAL | </span>
                <span>PGIM | </span>
                <span>SHRIRAM | </span>
                <span>BARODA BNP PARIBAS | </span>
                <span>QUANT | </span>
                <span>WHITEOAK CAPITAL | </span>
                <span>TRUST | </span>
                <span>SAMCO | </span>
                <span>NJ</span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
