import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  List,
  ListItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/helpSupport.css";

const HelpSupport = () => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("KYC");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedItem(null); // Reset selected item when tab changes
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <Header2 />
      <Box className="help-support-container">
        <Typography className="help-support-title">Stuck Somewhere?</Typography>
        <TextField
          placeholder="Search for your issue"
          variant="outlined"
          fullWidth
          className="help-support-search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className="help-support-tabs support-tabs-selected support-tabs-bg"
          >
            <Tab label="My Account" />
            <Tab label="Payments & Withdrawals" />
            <Tab label="Mutual Funds" />
            <Tab label="Most Visited FAQs" />
          </Tabs>
        </Box>
        <Box className="help-support-grid">
          <Box className="help-support-sidebar">
            {tabValue === 0 && (
              <List>
                {[
                  "KYC",
                  "Misshika Capital Account",
                  "Bank Accounts",
                  "Others",
                ].map((item) => (
                  <ListItem
                    key={item}
                    button
                    onClick={() => handleItemClick(item)}
                    className={`help-support-list-item ${
                      selectedItem === item ? "selected" : ""
                    }`}
                  >
                    {item}
                  </ListItem>
                ))}
              </List>
            )}
          </Box>

          <Box className="help-support-main">
            {tabValue === 0 && selectedItem === "KYC" && (
              <Box>
                <Accordion defaultExpanded elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="help-support-accordion-title">
                      Why do I need to reverify my KYC?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="help-support-accordion-content">
                      To buy new funds, your KYC must be confirmed by a central
                      agency (KRA) as per SEBI rules. If not confirmed, your
                      orders may be rejected. Your KYC might be unconfirmed due
                      to reasons like incomplete Aadhaar verification,
                      unverified contact details, or changes in your personal
                      information (Name, Date of Birth, Address). In such cases,
                      we need to update your KYC records by redoing the KYC
                      (reKYC) and submitting it to the Exchanges.
                      <br />
                      <br />
                      To fix this, go to your home page and click on 'Verify
                      Now' in the message board to complete the KYC process
                      again. It may take 5-7 working days to confirm.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="help-support-accordion-title">
                      How to complete my account creation?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="help-support-accordion-content">
                      To complete your account creation, ensure all mandatory
                      details like your PAN, Aadhaar, and bank account
                      information are submitted. Follow the on-screen steps in
                      the application and upload the necessary documents when
                      prompted. Once all steps are completed, your account will
                      be verified and activated within 2-3 working days.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="help-support-accordion-title">
                      Do you charge for processing KYC?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="help-support-accordion-content">
                      No, we do not charge any fees for processing your KYC. The
                      KYC process is completely free and is part of our
                      compliance with regulatory requirements.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}

            {selectedItem !== "KYC" && selectedItem && (
              <>
                <Typography className="help-support-accordion-title">
                  {selectedItem}
                </Typography>
                <Typography
                  className="help-support-accordion-content"
                  sx={{ pt: 3 }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam eget urna nec nunc volutpat volutpat. Mauris venenatis
                  tincidunt velit, et auctor turpis fermentum non. Sed nec
                  volutpat libero. Fusce dapibus efficitur libero, in maximus
                  orci lacinia ut. Aenean suscipit mauris id lacus vehicula, vel
                  scelerisque erat gravida. Curabitur sit amet eros at velit
                  condimentum sodales.
                </Typography>
              </>
            )}

            {/* -------------- */}

            {tabValue === 1 && (
              <Typography className="help-support-tab-heading">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                eget urna nec nunc volutpat volutpat. Mauris venenatis tincidunt
                velit, et auctor turpis fermentum non. Sed nec volutpat libero.
                Fusce dapibus efficitur libero, in maximus orci lacinia ut.
                Aenean suscipit mauris id lacus vehicula, vel scelerisque erat
                gravida. Curabitur sit amet eros at velit condimentum sodales.
              </Typography>
            )}
            {tabValue === 2 && (
              <Typography className="help-support-tab-heading">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                eget urna nec nunc volutpat volutpat. Mauris venenatis tincidunt
                velit, et auctor turpis fermentum non. Sed nec volutpat libero.
                Fusce dapibus efficitur libero, in maximus orci lacinia ut.
                Aenean suscipit mauris id lacus vehicula, vel scelerisque erat
                gravida. Curabitur sit amet eros at velit condimentum sodales.
              </Typography>
            )}
            {tabValue === 3 && (
              <Typography className="help-support-tab-heading">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                eget urna nec nunc volutpat volutpat. Mauris venenatis tincidunt
                velit, et auctor turpis fermentum non. Sed nec volutpat libero.
                Fusce dapibus efficitur libero, in maximus orci lacinia ut.
                Aenean suscipit mauris id lacus vehicula, vel scelerisque erat
                gravida. Curabitur sit amet eros at velit condimentum sodales.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HelpSupport;
