import React, { useState } from 'react';

import Header2 from "../home/Appbar2";


import { Box, Grid, Typography, IconButton, Divider, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import "../../assets/css/funddetails.css"
import "../../assets/css//mycard.css"

import SbiIcon from "../../assets/images/sbi.png"
import AdityaBirlaIcon from "../../assets/images/AdityaBirla-Icon.svg"
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import ProceedtoPay from "./Proceedtopay"
import { useNavigate } from 'react-router-dom';

// import WebFooter from "../../";



function Dashboard() {
    const navigate = useNavigate();
    
    const FundsDetails = ()=>{
        navigate("/allmutualfunds");
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    return (
        <>
            <Header2 />

            <div className="wrapper border-top mt-6">


                <Box className=" ">
                </Box>

                <Grid container className="receipt-wrapper" spacing={3}>



                    <Grid item xs={12} md={8} sm={12} className='pl-0'>


                        <Box sx={{ mb: 4 }}>
                            <Typography className='mycard-header'>  My Cart (2) </Typography>
                        </Box>


                        <div className='receipt-wrapper-box'>

                            <div className="scheme-container">
                                <div className="mycard-icon"> <img src={SbiIcon} alt='SbiIcon.png' /> </div>
                                <div className="scheme-content">
                                    <h5 className="mycard-title-text">SBI PSU Direct Plan Growth</h5>
                                </div>
                                <div className="scheme-amount">

                                    <CloseIcon sx={{ color: '#565A71' }} />

                                </div>
                            </div>

                            <Box>
                                <Divider />
                            </Box>

                            <Grid container>
                                {/* Amount Section */}
                                <Grid item xs={12} className="amount-section">
                                    <Typography variant="h6" className="mycard-amount">
                                        ₹500
                                    </Typography>
                                    <Typography variant="body1" className="mycard-description">
                                        Monthly SIP on 14th
                                    </Typography>
                                    <div className="edit-icon">

                                        <EditIcon />

                                    </div>
                                </Grid>

                            </Grid>


                        </div>

                        {/* --------------- */}

                        <div className='receipt-wrapper-box mt-2'>

                            <div className="scheme-container">
                                <div className="mycard-icon"> <img src={AdityaBirlaIcon} alt='AdityaBirlaIcon.png' /> </div>
                                <div className="scheme-content">
                                    <h5 className="mycard-title-text">Aditya Birla Sun Life PSU Equity Fund Direct Growth</h5>
                                </div>
                                <div className="scheme-amount">

                                    <CloseIcon sx={{ color: '#565A71' }} />

                                </div>
                            </div>

                            <Box>
                                <Divider />
                            </Box>

                            <Grid container>
                                {/* Amount Section */}
                                <Grid item xs={12} className="amount-section">
                                    <Typography variant="h6" className="mycard-amount">
                                        ₹500
                                    </Typography>
                                    <Typography variant="body1" className="mycard-description">
                                        One-time
                                    </Typography>

                                </Grid>

                            </Grid>


                        </div>

                        <div className='d-flex align-items-center justify-content-center'>
                            <Button variant='text' className='morefunds-btn' startIcon={<AddCircleIcon />} onClick={FundsDetails}>  Add more funds  </Button>
                        </div>

                    </Grid>


                    <Grid item xs={12} md={4} sm={12}>

                        <div >
                            <ProceedtoPay />
                        </div>


                    </Grid>

                </Grid>


            </div>

        </>
    );
}

export default Dashboard;
