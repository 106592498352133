import React, { useState } from "react";
import { Box, Typography, Divider, Button, TextField, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VerifyMobilePopup from "./Userverifypopup";
import NomineeDetails from "./nominee-details";
import ActiveDevices from "./active-devices";
import Reports from "./profiledetails/profile-reports";
import PassChanged from '../../src/components/passchanged';
import EmailPopup from "./EmailPopup";


const Content = ({ page }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [tooltipTitle, setTooltipTitle] = useState("Copy Name");
  const [tooltipTitleBOID, setTooltipTitleBOID] = useState("Copy BOID");
  const [tooltipTitleUCC, setTooltipTitleUCC] = useState("Copy UCC");

  const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleToggleConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleCopy = () => {
    const textToCopy = "Anand Arjun Rao";
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipTitle("Copied"); // Change tooltip title to "Copied"
      setTimeout(() => setTooltipTitle("Copy Name"), 2000); // Reset tooltip title after 2 seconds
    });
  };
  const handleBOIDCopy = () => {
    const textToCopy = "1234567890123456";
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipTitle("Copied"); // Change tooltip title to "Copied"
      setTimeout(() => setTooltipTitle("Copy Name"), 2000); // Reset tooltip title after 2 seconds
    });
  };
  const handleUniqueCopy = () => {
    const textToCopy = "1234567890";
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipTitle("Copied"); // Change tooltip title to "Copied"
      setTimeout(() => setTooltipTitle("Copy Name"), 2000); // Reset tooltip title after 2 seconds
    });
  };

  const handleUpdatePassword = () => {
    if (newPassword === confirmPassword) {
      alert("Password updated successfully!");
    } else {
      alert("Passwords do not match!");
    }
  };

  const handleOpenPopup = (type) => {
    setPopupType(type);
    setIsPopupOpen(true);
  };

  const handleClosePopup = (type) => {
    setIsPopupOpen(false);
    setPopupType("");
  };
  
  const basicDetails = (
    <Box
      sx={{
        p: 3,
        // height: "100vh",
        // width: "1000px",
        overflowY: "auto",
        border: "1px solid rgba(237, 237, 237, 1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "none",
        mb: 3
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Basic Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <Typography  >
            Name
          </Typography>
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
            Anand Arjun Rao
            <Tooltip title={tooltipTitle} arrow>
              <IconButton sx={{ marginLeft: 1 }} onClick={handleCopy}>
                <ContentCopyIcon className='copyicon' />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            PAN
          </Typography>
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>*****123A</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Date of Birth (DD/MM/YYYY)
          </Typography>
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>**/**/1997</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            Gender
          </Typography>
          <Typography p={1}>Female</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Mobile Number
          </Typography>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>*****12345
            <IconButton
              color="primary"

              // sx={{
              //   ml: { xs: 2, sm: 16, md: 32 },
              // }}

              sx={{
                ml: { xs: 0, md: 10 },
              }}
              onClick={() => handleOpenPopup("mobile")}
            >
              <EditIcon className="copyicon" />
            </IconButton>
          </Typography>
          <VerifyMobilePopup
            open={isPopupOpen}
            handleClose={handleClosePopup}
            popupType={popupType}
          />
          {/* </Box> */}
          {/* <Typography>*****12345</Typography> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Marital Status
          </Typography>
          <Typography p={1}>Single</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Email
          </Typography>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>ana******@gmail.com
            <IconButton color="primary"

              sx={{
                ml: { xs: 0, md: 10 },
              }}
              onClick={() => handleOpenPopup("email")}
            >
              <EditIcon className="copyicon" />
            </IconButton>
          </Typography>
          <VerifyMobilePopup
            open={isPopupOpen}
            handleClose={handleClosePopup}
            popupType={popupType}
          />
          {/* </Box> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Unique Client Code
          </Typography>
          {/* <Box sx={{ display: "flex", alignItems: "centre" }}> */}
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>1234567890

          <Tooltip title={tooltipTitle} arrow>
              <IconButton sx={{ marginLeft: 1 }} onClick={handleUniqueCopy}>
                <ContentCopyIcon className='copyicon' />
              </IconButton>
            </Tooltip>
          </Typography>
          {/* </Box> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography >
            Demat Acc Number / BOID
          </Typography>
          {/* <Box sx={{ display: "flex", alignItems: "centre" }}> */}
          <Typography p={1} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>1234567890123456
          <Tooltip title={tooltipTitle} arrow>
              <IconButton sx={{ marginLeft: 1 }} onClick={handleBOIDCopy}>
                <ContentCopyIcon className='copyicon' />
              </IconButton>
            </Tooltip>
          </Typography>
          {/* </Box> */}
        </Grid>
      </Grid>
    </Box>
  );

  const pages = {
    "basic-details": basicDetails,
    reports: <Reports />,
    "change-password": (
      <PassChanged
        pass="New Password"
        newPass=" Confirm New Password"
        placeText="Enter Password"
        mode= "password"
      />
    ),
    "change-pin": (
      <PassChanged
        pass="New PIN"
        newPass=" Confirm New PIN"
        placeText="Enter PIN"
        mode= "PIN"
      />
    ),
    "nominee-details": <NomineeDetails />,
    "active-devices": <ActiveDevices />,
  };
  return (
    <Box
      sx={
        {
          // p: 3,
          // height: "100vh",
          // width: "1000px",
          // overflowY: "auto",
          // border: "1px solid #ccc",
          // borderRadius: "8px",
          // backgroundColor: "#fff",
          // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }
      }
    >
      {/* sx={{ p: 3, height: "100vh", overflowY: "auto" }}> */}
      {pages[page] || (
        <Typography variant="h6" color="text.secondary">
          Select a menu option to view content.
        </Typography>
      )}
    </Box>
  );
};

export default Content;
