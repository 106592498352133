import React, { useState } from 'react';
import {
    Button,
    Typography,
    Grid,
    Box,
    Divider,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    MenuItem,
    Menu,
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import '../assets/css/pandetails.css';
import Fund1 from '../assets/images/birla.svg';
import { Sort as SortIcon } from "@mui/icons-material";

function Mysips() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Due Date");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <>
            <Header2 />
            <Grid className="wrapper pan-card-margin border-top">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <Box className=' mb-3' >
                            <Box className='mb-2 mt-2 d-flex align-center '>
                                <Box ml={3}>
                                    <Typography variant='h3' fontWeight='600' mb={2}>My SIPs</Typography>
                                    <Box className='blue-switchline' />
                                    <Typography mt={2}>View and manage your active SIPs.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ mb: 4, mt: 5 }} >
                        <Box mb={2} display='flex' justifyContent='space-between'>
                            <Typography variant='h5' fontWeight={600}>₹500</Typography>
                            <Typography >Monthly SIP Amount</Typography>
                        </Box>
                        <Divider />
                        <Box mt={5} display='flex' justifyContent='space-between'>
                            <Typography variant='h5' fontWeight={600}>Active SIP (1)</Typography>
                            <Button
                                startIcon={<SortIcon />}
                                onClick={handleClick}
                                variant="text"
                                sx={{
                                    color: "rgba(62, 65, 84, 1)", // Set the button text color to black
                                    textTransform: "none", // Prevent text from being uppercase if needed
                                }}
                            >
                                Sort by: {selectedOption}
                            </Button>
                            <Menu
                                className='active-sip-filter'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem disableRipple >
                                    <RadioGroup value={selectedOption} onChange={handleChange}>
                                        <FormControlLabel
                                            value="Due Date"
                                            control={<Radio className="custom-radio" />}
                                            label="Due Date"
                                        />
                                        <FormControlLabel
                                            value="Fund Name"
                                            control={<Radio className="custom-radio" />}
                                            label="Fund Name"
                                        />
                                    </RadioGroup>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Box className="watchlist-card" mt={2}>
                            <Box className="card-content">
                                {/* <CardContent> */}
                                {/* Header Section */}
                                <Box display="flex" alignItems="center" justifyContent='space-between' sx={{ mb: 2, mt: 2 }} className="card-padding">
                                    <Box display='flex' justifyContent='space-between'>
                                        <img src={Fund1} alt="fund card img" />
                                        <Box ml={1}>
                                            <Typography ml={1}>
                                                SBI PSU Direct Plan Growth
                                            </Typography>
                                            <Typography ml={1}><b> ₹500</b>  </Typography>
                                        </Box>
                                    </Box>
                                    <Box ml={1} >
                                        <Typography className="watchlist-card" p={1}>
                                            <b> 06</b> <br></br><span className='font-12'>Dec</span>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
}

export default Mysips;
