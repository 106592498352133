import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Header2 from "../pages/home/Appbar2";

const UserDetails = () => {
  const [selectedPage, setSelectedPage] = useState("basic-details");

  const handleMenuSelect = (pageId) => {
    setSelectedPage(pageId);
  };

  return (
    <>
      <Header2 />
      <Box className='border-top pan-card-margin'>
        <div className="side-margin ">
          <Box sx={{ marginTop: "50px" }}>
            <Grid container spacing={2}>
              {/* Left Sidebar */}
              <Grid item xs={12} md={3}>
                <Sidebar onSelect={handleMenuSelect} />
              </Grid>

              {/* Right Content */}
              <Grid item xs={12} md={9}>
                <Content page={selectedPage} />
              </Grid>
            </Grid>

          </Box>
        </div>
      </Box>
    </>

  );
};

export default UserDetails;
