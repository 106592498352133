import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    Divider,
    IconButton,
    TextField,
    CardContent,
    Card,
 
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import Fund1 from '../assets/images/birla.svg';
import SWP from '../assets/images/swpsystematic.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Invest from '../components/investsip';
import Redeem from '../components/redeem';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import { BorderBottom } from '@mui/icons-material';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
 
function Startswp() {
    const [showRedeem, setShowRedeem] = useState(false);
    const [isEditingInstallments, setIsEditingInstallments] = useState(false);
    const [installments, setInstallments] = useState(0);
 
 
    // Function to handle the Redeem button click
    const handleRedeemClick = () => {
        setShowRedeem(true); // Show the Redeem component
    };
 
    // Function to navigate back to the Invest component
    const handleBack = () => {
        setShowRedeem(false); // Show the original content
    };
 
    const [isEditingSWPDate, setIsEditingSWPDate] = useState(false);
    const [swpDate, setSwpDate] = useState(1);
 
    // Handle click on the edit/save icon for Installments
    const handleInstallmentsEditClick = () => {
        setIsEditingInstallments(!isEditingInstallments); // Toggle between edit and view mode
    };
 
    // Handle change in the Installments input value
    const handleInstallmentsInputChange = (event) => {
        setInstallments(event.target.value);
    };
 
    // Handle click on the edit/save icon for SWP Date
    const handleSWPDateEditClick = () => {
        setIsEditingSWPDate(!isEditingSWPDate); // Toggle between edit and view mode
    };
 
    // Handle change in the SWP Date input value
    const handleSWPDateInputChange = (event) => {
        setSwpDate(event.target.value);
    };
 
    const initialValues = {
        amount: "",
    };
 
    const validationSchema = Yup.object({
        amount: Yup.string()
            .required("Amount is required")
            .matches(/^\d+$/, "Amount must be a valid number")
            .test("positive", "Amount must be greater than zero", (value) => parseFloat(value) > 0),
    });
 
    const handleSubmit = (values) => {
        console.log("Submitted values:", values);
    };
 
    return (
        <>
            <Header2 />
            <Grid className="wrapper pan-card-margin border-top">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8.5}>
                        <Box className=' mb-3' >
                            <Box className='mb-2 mt-2 swp-imgcard' >
                                <Box>
                                    <img src={SWP} alt="fund card img" />
                                </Box>
                                <Box ml={3}>
                                    <Typography variant='h5' fontWeight='600' mb={2}>SWP (Systematic Withdrawal Plan)</Typography>
                                    <Typography>Withdraw a fixed amount of money every month from a mutual fund</Typography>
                                </Box>
                            </Box>
                            <Divider className="custom-hr" />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography  >START SWP FOR THIS FUND</Typography>
                        </Box>
                        <Box className='d-flex justify-between ' sx={{ mt: 3 }}>
                            <Box className='d-flex' >
                                <img src={Fund1} alt="fund card img" className='birla-cardimg' />
                                <Box sx={{ ml: 2 }}>
                                    <Typography ><b>Aditya Birla Sun Life PSU Equity Fund Direct Growth</b></Typography>
                                    <Typography className='font-12 ' sx={{ mt: 1 }}>Exit load and capital gain tax may be applicable</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ ml: 2 }} className='text-end'>
                                    <Typography ><b>₹969.74</b></Typography>
                                    <Typography className='font-12 ' sx={{ mt: 1 }}>Available to Withdraw</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3.5} sx={{ mb: 4, }} >
                        <Box className="watchlist-card swp-card-mar">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange }) => (
                                <Form>
                            <Box className="card-content">
                                {/* <CardContent> */}
                                {/* Header Section */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }} className="card-padding">
                                    <Typography >
                                        Total SWP Amount
                                    </Typography>
                                    <Typography fontWeight="bold">
                                        ₹0.00
                                    </Typography>
                                </Box>
                                <Divider />
                                {/* Monthly SWP Amount Section */}
                                <Box my={2} className="card-padding" width="50%" mx="auto" textAlign="center">
                                    <Typography className="text-center" mb={1}>
                                        Monthly SWP Amount <span style={{color:'red'}}>*</span>
                                    </Typography>
                                    <Box alignItems="center" display="flex" justifyContent="center">
                                        <Typography className="rs-fontsize" fontWeight="bold">
                                            ₹
                                        </Typography>
                                        <TextField
                                            name="amount"
                                            variant="standard"
                                            placeholder="0"
                                            inputProps={{ style: { fontSize: "1.5rem", textAlign: "center" } }}
                                            style={{ width: "80%" }} // Adjust width of the TextField
                                            onChange={handleChange}
                                            value={values.amount}
                                        />
                                    </Box>
                                    <ErrorMessage
                                        name="amount"
                                        component="div"
                                        style={{ color: "red", fontSize: "0.9rem", marginTop: "0.5rem" }}
                                    />
                                </Box>
 
                                {/* Installments and SWP Date Section */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3} className="card-padding">
                                    <Box display="flex" alignItems="center">
                                        <Typography mr={1} className='font-12' >Installments</Typography>
 
                                        {isEditingInstallments ? (
                                            <TextField
                                                variant="standard"
                                                value={installments}
                                                onChange={handleInstallmentsInputChange}
                                                inputProps={{
                                                    style: { textAlign: "center" },
                                                }}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        width: "50px", // Default width
                                                        "@media (max-width: 600px)": {
                                                            width: "20px", // Reduced width for mobile
                                                        },
                                                    },
                                                }}
                                                autoFocus
                                            />
                                        ) : (
                                            <Typography variant="body2">{installments}</Typography>
                                        )}
 
                                        <IconButton size="small" sx={{ ml: 1 }} onClick={handleInstallmentsEditClick}>
                                            {isEditingInstallments ? (
                                                <CheckIcon fontSize="small" className="text-blue" />
                                            ) : (
                                                <EditIcon fontSize="small" className="text-blue" />
                                            )}
                                        </IconButton>
                                    </Box>
 
                                    <Box display="flex" alignItems="center">
                                        <Typography mr={1} className='font-12'>SWP Date</Typography>
 
                                        {isEditingSWPDate ? (
                                            <TextField
                                                variant="standard"
                                                value={swpDate}
                                                onChange={handleSWPDateInputChange}
                                                inputProps={{
                                                    style: { textAlign: "center" },
                                                }}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        width: "50px", // Default width
                                                        "@media (max-width: 600px)": {
                                                            width: "20px", // Reduced width for mobile
                                                        },
                                                    },
                                                }}
                                                autoFocus
                                            />
                                        ) : (
                                            <Typography variant="body2">{swpDate}</Typography>
                                        )}
 
                                        <IconButton size="small" sx={{ ml: 1 }} onClick={handleSWPDateEditClick}>
                                            {isEditingSWPDate ? (
                                                <CheckIcon fontSize="small" className="text-blue" />
                                            ) : (
                                                <EditIcon fontSize="small" className="text-blue" />
                                            )}
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Divider />
 
                                {/* Bank Transfer Info */}
                                <Box mt={3} mb={2} className="card-padding">
                                    <Typography fontWeight='500'>
                                        Amount will be transferred to your bank
                                    </Typography>
                                    <Typography mt={1} className='font-14' >
                                        HDFC BANK (XXXXXXXXXX2291)
                                    </Typography>
                                </Box>
 
                                {/* Footer Section */}
                                <Typography mb={2} mt={6} className="card-padding font-12 text-center">
                                    Your first order will be placed on 1st of next month.
                                </Typography>
                                <Box className="card-padding" mb={3}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        className='submit-btn'
                                        type='submit'
 
                                    >
                                        Confirm SWP
                                    </Button>
                                </Box>
 
                                {/* </CardContent> */}
                            </Box>
                            </Form>
                            )}
                        </Formik>
                        </Box>
                    </Grid>
                </Grid>
            </Grid >
 
            {/* <DashboardInvestment />
            <PopularFunds />
            <WebFooter /> */}
        </>
    );
}
 
export default Startswp;
