// StepUpSIPPopup.js
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    MenuItem,
    Select,
    ToggleButtonGroup,
    ToggleButton,
    Box,
    IconButton,
    TextField,
    ButtonGroup,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PercentIcon from '@mui/icons-material/Percent'; // Import Percent Icon


const StepUpSIPPopup = ({ open, handleClose, incrementValue, setIncrementValue, interval, setInterval }) => {
    const handleIntervalChange = (event, newInterval) => {
        if (newInterval !== null) {
            setInterval(newInterval);
        }
    };

    const [sortOption, setSortOption] = useState("high-to-low");
    const [value, setValue] = useState(0); // Store the value of the amount or percentage


    const handleChange = (event) => {
        setSortOption(event.target.value);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value);
    };
    const [selectedOption, setSelectedOption] = useState('1Y'); // Default selected option

    const handleClick = (option) => {
        setSelectedOption(option);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: { maxWidth: 400, padding: 4 },
            }}
        >
            <DialogTitle>
                <Typography fontWeight={600}>Step-up SIP</Typography>
            </DialogTitle>

            <DialogContent>
                {/* Current SIP Amount */}
                <Box display='flex' justifyContent='space-between'>
                    <Typography className=' popup-contain' mb={1}>Current SIP Amount</Typography>
                    <Typography className=' popup-contain' mb={2}>
                        ₹500
                    </Typography>
                </Box>
                {/* Increment Selection */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} >
                    <Box display='flex' justifyContent='space-between' alignItems='center' mr={3}>
                        <Typography className=' popup-contain' mr={1}>Increase by</Typography>
                        <Select
                            value={sortOption}
                            onChange={handleChange}
                            variant="standard"
                            disableUnderline
                        >
                            <MenuItem className=' popup-contain' value="high-to-low"> Amount</MenuItem>
                            <MenuItem className=' popup-contain' value="low-to-high">Percentage</MenuItem>
                        </Select>
                    </Box>

                    {/* Conditional rendering based on selected option */}
                    <Box className='sip-aamount-box' display="flex" alignItems="center" justifyContent="space-between">
                        {sortOption === 'high-to-low' ? (
                            <>
                                <CurrencyRupeeIcon fontSize="small" />
                                <TextField
                                    value={value}
                                    onChange={handleInputChange}
                                    variant="standard"
                                    sx={{ width: 80 }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    inputProps={{
                                        style: { textAlign: "right", color: 'rgba(11, 36, 194, 1)' }, // Align text to the right
                                    }}
                                    type="number"
                                />
                            </>
                        ) : (
                            <>

                                <TextField
                                    value={value}
                                    onChange={handleInputChange}
                                    variant="standard"
                                    sx={{ width: 80 }}
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{
                                        style: { textAlign: "right", color: 'rgba(11, 36, 194, 1)' }, // Align text to the right
                                    }}
                                    type="number"
                                />
                                <PercentIcon fontSize="small" />
                            </>
                        )}
                    </Box>
                </Box>
                {/* Interval Selection */}
                <Box className="toggle-container" justifyContent='space-between'>
                    <Typography className=' popup-contain' variant="body1">After every</Typography>
                    <Box sx={{ gap: "10px" }}>
                        <Button
                            onClick={() => handleClick("6M")}
                            className={`toggle-button ${selectedOption === "6M" ? "active" : "inactive"
                                }`}
                        >
                            6M
                        </Button>
                        <Button
                            onClick={() => handleClick("1Y")}
                            className={`toggle-button ${selectedOption === "1Y" ? "active" : "inactive"
                                }`}
                        >
                            1Y
                        </Button>
                    </Box>
                </Box>

                {/* Monthly SIP Changes Info */}
                <Typography
                    className='font-12'
                    mt={3}
                    sx={{ textAlign: "center" }}
                >
                    Monthly SIP changes to ₹600 on 05 Nov '25
                </Typography>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                <Button
                    variant="contained"
                    fullWidth
                    className="start-sipbtn"
                    onClick={handleClose}
                >
                    Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StepUpSIPPopup;
