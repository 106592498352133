import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    FormControlLabel,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
} from "@mui/material";
import UPI from '../assets/images/upi.svg';
import HDFC from '../assets/images/hdfc.svg';
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Fund1 from '../assets/images/birla.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Info from '../assets/images/infoicon.svg';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
 
 
function Redeem({ onBack }) {
    const [showRedeemDetails, setShowRedeemDetails] = useState(false);
    const [showOtpSection, setShowOtpSection] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // Redeem
 
    const handleRedeem1Click = () => {
        setShowRedeemDetails(true);
    };
 
    const handleBackClick = () => {
        setShowRedeemDetails(false);
    };
    const handleBackToSIP = () => {
        setShowOtpSection(false);  // Hide OTP section and go back to the SIP section
    };
    const handleStartSIP = () => {
        setShowOtpSection(true);  // Show OTP section when 'Start SIP' is clicked
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
 
    const validationSchema = Yup.object({
        amount: Yup.string().when("redeemAll", {
            is: false, // Validate `amount` only if `redeemAll` is false
            then: (schema) =>
                schema
                    .required("Amount is required")
                    .matches(/^\d+$/, "Amount must be a number")
                    .test("positive", "Amount must be greater than zero", (value) => parseFloat(value) > 0),
            otherwise: (schema) => schema.notRequired(),
        }),
        redeemAll: Yup.boolean(), // Checkbox field
    });
   
   
 
    // Initial form values
    const initialValues = {
        amount: '',
        redeemAll: false,
    };
 
    // Submit handler
    const handleSubmit = (values) => {
        console.log('Submitted values:', values);
        handleRedeem1Click();
    };
    return (
        <>
            <Box className="card-content">
                {showRedeemDetails ? (
                    <Box>
                        {/* Back Button */}
                        <Typography sx={{ mt: 2 }} className="font-600 align-center d-flex card-padding">
                            <KeyboardBackspaceIcon onClick={handleBackClick} sx={{ mr: 2 }} />
                            Redeem
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        {/* Fund Information */}
                        <Box className="d-flex justify-between watchlist-textpadding">
                            <Box className='d-flex'>
                                <img src={Fund1} alt="fund card img" className='birla-cardimg' />
                                <Box sx={{ ml: 2 }}>
                                    <Typography ><b>Aditya Birla Sun Life PSU Equity Fund Direct Growth</b></Typography>
                                    <Typography variant="body2" sx={{ mt: 2 }}><b>13.49 Units</b> (₹466.11 approx.)</Typography>
                                    <Typography variant="body2" sx={{ mt: 2 }}>Applicable NAV Date: 05 Nov 2024</Typography>
                                    <Typography variant="body2" sx={{ mt: 2 }}>Approx. Exit Load: ₹0.00 <img src={Info} alt="info icon" className="h-100 redeem-custom-m" /></Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        {/* Transfer Information */}
                        <Typography className="card-padding" sx={{ mb: 2 }}>
                            Amount will be transferred to your bank HDFC Bank (XXXXXXXX1234)
                        </Typography>
                        <Box className="card-padding" sx={{ mb: 2, mt: 6 }}>
                            <Button
                                className="start-sipbtn"
                                variant="contained"
                                fullWidth
                                sx={{ bgcolor: "#1C5DB8", color: "#fff", mt: 2 }}
                            >
                                Proceed
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <>
                        {/* Back Button */}
                        <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                            <KeyboardBackspaceIcon onClick={onBack} sx={{ mr: 2 }} /> Redeem
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleChange, values }) => (
                            <Form>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} className='watchlist-textpadding'>
                            <Box display="flex" alignItems="center">
                                <Typography><b>Enter <span className='text-blue'>Amount <span style={{color:'red'}}>*</span></span></b></Typography>
                                <IconButton
                                    onClick={handleClick}
                                    sx={{ p: 0, ml: 1, color: 'inherit' }}
                                    aria-controls={open ? 'amount-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            </Box>
                            <Box className='sip-aamount-box' display="flex" alignItems="center" justifyContent="space-between" >
                                <CurrencyRupeeIcon fontSize="small" />
                                <Field
                                            as={TextField}
                                            name="amount"
                                            variant="standard"
                                            size="small"
                                            placeholder ="0"
                                            value={values.amount}
                                            onChange={handleChange}
                                        />
                                       
                            </Box>
                           
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <ErrorMessage
                                name="amount"
                                component="div"
                                className="text-error"
                                style={{ color: "red", fontSize: "16px",marginRight :'10px', whiteSpace: "nowrap" }}
                            />
                        </Box>
                        {/* <Menu
                                        id="amount-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleClose}>500</MenuItem>
                                        <MenuItem onClick={handleClose}>1000</MenuItem>
                                        <MenuItem onClick={handleClose}>1500</MenuItem>
                                    </Menu> */}
                        <Box className="d-flex align-center card-padding justify-end redeem-form-check" sx={{ mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Field
                                    type="checkbox"
                                    name="redeemAll"
                                    as={Checkbox}
                                />
                            }
                            label="Redeem All"
                        />
                    </Box>
                        <Box className='d-flex justify-between'>
                            <Typography className="card-padding" sx={{ mb: 2 }}>
                                <b> Total Redeemable (approx.)</b>
                            </Typography>
                            <Typography className="card-padding" sx={{ mb: 2 }}>
                                <b>₹466.11</b>
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box className="d-flex text-center card-padding align-center" sx={{ mb: 6 }}>
                            <Typography variant="body2">
                                Expected completion in 2 working days (by 07 Nov 2024)
                                <img src={Info} alt="info icon" className="h-100 redeem-custom-m" />
                            </Typography>
                        </Box>
                        <Box className='card-padding' sx={{ mb: 2, mt: 6 }}>
                            <Button className='start-sipbtn' variant="contained" type='submit' fullWidth sx={{ bgcolor: '#1C5DB8', color: '#fff', mt: 2 }}>
                                Redeem
                            </Button>
                        </Box>
                        </Form>
                        )}
                    </Formik>
                    </>
                )}
            </Box>
        </>
    );
}
 
export default Redeem;