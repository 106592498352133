import React from 'react';
// import { Card, CardContent, Typography, Box, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../../assets/css/onbording.css';
import { Box, Typography, Paper, Stack, Button } from '@mui/material';


import Header2 from "../../pages/home/Appbar2";


const LinkedAccountCard = () => {
    return (

        <>

            <Box>

                <div
                // className='pin-setup-header'
                >
                    <Header2 />

                </div>


                <div
                    // className='pin-setup-container'
                    className="wrapper pan-card-margin bgcolor" style={{ backgroundColor: "#f5f7fb", }}

                >

                    <div className='pandetails-bg'>

                        <Paper elevation={1} className="success-container">

                            <Box>
                                <CheckCircleIcon
                                    className="all-set-icon"
                                />
                            </Box>

                            <Typography

                                className="success-title"
                            >
                                You are all set
                            </Typography>

                            <Typography
                                variant="body1"
                                className="success-message"
                            >
                                Your document verification is in progress. You will be able to start investing within 48 hours.
                            </Typography>

                            <Stack sx={{ width: '100%', mt: 3 }} spacing={3}>

                                <Button
                                    variant="contained"
                                    className="open-camera"
                                >
                                    Let’s Go!
                                </Button>

                            </Stack>

                        </Paper>
                    </div>

                </div>

            </Box>

        </>


    );
};

export default LinkedAccountCard;
