import React, { useState } from 'react';
import Header2 from "../home/Appbar2";
import WebFooter from "../../components/Web-footer";
import { Box, Grid, Typography, IconButton, Divider, Stepper, Step, StepLabel, StepContent, } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import "../../assets/css/funddetails.css"
import "../../assets/css/common.css"
import "../../assets/css/dashboard.css"
import Checkedcircle from "../../assets/images/Checkedcircle.svg"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SbiIcon from "../../assets/images/sbi.png"

// import WebFooter from "../../";
// const steps = [
//     {
//         label: 'Payment confirmed',
//         description: `30 Sep ’24, 10:55 AM`,
//     },
//     {
//         label: 'Order approved by exchange',
//         description: `30 Sep ’24, 11:10 AM `,
//     },
//     {
//         label: 'Units allocated',
//         description: `1 Oct ’24, 11:30 AM`,
//     },
// ];

const steps = [
    {
        label: "Payment confirmed",
        description: "30 Sep ’24, 10:55 AM",
        icon: <CheckCircleIcon className='status-icon-bgcolor' />,
    },
    {
        label: "Order approved by exchange",
        description: "30 Sep ’24, 11:10 AM",
        icon: <CheckCircleIcon className='status-icon-bgcolor' />,
    },
    {
        label: "Units allocated",
        description: "1 Oct ’24, 11:30 AM",
        icon: <CheckCircleIcon className='status-icon-bgcolor' />,
    },
];

const CustomStepIcon = () => <CheckCircleIcon className='status-icon-bgcolor' />;
// const CustomStepIcon = () => <img src={Checkedcircle} alt='icons' className='status-icon-color' />;
function Dashboard() {
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <>
            <Header2 />
            <Grid className=" pan-card-margin border-top">
                <Grid item xs={12} md={12}>
                    <Box className='d-flex back-bread mt-3 mb-3' >
                        <KeyboardArrowLeftIcon /><Typography>Back</Typography>
                    </Box>
                    <Divider className="custom-hr" />
                </Grid>
            </Grid>
            <Grid container className="wrapper oder-card-margin" spacing={3}>
                <Grid item xs={12} md={8} sm={12} className='cardpl'>
                    <div className='receipt-wrapper-box2'>
                        <div className="scheme-container oder-details-status">
                            <div className="scheme-icon"> <img src={SbiIcon} alt='SbiIcon.png' /> </div>
                            <div className="scheme-content">
                                <h3 className="scheme-title">SBI PSU Direct Plan Growth</h3>
                                <p className="scheme-subtitle">New SIP</p>
                            </div>
                            <div className="scheme-amount">₹500</div>
                        </div>
                        <Divider className='status-heading-divider2' variant="fullWidth" />
                        <div className='oder-details-status'>
                            <Grid container className="transaction-row">
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">Completed On</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">01 Oct ‘24</div>
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">NAV Date</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">07 Nov '24</div>
                                </Grid>
                            </Grid>
                            <Grid container className="transaction-row">
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">Placed On</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">
                                        30 Sep ‘24, 10:55 AM
                                        {/* <IconButton
                                            size="small"
                                            className="copy-button"
                                            onClick={() => handleCopy('ABCD12345678901234567890123')}
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton> */}
                                    </div>
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">Paid Via</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">HDFC BANK</div>
                                </Grid>
                            </Grid>
                            <Grid container className="transaction-row">
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">Folio No.</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">
                                        12345678
                                        <IconButton
                                            size="small"
                                            className="copy-button"
                                        // onClick={() => handleCopy('12345678')}
                                        >
                                            <ContentCopyIcon fontSize="small" className='copyicon' />
                                        </IconButton>
                                    </div>
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <div className="transaction-label">Order ID</div>
                                </Grid>
                                <Grid item xs={9} md={4}>
                                    <div className="transaction-value">
                                        ABCD1234567890123456789
                                        <IconButton
                                            size="small"
                                            className="copy-button"
                                        // onClick={() => handleCopy('ABCD12345678901234567890123')}
                                        >
                                            <ContentCopyIcon fontSize="small" className='copyicon' />
                                        </IconButton>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <div className="transaction-label">Paid Via</div>
                                    <div className="transaction-value">HDFC BANK</div>
                                </Grid> */}
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} sm={12}>
                    <div className="receipt-wrapper-box2">
                        {/* <h4 className="status-heading">Status</h4> */}
                        <div className='d-flex align-items-center justify-content-between oder-details-status'>
                            <h4 className="status-heading">Status</h4>
                            <div className="status-item">
                                <CheckCircleIcon className='text-blue' />
                                <span>Completed</span>
                                {/* <div className="status-info">
                                    <div className="status-subheading">Completed</div>
                                </div> */}
                            </div>
                        </div>
                        <Divider className='status-heading-divider2' variant="fullWidth" />
                        <Box className='oder-details-status' mb={3}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            icon={step.icon} // Custom icons for each step
                                            classes={{
                                                root: "step-label-root",
                                                label: "step-label-text",
                                            }}
                                        >
                                            <Box>
                                                <Typography style={{ fontWeight: "400", color: "#3E4154", fontSize: "15px", }}>{step.label}</Typography>
                                                <Typography style={{ fontWeight: "400", fontSize: "12px", color: "#565A71" }}>{step.description}</Typography>
                                            </Box>
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </div>
                </Grid>
            </Grid>
            {/* </div> */}
        </>
    );
}

export default Dashboard;
