import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import Warning from "../../src/assets/images/warningpopupicon.svg";

const SkipInstallmentPopup = ({ open, handleClose, onSkip }) => {
    const [cannotSkipOpen, setCannotSkipOpen] = useState(false);

    const handleSkipAnyway = () => {
        const canSkip = false; // Replace with actual logic for skipping

        if (!canSkip) {
            setCannotSkipOpen(true); // Open the "Cannot Skip" popup
        } else {
            onSkip();
            handleClose();
        }
    };

    const handleGotIt = () => {
        setCannotSkipOpen(false); // Close the "Cannot Skip" popup
        handleClose(); // Close the main popup
    };

    return (
        <>
            {/* Main Skip Confirmation Dialog */}
            <Dialog
                open={open}
                onClose={handleClose}
                className="skip-popup-card"
                PaperProps={{
                    style: { maxWidth: 400, padding: 4 },
                }}
            >
                <DialogTitle>
                    <img src={Warning} alt="Warning" />
                    <Typography fontWeight={600}>
                        Are you sure you want to skip 06 Dec '24 installment?
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Typography className="dark-gray font-12">
                        Your next due date will be 06 Jan '25
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className="add-cartbtn"
                        fullWidth
                    >
                        Keep investing
                    </Button>
                    <Button
                        onClick={handleSkipAnyway}
                        variant="contained"
                        fullWidth
                        className="start-sipbtn"
                    >
                        Skip anyway
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Cannot Skip Dialog */}
            <Dialog
                open={cannotSkipOpen}
                onClose={() => setCannotSkipOpen(false)}
                className="cannot-skip-popup"
                PaperProps={{
                    style: { maxWidth: 400, padding: 4 },
                }}
            >
                <DialogTitle textAlign='center'>
                    <img src={Warning} alt="Warning Icon" />
                    <Typography fontWeight={600}>
                        You can no longer skip this installment
                    </Typography>
                </DialogTitle>

                <DialogContent >
                    <Typography textAlign='center' className="dark-gray font-12">
                        We have already started processing your order. Installments
                        can only be skipped up to 2 working days from your SIP date.
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ mb: 2 }}>
                    <Button
                        onClick={handleGotIt}
                        variant="contained"
                        className="start-sipbtn"
                        fullWidth
                    >
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SkipInstallmentPopup;
