import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  MenuItem,
  Divider,
  Collapse,
  Tabs,
  Tab,
  Menu,
  Paper,
  InputAdornment,
  Breadcrumbs,
  Link
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Header2 from "./home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import WebFooter from "../components/Web-footer";
import "../assets/css/funddetails.css";
import "../assets/css/pagination.css";
import MotilalOswal from "../assets/images/Motilal-Oswal.svg";
import AdityaBirlaIcon from "../assets/images/AdityaBirla-Icon.svg";
import sbiIcon from "../assets/images/sbi.png";
import QuantSmallIcon from "../assets/images/Quant-Small.svg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "@mui/material/Pagination";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import { useDispatch, useSelector } from "react-redux";
import {
  getExploreFundsList,
  getAMCLists,
} from "../actions/exploreFundsActions";
import Loader from "../loader/Loader-ui";
import { Link as RouterLink } from "react-router-dom";
import { getLocalStorageUserId } from "../utils/userData";
import { recentProductList } from "../utils/recentProduct";

function Dashboard() {
  const dispatch = useDispatch();
  const isMounted = useRef(false); // To track the first render
  const [isLoading, setIsLoading] = useState(false);
  const [isFundsLoading, setIsFundsLoading] = useState(false);
  const [isAMCLoading, setIsAMCLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAMC, setSelectedAMC] = useState("");
  const [searchAMC, setSearchAMC] = useState("");
  const [searchFunds, setSearchFunds] = useState("");
  const [filteredAMCList, setFilteredAMCList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState("");
  const [selectedTypes, setSelectedTypes] = useState("");
  const [selectedInvestment, setSelectedInvestment] = useState("");
  const userId = getLocalStorageUserId();
  // Accessing Redux state correctly
  const { exploreFundsList = [] } = useSelector(
    (state) => state.exploreFunds
  );
  // Get AMC list for the dropdown menu
  const { amcLists = [] } = useSelector((state) => state.exploreFunds);
  const exploreListProducts = exploreFundsList?.data?.products || [];
  const totalPages = exploreFundsList?.data?.total_page || 1;
  const totalRecords = exploreFundsList?.data?.total_records || 0;
  const amcListData = amcLists?.data || [];

  const fetchData = useCallback(async () => {
    const fundsPayload = {
      id: userId,
      pagenum: currentPage,
      amc: selectedAMC,
      search_query: searchFunds,
      category: selectedCategories.toLowerCase(),
      // type: selectedTypes.toLowerCase(),
      // minimum_amount_lumpsum: selectedMinimumAmountLumpsum,
      // minimum_amount_sip: selectedMinimumAmountSIP,
      // maximum_amount_lumpsum: selectedMaximumAmountLumpsum,
      // maximum_amount_sip: selectedMaximumAmountSIP,
      // amount_filter: selectedAmountFilter,
    };

    try {
      setIsFundsLoading(true); // Show loader
      await dispatch(getExploreFundsList(fundsPayload));
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsFundsLoading(false); // Hide loader
    }
  }, [
    dispatch,
    userId,
    currentPage,
    selectedAMC,
    searchFunds,
    selectedCategories,
    // selectedTypes,
    // selectedMinimumAmountLumpsum,
    // selectedMinimumAmountSIP,
    // selectedMaximumAmountLumpsum,
    // selectedMaximumAmountSIP,
    // selectedAmountFilter,
  ]);

  const amcData = useCallback(async () => {
    const amcPayload = {
      id: userId,
      search: searchAMC,
    };
    try {
      setIsAMCLoading(true); // Show loader
      await dispatch(getAMCLists(amcPayload));
    } catch (error) {
      console.error("One or more API calls failed: ", error);
    } finally {
      setIsAMCLoading(false); // Hide loader
    }
  }, [dispatch, userId, searchAMC]);

  // useEffect(() => {
  //   // Prevent double API calls on the first render
  //   if (isMounted.current) {
  //     fetchData();
  //     amcData();
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [fetchData,amcData]);
  console.log("above useEffect");
  useEffect(() => {
    console.log("under useEffect");
    // if (!isMounted.current) {
      console.log("under useEffect if block");
      // isMounted.current = true;
      fetchData();
      amcData();
    // }
  }, [fetchData, amcData]);

  useEffect(() => {
    if (searchAMC) {
      const filtered = amcListData.filter((amc) =>
        amc.long_name.toLowerCase().includes(searchAMC.toLowerCase())
      );
      setFilteredAMCList(filtered); // Show only the first 4 results
    } else {
      setFilteredAMCList(amcListData);
    }
  }, [searchAMC, amcListData]);

  // Function to clear all filters
  const clearFilters = () => {
    setSelectedAMC("");
    setSelectedCategories("");
    setSelectedTypes("");
    setSelectedInvestment("");
    setSearchFunds("");
  };

  const handleAMCSelect = (amcCode) => {
    // If the selectedAMC is already the clicked one, deselect it
    if (selectedAMC === amcCode) {
      setSelectedAMC(null); // Deselect
    } else {
      setSelectedAMC(amcCode); // Select
    }
  };

  const handleAMCSearchChange = (e) => {
    setSearchAMC(e.target.value);
  };

  const handleCategoryChange = (category) => {
    // Toggle category filter
    if (selectedCategories === category) {
      setSelectedCategories(""); // Deselect if already selected
    } else {
      setSelectedCategories(category); // Select the new category
    }
  };

  // const [expanded, setExpanded] = useState('amc');

  // const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  // };
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const [sortBy, setSortBy] = React.useState("name");

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const [expandedSection, setExpandedSection] = useState(null);
  const [checkboxState, setCheckboxState] = useState({
    Debt: { parent: false, children: [false, false] },
    Equity: { parent: false, children: [false, false] },
    Hybrid: { parent: false, children: [false, false] },
    Other: { parent: true, children: [false, false] },
  });

  // Handlers for expanding and collapsing sections
  const handleAccordionChange = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Handlers for parent checkbox toggles
  const handleParentCheckboxChange = (section) => {
    const newChildrenState = checkboxState[section].children.map(
      () => !checkboxState[section].parent
    );
    setCheckboxState({
      ...checkboxState,
      [section]: {
        parent: !checkboxState[section].parent,
        children: newChildrenState,
      },
    });
  };

  // Handlers for child checkbox toggles
  const handleChildCheckboxChange = (section, index) => {
    const newChildrenState = [...checkboxState[section].children];
    newChildrenState[index] = !newChildrenState[index];
    const newParentState = newChildrenState.every((state) => state);
    setCheckboxState({
      ...checkboxState,
      [section]: { parent: newParentState, children: newChildrenState },
    });
  };

  const formatLabel = (label) => {
    const words = label.split(" ");
    if (words.length === 1) return label;
    const [firstWord, ...remainingWords] = words;
    const camelCased = remainingWords
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return `${firstWord} ${camelCased}`;
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header2 />
      <div className="wrapper">
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}>
            <Box className="mt-10">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  component={RouterLink}
                  to="/"
                  underline="hover"
                  color="inherit"
                >
                  Home
                </Link>
                <Link
                  component={RouterLink}
                  to="/explore"
                  underline="hover"
                  color="inherit"
                >
                  Mutual Funds Explore
                </Link>
                <Typography color="text.primary">All Mutual Funds</Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} sx={{ mb: 3 }}>
            <Box>
              <Typography variant="h4" className="mutual-header-text">
                {" "}
                All Mutual Funds{" "}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className="mf_filters_container mb-2">
              <div className="mf_filters_header">
                {/* Commented the some the filters for now as per Aparna ma'am inputs */}
                <Typography variant="h6" className="mf_filters_title">
                  Filters
                </Typography>
                <button className="mf_clear_btn" onChange={clearFilters}>
                  CLEAR
                </button>
              </div>
              <Accordion defaultExpanded className="mf_accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="mutual-summary-text">AMC</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    placeholder="Search Mutual Fund Companies"
                    className="mf_search_field"
                    fullWidth
                    value={searchAMC}
                    onChange={handleAMCSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className="mf_checkbox_group scrollable-list">
                    {filteredAMCList.map((amc, index) => (
                      <FormControlLabel
                        key={amc.amc_code}
                        control={
                          <Checkbox
                            name={amc.long_name}
                            value={amc.amc_code}
                            onChange={() => handleAMCSelect(amc.amc_code)}
                            checked={selectedAMC === amc.amc_code}
                          />
                        }
                        label={formatLabel(amc.long_name)}
                        className="mf_checkbox_label"
                      />
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Divider />
              {/* ---------------- */}
              <Accordion defaultExpanded className="mf_accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="mutual-summary-text">
                    Category
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mf_checkbox_group">
                    {["Equity", "Debt", "Balanced", "Liquid", "ELSS", "Cash"].map(
                      (category) => (
                        <FormControlLabel
                          key={category}
                          control={
                           <Checkbox 
                           name={category}
                           value={category}
                           onChange={() => handleCategoryChange(category)}
                           checked={selectedCategories === category}
                           />
                          }
                          label={category}
                          className="mf_checkbox_label"
                        />
                      )
                    )}
                    {/* Debt Accordion */}
                    {/* <Accordion
                      className="mf_nested_accordion"
                      expanded={expandedSection === "Debt"}
                      onChange={() => handleAccordionChange("Debt")}
                    >
                      <AccordionSummary
                      expandIcon={
                        expandedSection === "Debt" ? (
                          <MinimizeIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxState.Debt.parent}
                              onChange={() =>
                                handleParentCheckboxChange("Debt")
                              }
                            />
                          }
                          label="Debt"
                          className="mf_checkbox_label"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="nested-checkbox-group mf_nested_checkbox-mt">
                          {["FoF Overseas/Domestic", "Index"].map(
                            (label, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={checkboxState.Debt.children[index]}
                                    onChange={() =>
                                      handleChildCheckboxChange("Debt", index)
                                    }
                                  />
                                }
                                label={label}
                                className="mf_checkbox_label mf_nested_checkbox"
                              />
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                    {/* Equity Accordion */}
                    {/* <Accordion
                      className="mf_nested_accordion"
                      expanded={expandedSection === "Equity"}
                      onChange={() => handleAccordionChange("Equity")}
                    >
                      <AccordionSummary
                      expandIcon={
                        expandedSection === "Equity" ? (
                          <MinimizeIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxState.Equity.parent}
                              onChange={() =>
                                handleParentCheckboxChange("Equity")
                              }
                            />
                          }
                          label="Equity"
                          className="mf_checkbox_label"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="nested-checkbox-group mf_nested_checkbox-mt">
                          {["FoF Overseas/Domestic", "Index"].map(
                            (label, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={
                                      checkboxState.Equity.children[index]
                                    }
                                    onChange={() =>
                                      handleChildCheckboxChange("Equity", index)
                                    }
                                  />
                                }
                                label={label}
                                className="mf_checkbox_label mf_nested_checkbox"
                              />
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                    {/* Commented for now as per Aparna ma'am inputs */}
                    {/* Hybrid Accordion */}
                    {/* <Accordion
                      className="mf_nested_accordion"
                      expanded={expandedSection === "Hybrid"}
                      onChange={() => handleAccordionChange("Hybrid")}
                    >
                      <AccordionSummary
                        expandIcon={
                          expandedSection === "Hybrid" ? (
                            <MinimizeIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxState.Hybrid.parent}
                              onChange={() =>
                                handleParentCheckboxChange("Hybrid")
                              }
                            />
                          }
                          label="Hybrid (2)"
                          className="mf_checkbox_label"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="nested-checkbox-group mf_nested_checkbox-mt">
                          {["FoF Overseas/Domestic", "Index"].map(
                            (label, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={
                                      checkboxState.Hybrid.children[index]
                                    }
                                    onChange={() =>
                                      handleChildCheckboxChange("Hybrid", index)
                                    }
                                  />
                                }
                                label={label}
                                className="mf_checkbox_label mf_nested_checkbox"
                              />
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                    {/* Other Accordion */}
                    {/* <Accordion
                                            className="mf_nested_accordion"
                                            expanded
                                        >
                                            <AccordionSummary 
                                            expandIcon={<MinimizeIcon />}
                                    
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkboxState.Other.parent}
                                                            onChange={() => handleParentCheckboxChange("Other")}
                                                        />
                                                    }
                                                    label="Other (2)"
                                                    className="mf_checkbox_label"
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="nested-checkbox-group mf_nested_checkbox-mt">
                                                    {["FoF Overseas/Domestic", "Index"].map((label, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={
                                                                <Checkbox
                                                                    checked={checkboxState.Other.children[index]}
                                                                    onChange={() => handleChildCheckboxChange("Other", index)}
                                                                />
                                                            }
                                                            label={label}
                                                            className="mf_checkbox_label mf_nested_checkbox"
                                                        />
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                    </Accordion>
                    <Accordion
                      className="mf_nested_accordion"
                      expanded={expandedSection === "Other"}
                      onChange={() => handleAccordionChange("Other")}
                    >
                      <AccordionSummary
                        expandIcon={
                          expandedSection === "Other" ? (
                            <MinimizeIcon />
                          ) : (
                            <AddIcon />
                          )
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxState.Other.parent}
                              onChange={() =>
                                handleParentCheckboxChange("Other")
                              }
                            />
                          }
                          label="Other (2)"
                          className="mf_checkbox_label"
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="nested-checkbox-group">
                          {["FoF Overseas/Domestic", "Index"].map(
                            (label, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={
                                      checkboxState.Other.children[index]
                                    }
                                    onChange={() =>
                                      handleChildCheckboxChange("Other", index)
                                    }
                                  />
                                }
                                label={label}
                                className="mf_checkbox_label mf_nested_checkbox"
                              />
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Divider />
              {/* Commented for now as per Aparna ma'am inputs */}
              {/* <Accordion defaultExpanded className="mf_accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="mutual-summary-text">Risk</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mf_checkbox_group">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Low"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Moderately Low"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Moderate"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Moderately High"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="High"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Very High"
                      className="mf_checkbox_label"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Divider /> */}
              <Accordion defaultExpanded className="mf_accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="mutual-summary-text">
                    Fund Size
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mf_checkbox_group">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Upto 1,000Cr"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Upto 2,000Cr"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Upto 5,000Cr"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Upto 10,000Cr"
                      className="mf_checkbox_label"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
              <Divider />
              <Accordion defaultExpanded className="mf_accordion">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="mutual-summary-text">
                    Available To Invest
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="mf_checkbox_group">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="SIP"
                      className="mf_checkbox_label"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="One-Time"
                      className="mf_checkbox_label"
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="search-header-container">
              <div className="search-results-text">
                Search Results:{" "}
                <span className="search-count">
                  {totalRecords} Mutual Funds
                </span>
              </div>
              <FormControl
                variant="standard"
                className="sort-select-container"
                size="small"
              >
                <Select
                  value={sortBy}
                  onChange={handleSortChange}
                  className="sort-select"
                  displayEmpty
                >
                  <MenuItem value="rating">Sort By: Rating</MenuItem>
                  {/* <MenuItem value="popularity">Sort By: Popularity</MenuItem> */}
                  {/* <MenuItem value="returns">Sort By: Returns</MenuItem> */}
                  <MenuItem value="name">Sort By: Name</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TableContainer component={Paper} className="mf_table_container">
              <Table className="mf_table_root">
                <TableHead>
                  <TableRow>
                    <TableCell className="mf_scheme_header-icon mf_scheme_header">
                      Scheme Name <ArrowDropDownIcon />
                    </TableCell>
                    <TableCell align="right" className="mf_header_cell">
                      1Y
                    </TableCell>
                    <TableCell align="right" className="mf_header_cell">
                      2Y
                    </TableCell>
                    <TableCell align="right" className="mf_header_cell">
                      3Y
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFundsLoading ? (
                    <Loader />
                  ) : exploreListProducts.length > 0 ? (
                    exploreListProducts.map((fund) => (
                      <TableRow
                        key={fund.id}
                        className="mf_table_row"
                        onClick={() => {
                          recentProductList(fund);
                        }}
                      >
                        <TableCell className="mf_scheme_cell">
                          <div className="mf_scheme_content">
                            <img
                              src={fund?.amc?.icon}
                              alt=""
                              className="mf_fund_logo"
                            />
                            <div className="mf_scheme_details">
                              <div className="mf_scheme_name">
                                <Link
                                  component={RouterLink}
                                  to={"/mutualfund-details"}
                                  underline="hover"
                                  color="inherit"
                                  state={fund}
                                >
                                  {fund?.product_long_name}
                                </Link>
                              </div>
                              <div className="mf_scheme_info">
                                {/* commented risk field as per aparna ma'am because we don't have data according to risk */}
                                {/* <span className="mf_risk_label">
                                  {fund?.risk ? fund?.risk : "NA"}
                                </span> */}
                                {/* <span className="mf_dot">•</span> */}
                                <span className="mf_type_label">
                                  {fund?.asset_class ? fund?.asset_class : "NA"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="right" className="mf_returns_cell">
                          {fund?.nav?.return_12_months
                            ? fund?.nav?.return_12_months
                            : "NA"}
                        </TableCell>
                        <TableCell align="right" className="mf_returns_cell">
                          {fund?.nav?.return_24_months
                            ? fund?.nav?.return_24_months
                            : "NA"}
                        </TableCell>
                        <TableCell align="right" className="mf_returns_cell">
                          {fund?.nav?.return_36_months
                            ? fund?.nav?.return_36_months
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <p>No product in your funds list.</p>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              className="d-flex align-items-center justify-content-center"
              mt={2}
            >
              <Pagination
                count={totalPages} // Dynamic total pages
                page={currentPage} // Current page
                onChange={handlePageChange} // Update page on change
                color="primary"
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      <WebFooter />
    </>
  );
}

export default Dashboard;
