import React, { useState } from 'react';
import {
    Button,
    Typography,
    Grid,
    Box,
    Divider,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    MenuItem,
    Menu,
    Avatar,
    Card,
    CardContent,
    List,
    ListItem,
    IconButton,
    Tooltip,
    Stepper,
    Step,
    StepLabel,
    StepContent
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import Checkedcircle from "../assets/images/Checkedcircle.svg"
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import '../assets/css/pandetails.css';
import Fund1 from '../../src/assets/images/birla.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Sort as SortIcon } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import Graph from '../assets/images/graphicon.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TodayIcon from '@mui/icons-material/Today';
import SkipInstallmentPopup from '../components/Skipinstallmentpopup';
import StepUpSIPPopup from '../components/stepupsippopup';
import CancelSIPPopup from '../components/cancelsippopup';
import ChangeAmountDatePopup from '../components/changeammountpopup';

function SipDetails() {
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState("Due Date");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [isStepUpPopupOpen, setStepUpPopupOpen] = useState(false);
    const [isSkipInstallmentPopupOpen, setSkipInstallmentPopupOpen] = useState(false);
    const [isCancelSIPPopupOpen, setCancelSIPPopupOpen] = useState(false);
    const [isChangeAmountPopupOpen, setChangeAmountPopupOpen] = useState(false);
    // Handlers for Step-Up Popup
    const handleOpenStepUpPopup = () => setStepUpPopupOpen(true);
    const handleCloseStepUpPopup = () => setStepUpPopupOpen(false);
    // Handlers for Skip Installment Popup
    const handleOpenSkipInstallmentPopup = () => setSkipInstallmentPopupOpen(true);
    const handleCloseSkipInstallmentPopup = () => setSkipInstallmentPopupOpen(false);
    // Handlers for Cancel SIP Popup
    const handleOpenCancelSIPPopup = () => setCancelSIPPopupOpen(true);
    const handleCloseCancelSIPPopup = () => setCancelSIPPopupOpen(false);
    // Handlers for Change Amount/Date Popup
    const handleOpenChangeAmountPopup = () => setChangeAmountPopupOpen(true);
    const handleCloseChangeAmountPopup = () => setChangeAmountPopupOpen(false);

    const installments = [
        { id: 3, label: "3rd Instalment", date: "6 Dec '24", skip: true, status: "pending" },
        { id: 2, label: "2nd Instalment", date: "6 Nov '24", status: "paid" },
        { id: 1, label: "1st Instalment", date: "30 Sep '24", status: "paid" },
    ];

    // const CustomStepIcon = ({ step }) => {
    //     if (step.skip) {
    //         return <TodayIcon style={{ color: "#3E4154" }} />;
    //     }
    //     if (step.status === "paid") {
    //         return <img src={Checkedcircle} alt="Paid Icon" className="status-icon-color" />;
    //     }
    //     return <TodayIcon style={{ color: "#3E4154" }} />;
    // };



    // const steps = [
    //     {
    //         label: '3rd Instalment',
    //         description: `6 Dec ’24`,
    //     },
    //     {
    //         label: '2nd Instalment',
    //         description: `6 Nov ’24`,
    //     },
    //     {
    //         label: '1st Instalment',
    //         description: `30 Sep ’24`,
    //     },

    // ];


    // const CustomStepIcon = ({ label }) => {
    //     switch (label) {
    //         case "3rd Instalment":
    //             return <TodayIcon style={{ color: "#3E4154" }} />;
    //         case "2nd Instalment":
    //         case "1st Instalment":
    //             return <img src={Checkedcircle} alt="Checked Icon" className="status-icon-color" />;
    //         default:
    //             return null;
    //     }
    // };

    const steps = [
        {
            label: "3rd Instalment",
            description: "6 Dec ’24",
            icon: <TodayIcon style={{ color: "#3E4154", fontSize: "24px" }} />,
        },
        {
            label: "2nd Instalment",
            description: "6 Nov ’24",
            icon: <img src={Checkedcircle} alt="Checked Icon" style={{ width: "24px", height: "24px" }} />,
        },
        {
            label: "1st Instalment",
            description: "30 Sep ’24",
            icon: <img src={Checkedcircle} alt="Checked Icon" style={{ width: "24px", height: "24px" }} />,
        },
    ];

    return (
        <>
            <Header2 />
            <Grid className="pan-card-margin border-top">
                <Grid item xs={12} md={12}>
                    <Box className='d-flex back-bread mt-3 mb-3' >
                        <KeyboardArrowLeftIcon /><Typography>My SIPs</Typography>
                    </Box>
                    <Divider className="custom-hr" />
                </Grid>
            </Grid>
            <Grid className="wrapper pan-card-margin pan-topmar ">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8.5}>
                        <Box className="watchlist-card" mb={2}>
                            {/* Header Section */}
                            <Box className="card-content card-padding" mt={3} mb={3}>
                                <Box mb={1}>
                                    <img src={Fund1} alt="fund card img" />
                                </Box>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant="h5" sx={{ fontWeight: "500" }}>
                                        ₹500
                                    </Typography>
                                    <Typography ml={1} >
                                        6th of every month
                                    </Typography>
                                </Box>
                                <Typography mt={1} >
                                    SBI PSU Direct Plan Growth<IconButton>
                                        <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                                    </IconButton>
                                </Typography>
                                {/* Action Buttons */}
                                <Grid container spacing={2} sx={{ marginTop: 3 }}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            startIcon={<img src={Graph} alt="graph icon" />}
                                            sx={{
                                                textTransform: "none",
                                                backgroundColor: "rgba(233, 236, 255, 1)",
                                                boxShadow: 'none',
                                                color: "rgba(11, 36, 194, 1)",
                                                "&:hover": {
                                                    backgroundColor: "#E3E8FF",
                                                },
                                            }}
                                            onClick={handleOpenStepUpPopup}
                                        >
                                            Add step-up
                                        </Button>
                                    </Grid>
                                    {/* Change Amount/Date Button */}
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            startIcon={<EditIcon />}
                                            sx={{
                                                textTransform: "none",
                                                borderColor: "rgba(237, 237, 237, 1)",
                                                color: "rgba(62, 65, 84, 1)",
                                                "&:hover": {
                                                    backgroundColor: "#F9F9F9",
                                                    borderColor: "#C0C0C0",
                                                },
                                            }}
                                            onClick={handleOpenChangeAmountPopup}
                                        >
                                            Change amount/date
                                        </Button>
                                    </Grid>
                                    {/* Cancel SIP Button */}
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                textTransform: "none",
                                                borderColor: "rgba(237, 237, 237, 1)",
                                                color: "rgba(62, 65, 84, 1)",
                                                "&:hover": {
                                                    backgroundColor: "#F9F9F9",
                                                    borderColor: "#C0C0C0",
                                                },
                                            }}
                                            startIcon={<CloseIcon />}
                                            onClick={handleOpenCancelSIPPopup}
                                        >
                                            Cancel SIP
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {/* Details Section */}
                        <Box className="watchlist-card" mt={2} mb={2}>
                            <Box className="card-content card-padding" mt={3} mb={3}>
                                <Typography variant="h6" sx={{ fontWeight: "500" }} mb={2}>
                                    Details
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} mb={1}>
                                        <Typography variant="body2" sx={{ color: "gray" }} >
                                            AutoPay linked to
                                        </Typography>
                                        <Typography variant="body1">HDFC BANK XXXX1234</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={1}>
                                        <Typography variant="body2" sx={{ color: "gray" }} >
                                            AutoPay ID
                                        </Typography>
                                        <Typography variant="body1">
                                            ABCD1234567890123456789012
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={1}>
                                        <Typography variant="body2" sx={{ color: "gray" }} >
                                            SIP ID
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                            SIP1234567890123456789012
                                            <Tooltip title="Copy SIP ID">
                                                <IconButton sx={{ marginLeft: 1 }}>
                                                    <ContentCopyIcon className='copyicon' />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} mb={1}>
                                        <Typography variant="body2" sx={{ color: "gray" }}>
                                            Folio No.
                                        </Typography>
                                        <Typography variant="body1" sx={{ display: "flex", alignItems: "center" }}>
                                            12345678
                                            <Tooltip title="Copy Folio No.">
                                                <IconButton sx={{ marginLeft: 1 }}>
                                                    <ContentCopyIcon className='copyicon' />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <Box className="watchlist-card" mb={2}>
                            <Box className='card-content card-padding' mb={2} mt={2}>
                                <Typography variant='h6'>Instalments</Typography>
                            </Box>
                            <Divider />
                            <Box className="d-flex justify-content-between card-sip-details">
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                            <StepLabel
                                                icon={step.icon} // Custom icons for each step
                                                classes={{
                                                    root: "step-label-root",
                                                    label: "step-label-text",
                                                }}
                                            >
                                                <Box>
                                                    <Typography style={{ fontWeight: "400", color: "#3E4154", fontSize: "14px", }}>{step.label}</Typography>
                                                    <Typography style={{ fontWeight: "400", fontSize: "12px", color: "#565A71" }}>{step.description}</Typography>
                                                </Box>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Box className='mt-2'>
                                    <a className="skip-btn" onClick={handleOpenSkipInstallmentPopup}>
                                        Skip
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/* stepuppopup  */}
                    <StepUpSIPPopup
                        open={isStepUpPopupOpen}
                        handleClose={handleCloseStepUpPopup}
                    />
                    {/* Popup Component */}
                    <SkipInstallmentPopup
                        open={isSkipInstallmentPopupOpen}
                        handleClose={handleCloseSkipInstallmentPopup}
                    />
                    {/* Cancel SIP Popup */}
                    <CancelSIPPopup
                        open={isCancelSIPPopupOpen}
                        handleClose={handleCloseCancelSIPPopup}
                    />
                    {/* Change Amount/Date Popup */}
                    <ChangeAmountDatePopup
                        open={isChangeAmountPopupOpen}
                        handleClose={handleCloseChangeAmountPopup}
                    />
                </Grid>
            </Grid >
        </>
    );
}

export default SipDetails;
