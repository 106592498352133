import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { userChangePassword } from "../actions/userActions";
import { getLocalStorageUserData } from "../utils/userData";

export default function PassChanged({
  pass,
  newPass,
  placeText,
  mode = "password",
}) {
  const [showNewValue, setShowNewValue] = useState(false);
  const [showConfirmValue, setShowConfirmValue] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(false); // New state to trigger reset
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const handleToggleNewValue = () => setShowNewValue(!showNewValue);
  const handleToggleConfirmValue = () => setShowConfirmValue(!showConfirmValue);

  const validationSchema = Yup.object({
    new_password:
      mode === "password"
        ? Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("New password is required")
        : Yup.string()
            .length(4, "PIN must be 4 digits")
            .matches(/^\d+$/, "PIN must contain only numbers")
            .required("New PIN is required"),
    password_confirm: Yup.string()
      .oneOf(
        [Yup.ref("new_password"), null],
        `${mode === "password" ? "Passwords" : "PINs"} must match`
      )
      .required(
        `Confirm ${mode === "password" ? "password" : "PIN"} is required`
      ),
  });

  const initialValues = {
    email: "",
    new_password: "",
    password_confirm: "",
  };

  useEffect(() => {
    setResetTrigger((prev) => !prev); // Toggle the reset trigger when mode changes
  }, [mode]);

  return (
    <Formik
      key={resetTrigger} // Use key to force reset when mode changes
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const payload = {
          email: userData?.email,
          new_password: values?.new_password,
          password_confirm: values?.password_confirm,
        };
        dispatch(userChangePassword(payload));
        console.log("Form values:", values);
      }}
    >
      {({ values, handleChange }) => (
        <Form>
          <Box
            sx={{
              p: 3,
              overflowY: "auto",
              border: "1px solid rgba(237, 237, 237, 1)",
              borderRadius: "8px",
              backgroundColor: "#fff",
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                width: "50%",
                backgroundColor: "#fff",
                borderRadius: "12px",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            >
              <Typography mt={1} sx={{ fontSize: "16px" }}>
                {pass} <span style={{ color: "red" }}>*</span>
              </Typography>
              <Field
                as={TextField}
                name="new_password"
                placeholder={placeText}
                type={
                  showNewValue
                    ? "text"
                    : mode === "password"
                    ? "password"
                    : "text"
                }
                fullWidth
                margin="normal"
                value={values.new_password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleNewValue} edge="end">
                        {showNewValue ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: "8px",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "14px",
                    height: "50px",
                  },
                }}
              />
              <ErrorMessage
                name="new_password"
                component="div"
                style={{ color: "red", fontSize: "16px" }}
              />
              <Typography mt={3} sx={{ fontSize: "16px" }}>
                {newPass} <span style={{ color: "red" }}>*</span>
              </Typography>
              <Field
                as={TextField}
                name="password_confirm"
                placeholder={placeText}
                type={
                  showConfirmValue
                    ? "text"
                    : mode === "password"
                    ? "password"
                    : "text"
                }
                fullWidth
                margin="normal"
                value={values.password_confirm}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleToggleConfirmValue} edge="end">
                        {showConfirmValue ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    padding: "8px",
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    fontSize: "14px",
                    height: "50px",
                  },
                }}
              />
              <ErrorMessage
                name="password_confirm"
                component="div"
                style={{ color: "red", fontSize: "16px" }}
              />
              <Box mt={6}>
                <Button
                  type="submit"
                  variant="contained"
                  className="start-sipbtn"
                >
                  Update {mode === "password" ? "Password" : "PIN"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
