import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Link,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  MenuItem,
  Divider,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
} from "@mui/material";
import UPI from "../assets/images/upi.svg";
import Plus from "../assets/images/plus.svg";

import HDFC from "../assets/images/hdfc.svg";
import Info from "../assets/images/info.svg";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Redeem from "./redeem";

function Autopay({ onBack }) {
  const [showAutoPayDetails, setShowAutoPayDetails] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);

  const handleRedeemClick = () => {
    setShowRedeem(true);
  };

  const handleBackToMain = () => {
    setShowAutoPayDetails(false);
  };

  if (showRedeem) {
    return <Redeem onBack={handleBackToMain} />;
  }

  return (
    <>
      <Box className="card-content">
        <Typography className="font-600 align-center d-flex card-padding">
          <KeyboardBackspaceIcon sx={{ ml: -2, mt: 2 }} onClick={onBack} />
        </Typography>
        <Divider className="custom-hr" sx={{ my: 2 }} />
        <Accordion >
          <AccordionSummary>
            <Typography className="font-600">UPI</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup name="paymentOption" defaultValue="scanAndPay">
              <Box className="d-flex justify-between align-center">
                <Box className="d-flex">
                  <img src={UPI} alt="upi icon" />
                  <Typography sx={{ ml: 1 }}>Scan QR & Pay</Typography>
                </Box>
                <FormControlLabel
                  value="scanAndPay"
                  control={<Radio sx={{
                    ml: 1,
                    color: 'rgba(11, 36, 194, 1)', // Custom color for unselected state
                    '&.Mui-checked': {
                      color: 'rgba(11, 36, 194, 1)', // Custom color when selected
                    },
                  }} />}
                />
              </Box>
            </RadioGroup>
            <Box className="d-flex justify-center" mb={2}>
              <Box className="watchlist-card   w-84">
                <Box className="d-flex card-padding" sx={{ mt: 2, mb: 2 }}>
                  <img src={HDFC} alt="hdfc icon" />
                  <Typography sx={{ ml: 1 }} className="font-14">HDFC BANK....1234</Typography>
                </Box>
                <Box className="d-flex" alignItems='center' sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 2, mt: 2 }}>
                  <img src={Info} alt="hdfc icon" className="h-100" />
                  <Typography className="upi-text font-14" sx={{ ml: 1 }}>
                    Select the same bank on UPI app
                  </Typography>
                </Box>
              </Box>
            </Box>


            <Box className="card-padding" sx={{ mb: 2, mt: 2 }}>
              <Button className="start-sipbtn " fullWidth>
                Pay ₹500.00
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary>
            <Typography className="font-600">
              <RadioGroup name="paymentOption" defaultValue="scanAndPay">
                <Box className="d-flex justify-between align-center">
                  <Box className="d-flex">
                    <img src={Plus} alt="upi icon" />
                    <Typography sx={{ ml: 1, color: "#0B24C2" }}>
                      New UPI ID
                    </Typography>
                  </Box>
                  <Box>
                    <FormControlLabel
                      value="scanAndPay"
                      control={<Radio sx={{
                        ml: 1,
                        color: 'rgba(11, 36, 194, 1)', // Custom color for unselected state
                        '&.Mui-checked': {
                          color: 'rgba(11, 36, 194, 1)', // Custom color when selected
                        },
                      }} />}
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Box className="d-flex justify-center" mb={2}>
              <Box className="watchlist-card   w-84">
                <Box className="d-flex card-padding" sx={{ mt: 2, mb: 2 }}>
                  <img src={HDFC} alt="hdfc icon" />
                  <Typography sx={{ ml: 1 }} className="font-14">HDFC BANK....1234</Typography>
                </Box>
                <Box className="d-flex" alignItems='center' sx={{ bgcolor: "rgba(245, 245, 245, 1)", p: 2, mt: 2 }}>
                  <img src={Info} alt="info icon" className="h-100" />
                  <Typography className="upi-text font-14" sx={{ ml: 1 }}>
                    Select the same bank on UPI app
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Divider /> */}

            <Box className="card-padding" sx={{ mb: 2 }}>
              <TextField
                variant="standard"
                placeholder="Enter UPI ID"
                InputProps={{
                  disableUnderline: false, // Ensures underline stays
                }}
                sx={{
                  width: '100%', // Optional for full-width input
                  '& .MuiInputBase-root': {
                    color: '#B3B3B3', // Text color for placeholder
                  },
                }}
              />
            </Box>

            <Box className="card-padding" sx={{ mb: 2, mt: 2 }}>
              <Button className="start-sipbtn " fullWidth>
                Pay ₹500.00
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
        <Accordion >
          <AccordionSummary>
            <Typography className="font-600">Netbanking</Typography>
          </AccordionSummary>
          <Box className="card-padding d-flex justify-between align-center">
            <Box className="d-flex">
              <img src={HDFC} alt="hdfc icon" />

              <Typography ml={1}>HDFC BANK....1234</Typography>
            </Box>
            <FormControlLabel value="yes" control={<Radio style={{ marginRight: "-20px" }} sx={{
              color: 'rgba(11, 36, 194, 1)', // Custom color for unselected state
              '&.Mui-checked': {
                color: 'rgba(11, 36, 194, 1)', // Custom color when selected
              },
            }} />} />
          </Box>

          <Box className="card-padding" sx={{ mb: 2, mt: 2 }}>
            <Button className="start-sipbtn " fullWidth>
              Pay ₹500.00
            </Button>
          </Box>

        </Accordion>
        {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
        <Accordion>
          <AccordionSummary>
            <Typography className="font-600 ">Autopay</Typography>
          </AccordionSummary>
          <Box className="card-padding d-flex justify-between align-center">
            <Box className="d-flex">
              <img src={HDFC} alt="hdfc icon" />

              <Typography ml={1}>HDFC BANK....1234</Typography>
            </Box>
            <FormControlLabel value="yes" control={<Radio style={{ marginRight: "-20px" }} sx={{
              color: 'rgba(11, 36, 194, 1)', // Custom color for unselected state
              '&.Mui-checked': {
                color: 'rgba(11, 36, 194, 1)', // Custom color when selected
              },
            }} />} />
          </Box>
          <Box className="card-padding">
            <Typography
              sx={{ bgcolor: "#fde8e8", p: 2, borderRadius: 1, mb: 2 }}
              className="font-14"
            >
              Auto-debit in 2 working days. NAV applicable accordingly.
            </Typography>
          </Box>
          <Box className="card-padding" sx={{ mb: 2 }}>
            <Button className="start-sipbtn " fullWidth>
              Pay ₹500.00
            </Button>
          </Box>
        </Accordion>
      </Box>
    </>
  );
}

export default Autopay;
