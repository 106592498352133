import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    Collapse,
    Tabs,
    Tab,
    Menu,
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import WebFooter from "../components/Web-footer";
import GetStart from "../components/getstart-card-contact";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { sendEnquiryEmail } from "../api";
import { toast } from "react-toastify";
import Illustration from '../assets/images/emptyillustration.svg';
import Investment from '../../src/assets/images/investment.svg';
import SetupCard from "../components/setupcard";
import PopularFunds from "../components/popularfunds";
import DashboardInvestment from '../components/dashboard-investment';

function Dashboard() {
    const [hasInvestments, setHasInvestments] = useState(true);

    return (
        <>
            <Header2 />
            <div className="wrapper">
                <Grid container>
                    {!hasInvestments ? (
                        <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                            <Box className="image-section text-center mt-10">
                                <img src={Investment} alt="Illustration" className="dashimg" />
                                <Typography sx={{ mt: 3 }}>
                                    Your investments & holdings will show up here. Start investment
                                </Typography>
                            </Box>
                            <PopularFunds />
                        </Grid>
                        
                    ) : (
                        <DashboardInvestment />
                    )}
                </Grid>
            </div>
            <WebFooter />
        </>
    );
}

export default Dashboard;
