import * as React from "react";
import Accordion from "@mui/material/Accordion";
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
} from "@mui/material";
import '../assets/css/explore.css';
import { Link } from 'react-router-dom';
import cardimg from '../assets/images/Illustration.svg';
import Quick1 from '../assets/images/quick1.svg';
import Quick2 from '../assets/images/quick2.svg';
import Quick3 from '../assets/images/quick3.svg';
import Quick4 from '../assets/images/quick4.svg';
import Handpicked5 from '../assets/images/handpicked5.svg';
import Handpicked6 from '../assets/images/handpicked6.svg';

export default function Quickaccess() {
    return (
        <div className="wrapper">
            <Grid container textAlign="start" spacing={2}>
                <Grid item xs={12} md={8.5} display='flex' justifyContent='space-between'>
                    <Grid container textAlign="start" spacing={2} sx={{ mb: 4 }}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                            <Box>
                                <Typography variant="h6" className="explore-sections-head" >Quick Access</Typography>
                            </Box>
                        </Grid>
                        {/* I commented the other quick access menu for now as per Aparna ma'am inputs */}
                        {/* <Grid item xs={6} md={3} >
                            <Box className="setupcard">
                                <Box className="card-content card-content-link" component={Link} to="/coming-soon">
                                    <Box className="image-section">
                                        <img src={Quick1} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="quick-text">New Fund Offerings</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid> */}
                        <Grid item xs={6} md={3} >
                            <Box className="setupcard ">
                                <Box className="card-content card-content-link" component={Link} to="/externalfunds">
                                    <Box className="image-section">
                                        <img src={Quick2} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="quick-text">Import Funds</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={6} md={3}>
                            <Box className="setupcard">
                                <Box className="card-content card-content-link" component={Link} to="/coming-soon">
                                    <Box className="image-section">
                                        <img src={Quick3} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="quick-text">Compare Funds</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Box className="setupcard">
                                <Box className="card-content card-content-link" component={Link} to="/coming-soon">
                                    <Box className="image-section">
                                        <img src={Quick4} alt="fund card img" />
                                    </Box>
                                    <Box>
                                        <Typography className="quick-text">SIP Calculator</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
