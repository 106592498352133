import React, { useState } from 'react';
import {

    Grid,
    Box,

} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


import Invest from './investsip';


const data = [
    {
        name: 'SBI Magnum Mid Cap Direct Plan Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
    {
        name: 'Aditya Birla Sun Life PSU Equity Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: true,
    },
    {
        name: 'Motilal Oswal Midcap Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
];

export default function  DashboardInvestment() {
    const [selectedOption, setSelectedOption] = useState('invest');
    const [showOtpSection, setShowOtpSection] = useState(false);
    const [showAutoPayDetails, setShowAutoPayDetails] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);



    const handleStartSIP = () => {
        setShowOtpSection(true);
    };
    const handleBackToSIP = () => {
        setShowOtpSection(false);
    };
    const handleAutoPayClick = () => {
        setShowAutoPayDetails(true);
    };

    const handleBackToMain = () => {
        setShowAutoPayDetails(false);
    };

    const [rows, setRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...rows].sort((a, b) => {
            if (key === 'name') {
                return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            } else {
                const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ''));
                const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ''));
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            }
        });
        setRows(sortedData);
    };


    const getSortIcon = (key) => {
        return sortConfig.key === key ? (
            sortConfig.direction === 'asc' ? <ArrowDropUpIcon className='drop-icon' /> :
                <ArrowDropDownIcon className='drop-icon' />
        ) : null;
    };



    const toggleExpand = (index) => {
        setRows((prevRows) =>
            prevRows.map((row, i) => (i === index ? { ...row, expanded: !row.expanded } : row))
        );
    };

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleMenuOpen = (event, index) => setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    const handleMenuClose = () => setMenuAnchorEl(null);

    const handleRedeemClick = () => {
        setSelectedOption('redeem');
        handleMenuClose();
    };

    const handleInvestClick = () => {
        setSelectedOption('invest');
        handleMenuClose();
    };


    const navigate = useNavigate();

    const handleViewTransactionsClick = () => {
        navigate('/view-transactions');
    };

    const handleStartSwpClick = () => {
        navigate('/start-swp');
    };
    const handleStartStpClick = () => {
        navigate('/start-stp');
    };
    const handleSwitchFundClick = () => {
        navigate('/switch-fund');
    };

    return (

        <div >
            <Grid container textAlign="start" spacing={2}>

                <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} >
                            <Box className="watchlist-card">
                                {selectedOption === 'invest' && (

                                    <Box className="card-content">

                                        {!showOtpSection ? (
                                            !showAutoPayDetails ? (
                                                <>
                                                    <Invest />

                                                </>

                                            ) : (
                                                <div>

                                                </div>
                                            )

                                        ) : (

                                            <div> </div>
                                        )}

                                    </Box>
                                )}




                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}

