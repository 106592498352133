import {
  USER_START,
  USER_LOGIN,
  USER_VERIFY_LOGIN,
  USER_FORGOT_PASSWORD,
} from "../actions/types";

const initialState = {
  users: "",
  loading: false,
  usersVerifyLogin: "",
  usersForgotPassword: "",
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_START:
      return { ...state, loading: true };
    case USER_LOGIN:
      return { ...state, loading: false, users: action.payload };
    case USER_VERIFY_LOGIN:
      return { ...state, loading: false, usersVerifyLogin: action.payload };
    case USER_FORGOT_PASSWORD:
      return { ...state, loading: false, usersForgotPassword: action.payload };
    default:
      return state;
  }
};
export default usersReducer;
