import React, { useEffect } from "react";
import "../../assets/css/notification.css";
import IoMdClose from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationList, markAsRead } from "../../actions/notificationAction";
import { getLocalStorageUserId } from "../../utils/userData";

const NotificationPanel = ({ isOpen, onClose, notificationRef }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state) => state.notification?.notificationList?.data?.notifications || []
  );
  const userId = getLocalStorageUserId(); // Fetch user data from local storage

  // Fetch notifications when the panel is opened
  useEffect(() => {
    if (isOpen && userId) {
      const payload = { id: userId };
      dispatch(getNotificationList(payload));
    }
  }, [isOpen, userId, dispatch]);

  // Mark notification as read
  const markAsReadHandler = (notificationId) => {
    if (userId && notificationId) {
      const payload = { id: userId, notification_id: notificationId };
      dispatch(markAsRead(payload));
    }
  };

  if (!isOpen) return null;

  return (
    <div className="notification-panel" ref={notificationRef}>
      <div className="notification-header">
        <h4>Notifications</h4>
        <IoMdClose className="close-btn" onClick={onClose} />
      </div>

      <div className="notification-content">
        {notifications.length ? (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification-item ${
                notification.read_at ? "read" : "unread"
              }`}
              onClick={() => markAsReadHandler(notification.id)}
            >
              <div className="notification-details">
                <h6>{notification.data.model}</h6>
                <p>{notification.data.message}</p>
                <small>
                  {new Date(notification.created_at).toLocaleString()}
                </small>
              </div>
            </div>
          ))
        ) : (
          <p className="no-notifications">No notifications available</p>
        )}
      </div>
    </div>
  );
};

export default NotificationPanel;
