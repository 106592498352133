import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";

const VerifyPopup = ({ open, handleClose, popupType }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [step, setStep] = useState(1);

  const resetFields = () => {
    setOtp(Array(6).fill(""));
    setStep(1);
    formik.resetForm();
  };

  const validationSchema = Yup.object({
    mobileNumber: popupType === "mobile"
      ? Yup.string()
          .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
          .required("Mobile number is required")
      : null,
    email: popupType === "email"
      ? Yup.string()
          .email("Invalid email address")
          .required("Email is required")
      : null,
  });

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSendOtp();
    },
  });

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1);
    setOtp(newOtp);
    if (value && index < otp.length - 1) focusInput(index + 1);
  };

  const focusInput = (index) => {
    const input = document.querySelectorAll("input")[index];
    if (input) input.focus();
  };

  const handleSendOtp = () => {
    setStep(2);
  };

  const handleVerifyOtp = () => {
    console.log("Verified OTP:", otp.join(""));
    resetFields();
    handleClose();
  };

  const getTitle = () => {
    if (popupType === "mobile") {
      return step === 1 ? "Enter New Mobile Number" : "Verify Mobile OTP";
    } else if (popupType === "email") {
      return step === 1 ? "Enter New Email" : "Verify Email OTP";
    }
    return "";
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetFields();
        handleClose();
      }}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: "12px", padding: "8px" },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "600", color: "#3E4154" }}>
          {getTitle()} <span style={{color:'red'}}>*</span>
        </Typography>
        <IconButton
          onClick={() => {
            resetFields();
            handleClose();
          }}
          sx={{ padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "24px" }}>
        {popupType === "mobile" && step === 1 && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Enter Mobile Number"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
              sx={{ mb: 2 }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
              Send OTP
            </Button>
          </form>
        )}

        {popupType === "email" && step === 1 && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              variant="outlined"
              fullWidth
              id="email"
              name="email"
              placeholder="Enter Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={{ mb: 2 }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
              Send OTP
            </Button>
          </form>
        )}

        {step === 2 && (
          <>
            <Typography variant="body2" sx={{ mb: 2, color: "#1C1E23" }}>
              Enter a 6-digit OTP sent to your {popupType === "mobile" ? "Mobile" : "Email"}
            </Typography>

            <Box display="flex" gap={1} sx={{ mb: 2, justifyContent: "space-between" }}>
              {otp.map((digit, index) => (
                <TextField
                  key={index}
                  variant="standard"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      width: "40px",
                      height: "40px",
                      fontSize: "1.2rem",
                      textAlign: "center",
                      color: "#000",
                      backgroundColor: "transparent",
                      border: "none",
                      borderBottom: "2px solid rgba(62, 65, 84, 1)",
                    },
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </DialogContent>

      {step === 2 && (
        <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
          <Button
            onClick={handleVerifyOtp}
            fullWidth
            sx={{ textTransform: "none", width: "40%", height: "48px", borderRadius: "8px" }}
          >
            Verify OTP
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VerifyPopup;
