import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    Collapse,
    Tabs,
    Tab,
    Menu,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputAdornment,
    Checkbox,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ExpandMore, ExpandLess, MoreVert, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EventIcon from '@mui/icons-material/Event';
import OTPInput from '../../pages/home/opt';
import InfoIcon from '@mui/icons-material/Info';
import Autopay from '../../components/autopay';
import Fund1 from '../../assets/images/birla.svg';
import Redeem from '../../components/redeem';
import Invest from '../../components/investsip';
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";



const data = [
    {
        name: 'SBI Magnum Mid Cap Direct Plan Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
    {
        name: 'Aditya Birla Sun Life PSU Equity Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: true, // Expanded to show details by default for demonstration
    },
    {
        name: 'Motilal Oswal Midcap Fund Direct Growth',
        dayChange: '₹123 (12%)',
        returns: '-97 (10%)',
        current: '₹5,219',
        expanded: false,
    },
];

export default function DashboardInvestment() {
    const [selectedOption, setSelectedOption] = useState('invest');
    const [showOtpSection, setShowOtpSection] = useState(false);
    const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);



    const handleStartSIP = () => {
        setShowOtpSection(true);  // Show OTP section when 'Start SIP' is clicked
    };
    const handleBackToSIP = () => {
        setShowOtpSection(false);  // Hide OTP section and go back to the SIP section
    };
    const handleAutoPayClick = () => {
        setShowAutoPayDetails(true); // Show the AutoPay details section
    };

    const handleBackToMain = () => {
        setShowAutoPayDetails(false); // Go back to the main card view
    };
    const validationSchema = Yup.object().shape({
        otp: Yup.string().when("verifyOtp", (verifyOtp, schema) => {
              return verifyOtp[0] === true
                ? schema
                    .required("OTP is required")
                    .length(6, "OTP must be exactly 6 digits")
                : schema;
            }),
    })

    const formik = useFormik({
        initialValues:{
            otp:''
        },
        enableReinitialize: true,
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            console.log("values",values);
        }
    });

    const [rows, setRows] = useState(data);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedData = [...rows].sort((a, b) => {
            if (key === 'name') {
                return direction === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            } else {
                const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ''));
                const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ''));
                return direction === 'asc' ? aValue - bValue : bValue - aValue;
            }
        });
        setRows(sortedData);
    };


    const getSortIcon = (key) => {
        return sortConfig.key === key ? (
            sortConfig.direction === 'asc' ? <ArrowDropUpIcon className='drop-icon' /> :
                <ArrowDropDownIcon className='drop-icon' />
        ) : null;
    };

    // more icon options

    const toggleExpand = (index) => {
        setRows((prevRows) =>
            prevRows.map((row, i) => (i === index ? { ...row, expanded: !row.expanded } : row))
        );
    };

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleMenuOpen = (event, index) => setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    const handleMenuClose = () => setMenuAnchorEl(null);

    const handleRedeemClick = () => {
        setSelectedOption('redeem');
        handleMenuClose();
    };

    const handleInvestClick = () => {
        setSelectedOption('invest');
        handleMenuClose();
    };

    // view transaction
    const navigate = useNavigate();

    const handleViewTransactionsClick = () => {
        navigate('/view-transactions'); // Navigates to the View Transactions page
    };

    const handleStartSwpClick = () => {
        navigate('/start-swp'); // Navigates to the View Transactions page
    };
    const handleStartStpClick = () => {
        navigate('/start-stp'); // Navigates to the View Transactions page
    };
    const handleSwitchFundClick = () => {
        navigate('/switch-fund'); // Navigates to the View Transactions page
    };

    return (
        <div >
            <Grid container textAlign="start" spacing={2}>


                <Grid item xs={12} md={12} sx={{ mb: 6 }}>
                    <Grid container textAlign="start" spacing={2}>
                        <Grid item xs={12} md={12} >
                            <Box className="watchlist-card">
                                {selectedOption === 'invest' && (
                                    <Box className="card-content">
                                        {!showOtpSection ? (
                                            !showAutoPayDetails ? (
                                                <>
                                                    <Invest />
                                                </>
                                            ) : (
                                                <div>
                                                    <Autopay onBack={handleBackToMain} />
                                                </div>
                                            )
                                        ) : (
                                            <Box className="card-content">
                                                <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                                                    <KeyboardBackspaceIcon sx={{ mr: 2 }} onClick={handleBackToSIP} /> Authorize with OTP
                                                </Typography>
                                                <Divider className="custom-hr" sx={{ my: 2 }} />
                                                <Box alignItems="center" textAlign='center' mb={2} >
                                                    <Typography sx={{ mb: 4, mt: 2 }}>Enter a 6-digit OTP sent to abc@gmail.com &
                                                        9999999999</Typography>
                                                    <OTPInput inputStyles={{ width: '30px', height: '30px', }} length={6} formik={formik} />
                                                    <Typography sx={{ mt: 2 }}>Didn't receive OTP?
                                                        <a className='text-blue resend-link'>Resend</a>
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ mb: 2, mt: 6 }} className='card-padding'>
                                                    <Button className='confirmbtn' fullWidth sx={{ mr: 1 }}>
                                                        Confirm
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                {selectedOption === 'redeem' && (
                                    <Redeem />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}

