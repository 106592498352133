import CryptoJS from "crypto-js";
import { toast } from "react-toastify";

const secretKey = process.env.REACT_APP_SECRETKEY;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const getLocalStorageUserData = (key) => {
  try {
    const value = localStorage.getItem("userData");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData ? decryptedData : null;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const getLocalStorageUserToken = (key) => {
  try {
    const value = localStorage.getItem("token");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const getLocalStorageUserId = (key) => {
  try {
    const value = localStorage.getItem("userData");
    if (value) {
      const decryptedData = decryptData(value);
      return decryptedData.id;
    }
  } catch (error) {
    console.error(`Error getting localStorage key "${key}":`, error);
    return null;
  }
};

export const redirectToLoginIfNoToken = () => {
  const token = getLocalStorageUserToken();
  if (!token) {
    window.location.href = "/login";
  }
};

export const logoutUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userData");
  toast.success("Logout successfully!");
  setTimeout(() => {
    window.location.href = "/";
  }, 1000);
};

export const getTime = () => {
  const now = new Date();
  const formattedTime = `${now.getFullYear()}-${String(
    now.getMonth() + 1
  ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")} ${String(
    now.getHours()
  ).padStart(2, "0")}:${String(now.getMinutes()).padStart(2, "0")}:${String(
    now.getSeconds()
  ).padStart(2, "0")}`;
  return formattedTime;
};
