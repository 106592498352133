import React, { useState } from 'react';
import Accordion from "@mui/material/Accordion";
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Box,
    Avatar,
    Link,
} from "@mui/material";
import VerifyMobilePopup from './verifymobilepopup';
import '../assets/css/explore.css';
import cardimg from '../assets/images/Illustration.svg';
import attention from '../../src/assets/images/attention.svg';


export default function SetupCard() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };
    return (
        <div className="wrapper">
            <Grid container py={4}>
                <Grid item xs={12} md={3.5} sx={{ mt: 6 }}>
                    <Box className="setupcard">
                        <Box className="card-content text-center">
                            <Box className="image-section">
                                <img src={attention} alt="Illustration" />
                            </Box>

                            {/* sx={{ ml: 2 }} */}

                            <Box>
                                <Box className="text-section">
                                    <Typography variant="h6" fontWeight="600" sx={{ mb: 2 }}>Attention Required!</Typography>
                                    <Typography variant="body2" sx={{ mb: 4 }}>
                                        Complete setting up your account to start investing
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 4 }}>
                                    <Button variant="contained" fullWidth className="login-button" onClick={handleOpenPopup}>
                                        Complete Setup
                                    </Button>
                                    <VerifyMobilePopup open={isPopupOpen} handleClose={handleClosePopup} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
