import React from 'react';
import {
    Box,
    Typography,
    Button,
    Divider,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from 'dayjs';

const HoldingStatement = () => {
    // Validation schema
    const validationSchema = Yup.object().shape({
        selectedDate: Yup.date()
            .nullable()
            .required("Please select a date."),
    });

    const initialValues = {
        selectedDate: null,
    };

    const handleSubmit = (values) => {
        console.log("Selected Date:", values.selectedDate);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Box className="card-content w-100 watchlist-card">
                        <Typography mt={2} className="font-600 align-center d-flex card-padding">
                            Mutual Funds - Holdings Statement
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        {/* Date Picker Section */}
                        <Box className="d-flex justify-between watchlist-textpadding mb-150">
                            <Box sx={{ width: "100%" }}>
                                <Typography className='font-14'>
                                    Choose date <span style={{color:'red'}}>*</span>
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={values.selectedDate}
                                        onChange={(newValue) => setFieldValue("selectedDate", newValue)}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                <ErrorMessage
                                    name="selectedDate"
                                    component="div"
                                    style={{ color: "red", fontSize: "16px", marginTop: "4px" }}
                                />
                            </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        {/* Download Button Section */}
                        <Box className="card-padding" sx={{ mb: 2 }}>
                            <Button
                                type="submit"
                                className="start-sipbtn"
                                variant="contained"
                                fullWidth
                                sx={{ bgcolor: "#1C5DB8", color: "#fff", mt: 2, mb: 2 }}
                            >
                                Download
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default HoldingStatement;
