import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Link,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  MenuItem,
  Divider,
  Collapse,
  Tabs,
  Tab,
  Menu,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Invests from "../../src/assets/images/Invest.svg";
import Redeems from "../../src/assets/images/Redeem.svg";
import ViewTrans from "../../src/assets/images/ViewTrans.svg";
import StartSWP from "../../src/assets/images/StartSWP.svg";
import StartSTP from "../../src/assets/images/StartSTP.svg";
import SwitchFund from "../../src/assets/images/SwitchFund.svg";
import {
  ExpandMore,
  ExpandLess,
  MoreVert,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EventIcon from "@mui/icons-material/Event";
import OTPInput from "../pages/home/opt";
import InfoIcon from "@mui/icons-material/Info";
import Autopay from "./autopay";
import Fund1 from "../assets/images/birla.svg";
import Redeem from "./redeem";
import Invest from "./investsip";

const data = [
  {
    name: "SBI Magnum Mid Cap Direct Plan Growth",
    dayChange: "₹10 (12%)",
    returns: "-58 (10%)",
    current: "₹5,219",
    expanded: false,
  },
  {
    name: "Aditya Birla Sun Life PSU Equity Fund Direct Growth",
    dayChange: "₹123 (12%)",
    returns: "-90 (10%)",
    current: "₹5,000",
    expanded: false, // Expanded to show details by default for demonstration
  },
  {
    name: "Motilal Oswal Midcap Fund Direct Growth",
    dayChange: "₹150 (12%)",
    returns: "-97 (10%)",
    current: "₹4,219",
    expanded: false,
  },
];

export default function DashboardInvestment() {
  const [selectedOption, setSelectedOption] = useState("invest");
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [investValue, setInvestValue] = useState("");
  const handleStartSIP = () => {
    setShowOtpSection(true); // Show OTP section when 'Start SIP' is clicked
  };
  const handleBackToSIP = () => {
    setShowOtpSection(false); // Hide OTP section and go back to the SIP section
  };
  const handleAutoPayClick = () => {
    setShowAutoPayDetails(true); // Show the AutoPay details section
  };

  const handleBackToMain = () => {
    setShowAutoPayDetails(false); // Go back to the main card view
  };

  const [rows, setRows] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...rows].sort((a, b) => {
      if (key === "name") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ""));
        const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ""));
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
    setRows(sortedData);
  };

  const getSortIcon = (key) => {
    return sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowDropUpIcon className="drop-icon" />
      ) : (
        <ArrowDropDownIcon className="drop-icon" />
      )
    ) : null;
  };

  // more icon options

  const toggleExpand = (index) => {
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, expanded: !row.expanded } : row
      )
    );
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event, index) =>
    setMenuAnchorEl({ anchorEl: event.currentTarget, index });
  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleRedeemClick = () => {
    setSelectedOption("redeem");
    handleMenuClose();
  };

  const handleInvestClick = () => {
    setSelectedOption("invest");
    handleMenuClose();
  };

  // view transaction
  const navigate = useNavigate();

  const handleViewTransactionsClick = () => {
    navigate("/view-transactions"); // Navigates to the View Transactions page
  };

  const handleStartSwpClick = () => {
    navigate("/start-swp"); // Navigates to the View Transactions page
  };
  const handleStartStpClick = () => {
    navigate("/start-stp"); // Navigates to the View Transactions page
  };
  const handleSwitchFundClick = () => {
    navigate("/switch-fund"); // Navigates to the View Transactions page
  };

  return (
    <div className="wrapper" style={{ marginTop: "100px" }}>
      <Grid container textAlign="start" spacing={2}>
        <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>
          <Grid container textAlign="start" spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h6" className="explore-sections-head">
                  Investments (3)
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
              <Box className="investcard">
                <Box sx={{ mb: 5 }} className="d-flex">
                  <Box>
                    <Typography variant="h6" className="investemnt-card-head">
                      Your Investments
                    </Typography>
                    {/* I commented this for now as per Aparna ma'am inputs and due to this section not making sense or we don't know about this */}
                    {/* <Typography variant="h6" className="investemnt-card-head">
                      Arun Patil’s Folio
                    </Typography>
                    <Typography className="investemnt-card-head2">
                      123456789012
                    </Typography> */}
                  </Box>
                  <Box>
                    <Typography
                      className="investemnt-card-head2"
                      sx={{ ml: 20, mb: 1 }}
                    >
                      03
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ ml: 20 }}
                    >
                      Schemes
                    </Typography>
                  </Box>
                </Box>
                <Grid container spacing={2} className="justify-between">
                  <Grid item>
                    <Typography className="investemnt-card-head2">
                      ₹5,219
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Current Value
                    </Typography>
                  </Grid>
                  {/* Vertical line */}
                  <Grid item>
                    <div
                      style={{
                        height: "120%",
                        width: "2px",
                        backgroundColor: "#EDEDED", // Spacing between the vertical line and the grid items
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography className="investemnt-card-head2">
                      ₹5,000
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Invested Value
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h5"
                      className="investemnt-card-totalreturnhead "
                    >
                      +₹295 (6.00%)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Total Returns
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className="investment-1dreturn">
                      -₹58 (1.11%)
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      1D Returns
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className="investment-1dreturn">
                      NA
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      XIRR
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <TableContainer elevation={3}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle2"
                          className="white-space-nowrap"
                        >
                          Scheme Name
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleSort("name")}
                        >
                          {getSortIcon("name") || (
                            <ArrowDropDownIcon className="drop-icon" />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          variant="subtitle2"
                          className="white-space-nowrap"
                        >
                          Day Change
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleSort("dayChange")}
                        >
                          {getSortIcon("dayChange") || (
                            <ArrowDropDownIcon className="drop-icon" />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          variant="subtitle2"
                          className="white-space-nowrap"
                        >
                          Returns (%)
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleSort("returns")}
                        >
                          {getSortIcon("returns") || (
                            <ArrowDropDownIcon className="drop-icon" />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          variant="subtitle2"
                          className="white-space-nowrap"
                        >
                          Current
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleSort("current")}
                        >
                          {getSortIcon("current") || (
                            <ArrowDropDownIcon className="drop-icon" />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell sx={{ p: 0 }}>
                          <Box display="flex" alignItems="center">
                            <IconButton
                              onClick={() => toggleExpand(index)}
                              size="small"
                            >
                              {row.expanded ? (
                                <ArrowDropUpIcon className="drop-icon" />
                              ) : (
                                <ArrowDropDownIcon className="drop-icon" />
                              )}
                            </IconButton>
                            <Typography
                              variant="body1"
                              onClick={() => setInvestValue(row.name)}
                            >
                              {row.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography component="span" sx={{ color: "red" }}>
                            {row.dayChange.split(" ")[0]}
                          </Typography>
                          <Typography component="span" sx={{ color: "black" }}>
                            {row.dayChange.slice(row.dayChange.indexOf(" "))}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography component="span" sx={{ color: "red" }}>
                            {row.returns.split(" ")[0]}
                          </Typography>
                          <Typography component="span" sx={{ color: "black" }}>
                            {row.returns.slice(row.returns.indexOf(" "))}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ color: "red" }}>
                          {row.current}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={(event) => handleMenuOpen(event, index)}
                          >
                            <MoreVert />
                          </IconButton>
                          {menuAnchorEl?.index === index && (
                            <Menu
                              anchorEl={menuAnchorEl.anchorEl}
                              open={Boolean(menuAnchorEl)}
                              onClose={handleMenuClose}
                            >
                              <MenuItem onClick={handleInvestClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={Invests}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  {/* <div className='square'></div> */}
                                  <Typography sx={{ marginLeft: 2 }}>
                                    Invest
                                  </Typography>
                                </Box>
                              </MenuItem>
                              <Divider className="custom-hr" />
                              <MenuItem onClick={handleRedeemClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={Redeems}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  <Typography sx={{ marginLeft: 2 }}>
                                    Redeem
                                  </Typography>
                                </Box>
                              </MenuItem>
                              <Divider className="custom-hr" />
                              <MenuItem onClick={handleViewTransactionsClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={ViewTrans}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  <Typography sx={{ marginLeft: 2 }}>
                                    View Transactions
                                  </Typography>
                                </Box>
                              </MenuItem>
                              <Divider className="custom-hr" />
                              <MenuItem onClick={handleStartSwpClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={StartSWP}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  <Typography sx={{ marginLeft: 2 }}>
                                    Start SWP
                                  </Typography>
                                </Box>
                              </MenuItem>
                              <Divider className="custom-hr" />
                              <MenuItem onClick={handleStartStpClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={StartSTP}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  <Typography sx={{ marginLeft: 2 }}>
                                    Start STP
                                  </Typography>
                                </Box>
                              </MenuItem>
                              <Divider className="custom-hr" />
                              <MenuItem onClick={handleSwitchFundClick}>
                                <Box display="flex" alignItems="center">
                                  <img
                                    src={SwitchFund}
                                    alt="Illustration"
                                    className="dashimg"
                                  />
                                  <Typography sx={{ marginLeft: 2 }}>
                                    Switch Funds
                                  </Typography>
                                </Box>
                              </MenuItem>
                            </Menu>
                          )}
                        </TableCell>
                      </TableRow>
                      {row.expanded && (
                        <TableRow>
                          <TableCell
                            colSpan={5}
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                          >
                            <Collapse
                              in={row.expanded}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box className="accordianbg">
                                <Box
                                  display="flex"
                                  justifyContent="space-around"
                                >
                                  <Box>
                                    <Typography variant="h5">₹5,219</Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Current Value
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="h5">₹5000</Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Invested Value
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      className="text-green"
                                    >
                                      +₹295 (6.00%)
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Total Returns
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      className="text-red"
                                    >
                                      -₹58 (1.11%)
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      1D Returns
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      className="text-red"
                                    >
                                      NA
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      XIRR
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
          <Grid container textAlign="start" spacing={2}>
            <Grid item xs={12} md={12}>
              <Box className="watchlist-card">
                {selectedOption === "invest" && (
                  <Box className="card-content">
                    {!showOtpSection ? (
                      !showAutoPayDetails ? (
                        <>
                          <Invest investValue={investValue} />
                        </>
                      ) : (
                        <div>
                          <Autopay onBack={handleBackToMain} />
                        </div>
                      )
                    ) : (
                      <Box className="card-content">
                        <Typography
                          sx={{ mt: 2 }}
                          className="font-600 align-center d-flex card-padding"
                        >
                          <KeyboardBackspaceIcon
                            sx={{ mr: 2 }}
                            onClick={handleBackToSIP}
                          />{" "}
                          Authorize with OTP
                        </Typography>
                        <Divider className="custom-hr" sx={{ my: 2 }} />
                        <Box alignItems="center" textAlign="center" mb={2}>
                          <Typography sx={{ mb: 4, mt: 2 }}>
                            Enter a 6-digit OTP sent to abc@gmail.com &
                            9999999999
                          </Typography>
                          <OTPInput
                            inputStyles={{ width: "30px", height: "30px" }}
                            separatorWidth={0}
                          />
                          <Typography sx={{ mt: 2 }}>
                            Didn't receive OTP?
                            <a className="text-blue resend-link">Resend</a>
                          </Typography>
                        </Box>
                        <Box sx={{ mb: 2, mt: 6 }} className="card-padding">
                          <Button
                            className="confirmbtn"
                            fullWidth
                            sx={{ mr: 1 }}
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                {selectedOption === "redeem" && <Redeem />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
