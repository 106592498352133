import React, { useState, useCallback, useEffect, useRef } from "react";
import Header2 from "../../pages/home/Appbar2";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  InputAdornment,
  Breadcrumbs,
  Link,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import "../../assets/css/funddetails.css";
import "../../assets/css//mycard.css";
import DeleteIcon from "@mui/icons-material/Delete";
import WatchlistInvestment from "./Watchlist-investment";
// import WebFooter from "../../";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getWatchList, deleteWatchList } from "../../actions/watchListAction";
import Loader from "../../loader/Loader-ui";
import { getLocalStorageUserId } from "../../utils/userData";
import { Link as RouterLink } from "react-router-dom";

function Dashboard() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isFetched = useRef(false);
  const userId = getLocalStorageUserId();
  // Extracting necessary state with default values for safe destructuring
  const { getWatchList: watchListData = {} } = useSelector(
    (state) => state.watchList
  );
  const watchListProducts = watchListData?.data?.products || [];
  // Function to fetch watchlist data
  const fetchWatchList = useCallback(async () => {
    if (userId) {
      try {
        setIsLoading(true);
        await dispatch(getWatchList({ id: userId }));
      } catch (error) {
        console.error("Error fetching watchlist:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [dispatch, userId]);

  // Fetch watchlist on component mount
  useEffect(() => {
    if (!isFetched.current) {
      fetchWatchList();
      isFetched.current = true;
    }
  }, [fetchWatchList]);

  // Memoized delete handler to avoid unnecessary re-renders
  const handleDelete = useCallback(
    (productId) => {
      if (userId) {
        try {
          setIsLoading(true); // Hide loader
          dispatch(deleteWatchList({ id: userId, product_id: productId }));
        } catch (error) {
          console.error("Error deleting item from watchlist:", error);
        } finally {
          setIsLoading(false); // Hide loader
        }
      }
    },
    [dispatch, userId]
  );

  return (
    <>
      <Header2 />
      <div className="wrapper mt-7 border-top">
        <Grid container>
          <Grid item xs={12} md={12} sx={{ mb: 4 }}>
            <Box className="mt-3 mb-3">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  component={RouterLink}
                  to="/"
                  underline="hover"
                  color="inherit"
                >
                  Home
                </Link>
                <Link
                  component={RouterLink}
                  to="/explore"
                  underline="hover"
                  color="inherit"
                >
                  Mutual Funds Explore
                </Link>
                <Typography color="text.primary">Watchlist</Typography>
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography className="mycard-header"> Watchlist </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7.9}>
            <TextField
              sx={{ mt: 3 }}
              fullWidth
              placeholder="Type the code or search branch"
              variant="outlined"
              className="watchlist_search_field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="receipt-wrapper" spacing={3}>
          <Grid item xs={12} md={8} sm={12} className="pl-0">
            <Box>
              <TableContainer component={Paper} className="mf_table_container">
                <Table className="mf_table_root">
                  <TableHead>
                    <TableRow>
                      <TableCell className="mf_header_cell mf_scheme_header">
                        Scheme Name <ArrowDropDownIcon />
                      </TableCell>
                      <TableCell align="right" className="mf_header_cell">
                        1Y
                      </TableCell>
                      <TableCell align="right" className="mf_header_cell">
                        2Y
                      </TableCell>
                      <TableCell align="right" className="mf_header_cell">
                        3Y
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <Loader />
                    ) : watchListProducts.length > 0 ? (
                      watchListProducts.map((fund) => (
                        <TableRow key={fund.id} className="mf_table_row">
                          <TableCell className="mf_scheme_cell">
                            <div className="mf_scheme_content">
                              <img
                                src={fund?.amc?.icon || ""}
                                alt=""
                                className="mf_fund_logo"
                              />
                              <div className="mf_scheme_details">
                                <div className="mf_scheme_name">
                                  {fund?.product_long_name}
                                </div>
                                <div className="mf_scheme_info">
                                  <span className="mf_risk_label">
                                    {fund?.risk ? fund?.risk : "NA"}
                                  </span>
                                  <span className="mf_dot">•</span>
                                  <span className="mf_type_label">
                                    {fund?.asset_class
                                      ? fund?.asset_class
                                      : "NA"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="right" className="mf_returns_cell">
                            NA
                          </TableCell>
                          <TableCell align="right" className="mf_returns_cell">
                            NA
                          </TableCell>
                          <TableCell align="right" className="mf_returns_cell">
                            NA
                          </TableCell>
                          <TableCell align="right" className="mf_action_cell">
                            <IconButton
                              color="error"
                              onClick={() => handleDelete(fund.id)}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <p>No items in your watchlist.</p>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {/* <Box className='d-flex align-items-center justify-content-center' mt={2}>
                            <Pagination count={5} color="primary" />
                        </Box> */}
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <div>
              <WatchlistInvestment />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Dashboard;
