import React, { useState } from 'react';
import {
    Button,
    Typography,
    Link,
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from "@mui/material";
import UPI from '../../assets/images/upi.svg';
import HDFC from '../../assets/images/htdf.svg';
import "../../assets/css/home.css";
import "../../assets/css/common.css";
import "../../assets/css/aboutus.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OTPInput from './opt';
import Autopay from '../../components/autopay';
import Authorisedotp from '../../components/authorised-otp';
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";

function Invest({ onBack, hideHeader = false }) {
    const [tabValue, setTabValue] = useState(0);
    const [showOtpSection, setShowOtpSection] = useState(false);
     const [showAutopay, setShowAutopay] = useState(false);
      const [showAuthorisedOTP, setShowAuthorisedOTP] = useState(false);

    const handleStartSIP = () => {
        setShowOtpSection(true); // Show the OTP section
    };
    const validationSchema = Yup.object().shape({
        otp: Yup.string().when("verifyOtp", (verifyOtp, schema) => {
              return verifyOtp[0] === true
                ? schema
                    .required("OTP is required")
                    .length(6, "OTP must be exactly 6 digits")
                : schema;
            }),
    })
    const formik = useFormik({
        initialValues:{
            otp:''
        },
        enableReinitialize: true,
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            console.log("values",values);
        }
    });

    const handleAutoPayClick = () => {
        setShowAutopay(true); // Show Autopay component
      };
    const handleBackToSIP = () => {
        setShowOtpSection(false); // Return to the old UI
    };
    const handleBackToInvest = () => {
        setShowAutopay(false); // Navigate back from Autopay
        setShowAuthorisedOTP(false); // Navigate back from AuthorisedOTP
      };
    if (showAutopay) {
        return <Autopay onBack={handleBackToInvest} />;
      }
      if (showAuthorisedOTP) {
        return <Authorisedotp onBack={handleBackToInvest} />;
      }

    if (showOtpSection) {
        return (
            <Box className="card-content">
                <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                    <KeyboardBackspaceIcon sx={{ mr: 2 }} onClick={handleBackToSIP} />
                    Authorize with OTP
                </Typography>
                <Divider className="custom-hr" sx={{ my: 2 }} />
                <Box alignItems="center" textAlign='center' mb={2}>
                    <Typography sx={{ mb: 4, mt: 2, color: '#565A71' }}>
                        Enter a 6-digit OTP sent to abc@gmail.com & 9999999999
                    </Typography>
                    <OTPInput inputStyles={{ width: '30px', height: '30px' }} length={6} formik={formik} />
                    <Typography sx={{ mt: 2, color: '#565A71' }}>
                        Didn't receive OTP?{' '}
                        <a className='text-blue resend-link'>Resend</a>
                    </Typography>
                </Box>
                <Box sx={{ mb: 2, mt: 6 }} className='card-padding'>
                    <Button className='confirmbtn-mycart' fullWidth sx={{ mr: 1 }}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <>
            {!hideHeader && (
                <>
                    <Box className="amount-container">
                        <Typography variant="h4" className="amount_text">
                            ₹5,500
                        </Typography>
                        <Typography variant="body2" className="amount_labell">
                            Amount payable
                        </Typography>
                    </Box>
                    <Box className="terms-section">
                        <Typography variant="body2">
                            By continuing, you agree to{' '}
                            <Link href="/terms-conditions" className="terms-link">
                                Terms & Conditions
                            </Link>
                        </Typography>
                    </Box>
                </>
            )}
            <Divider className="custom-hr" sx={{ my: 2 }} />
            <Box>
                {/* <List className="payment-methods">
                    <ListItem className="payment-method-item">
                        <ListItemIcon>
                            <img src={UPI} alt="upi icon" />
                        </ListItemIcon>
                        <ListItemText primary="Scan QR & Pay" className="method-text" />
                    </ListItem>
                    <ListItem className="payment-method-item">
                        <ListItemIcon>
                            <img src={HDFC} alt="hdfc icon" />
                        </ListItemIcon>
                        <ListItemText primary="HDFC BANK ••••1234" className="method-text" />
                    </ListItem>
                </List> */}

                <Box className=" investcard-textpadding">
                    <Box >
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                            <Typography sx={{ mb: 1, mt: 2 }} className="d-flex">
                                <img src={UPI} alt="upi icon" /><Typography className='font-14' fontWeight={500} sx={{ ml: 1 }}> Scan QR & Pay</Typography>
                            </Typography>
                            <Box>
                                <IconButton onClick={handleAutoPayClick}>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Typography sx={{ mb: 2, mt: 1 }} className="d-flex investcard-textpadding">
                            <img src={HDFC} alt="hdfc" /><Typography className='font-14' sx={{ ml: 1 }}> HDFC BANK....1234</Typography>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Divider className="custom-hr" sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2, mt: 1 }} className='watchlist-textpadding'>
                <Button className='Proceedtoay-btn' fullWidth sx={{ ml: 1 }} onClick={handleStartSIP}>
                    Proceed to pay
                </Button>
            </Box>
        </>
    );
}

export default Invest;
