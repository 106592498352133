import React, { useState, useRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Container,
  Stack,
} from "@mui/material";
import "../../assets/css/onbording.css";

import Header2 from "../../pages/home/Appbar2";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { userSetPin } from "../../actions/userActions";
import { getLocalStorageUserId } from "../../utils/userData";
import { toast } from "react-toastify";

const VerifyMobileCard = () => {
  const [pin, setPin] = useState(Array(4).fill(""));
  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = getLocalStorageUserId();

  const handlePinChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value.slice(-1);
      setPin(newPin);
      formik.setFieldValue("pin", newPin.join(""));
      if (value && index < pin.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      const newPin = [...pin];
      if (!pin[index] && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
      newPin[index] = "";
      setPin(newPin);
      formik.setFieldValue("pin", newPin.join(""));
    }
  };

  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .required("Pin is required")
      .length(4, "Pin must be exactly 4 digits"),
  });

  const formik = useFormik({
    initialValues: {
      pin: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const payload = {
        id: userId,
        pin: values?.pin,
      };
      dispatch(userSetPin(payload))
        .then((response) => {
          if (response.status == true) {
            toast.success(response.message);
            navigate("/selectbank");
          } else {
            toast.error(response?.message);
          }
        })
        .catch((error) => {});
    },
  });

  return (
    <>
      <div>
        {/* <div
                    className='pin-setup-header'
                > */}
        <Header2 />
        {/* </div> */}
        <div
          className="wrapper pan-card-margin bgcolor"
          style={{ backgroundColor: "#f5f7fb" }}
          // className="pin-setup-container"
        >
          <div className="pandetails-bg">
            <Card className="pin-card" style={{ padding: "30px" }}>
              <CardContent className="card-content">
                <Typography variant="h4" className="title">
                  Set up PIN
                </Typography>

                <Typography
                  variant="body1"
                  className="subtitle"
                  textAlign="start"
                >
                  To keep your finances secure, we'll ask for this PIN every
                  time you open the app.
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                  {" "}
                  <Box className="pin-input-container">
                    {pin.map((digit, index) => (
                      <TextField
                        key={index}
                        variant="standard"
                        value={digit}
                        name="pin"
                        onChange={(e) => handlePinChange(index, e.target.value)}
                        inputRef={(el) => (inputRefs.current[index] = el)}
                        className="pin-input"
                        // inputProps={{
                        //     maxLength: 1,
                        //     style: {
                        //         width: '40px',
                        //         height: '40px',
                        //         fontSize: '1.2rem',
                        //         textAlign: 'center',
                        //         color: '#3E4154',
                        //         backgroundColor: 'transparent',
                        //         border: 'none',
                        //         borderBottom: '2px solid #3E4154',
                        //     },
                        // }}
                        InputProps={{
                          disableUnderline: true, // Disable the default underline
                          sx: {
                            "& input": {
                              width: "40px",
                              height: "40px",
                              fontSize: "1.2rem",
                              textAlign: "center",
                              color: "#3E4154", // Set input text color
                              borderBottom: "2px solid #3E4154", // Bottom line color
                              backgroundColor: "transparent",
                            },
                            "& input:focus": {
                              borderBottom: "2px solid #3E4154", // Bottom line color on focus
                              outline: "none",
                            },
                          },
                        }}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottom: "2px solid #3E4154",
                          }, // Normal state line color
                          "& .MuiInput-underline:after": {
                            borderBottom: "2px solid #3E4154",
                          }, // Active line color
                          "& .MuiInputBase-input:focus": {
                            borderBottom: "2px solid #3E4154", // Focused line color
                            outline: "none",
                            color: "#3E4154", // Focused text color
                          },
                        }}
                      />
                    ))}
                  </Box>
                  {formik.touched.pin && formik.errors.pin ? (
                    <div className="text-danger text-center">
                      {formik.errors.pin}
                    </div>
                  ) : null}
                  <Stack
                    direction="column"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="submit-button"
                      //   onClick={() => console.log("PIN:", pin.join(""))}
                      //   component={Link}
                      type="submit"
                      //   to="/selectbank"
                    >
                      Set PIN
                    </Button>
                  </Stack>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyMobileCard;
