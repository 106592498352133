import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ComingSoon from "./components/coming-soon"; // Capitalize the component name
import Home from "./pages/home"; // Import your Home.js component
import AboutUs from "./pages/aboutus";
import ContactUs from "./pages/contactus.js";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Explore from "./pages/Explore.js";
import Dashboard from "./pages/Dashboard.js";
import PlanGrowth from "./pages/Plan-growth.js";
import Allmutualfunds from "./pages/Allmutualfunds.js";
import SetupPin from "./components/web-onboarding/setup-pin";
import SelectBank from "./components/web-onboarding/SelectBank";
import LinkedToUpi from "./components/web-onboarding/linked-To_Upi.js";
import Bob from "./components/web-onboarding/Bob.js";
import Verifylocation from "./components/web-onboarding/Verifylocation.js";
import SelfieKYC from "./components/web-onboarding/SelfieKYC.js";
import Signature from "./components/web-onboarding/Signature.js";
import Signature2 from "./components/web-onboarding/Signature2.js";
import Nominee from "./components/web-onboarding/Nominee.js";
import Addnominee from "./components/web-onboarding/Addnominee.js";
import Finishaccountsetup from "./components/web-onboarding/Finishaccountsetup.js";
import Allset from "./components/web-onboarding/all-set.js";
import Pandetails from "./components/Pan-details/pan-details.js";
import OccupationForm from "./components/Pan-details/ocupation-form.js";
import UserDetails from "./components/UserDetails.js";
import ViewTransation from "./pages/View-transactions.js";
import Startswp from "./pages/Startswp.js";
import Startstp from "./pages/startstp.js";
import SwitchFunds from "./pages/Switchfunds.js";

import Orderdetails from "./pages/Profile/Orderdetails.js";
import OrderdetailsCmplt from "./pages/Profile/Oderdetails-cmplt.js";
import Oderdetailsfailed from "./pages/Profile/Oderdetails-failed.js";
import Externalfunds from "./pages/Profile/externalfunds.js";
import Allorders from "./pages/Profile/allorders.js";
import BankDetails from "./pages/Profile/Bank-details.js";
import AddBankDetails from "./pages/Profile/addBank.js";
import EditIFSC from "./pages/Profile/EditIFSC.js";
import AddBankverify from "./pages/Profile/addBankverify.js";
import Bankdetailsconform from "./pages/Profile/Bankdetailsconform.js";
import Mysips from "./pages/Mysips.js";
import SipDetails from "./pages/SipDetails.js";
import HelpSupport from "./components/help-support.js";
import Mycart from "./pages/MyCart/Mycart.js";
import Watchlist from "./pages/MyCart/Watchlist.js";
import TermsConditions from "./components/terms-conditions";
import PrivacyPolicy from "./components/privacy-policy";
import { getLocalStorageUserToken } from "./utils/userData.js";

function App() {
  const ProtectedRoute = ({ children }) => {
    const token = getLocalStorageUserToken();
    return token ? children : <Navigate to="/" replace />;
  };

  return (
    <>
      <ToastContainer />
      <Router>
        <div className="App">
          <Routes>
            {/* Define the route for Home */}
            <Route path="/" element={<Home />} />
            <Route path="/coming-soon" element={<ComingSoon />} />{" "}
            {/* Capitalized component */}
            {/* Add more routes as needed */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/userdetails" element={<UserDetails />} />
            {/* <Route path="/userdetails" element={<UserDetails />} /> */}
            <Route path="/helpsupport" element={<HelpSupport />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/explore" element={<Explore />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/mutualfund-details" element={<PlanGrowth />} />
            <Route path="/allmutualfunds" element={<Allmutualfunds />} />
            <Route path="/setuppin" element={<SetupPin />} />
            <Route path="/selectbank" element={<SelectBank />} />
            <Route path="/linkedtoupi" element={<LinkedToUpi />} />
            <Route path="/dob" element={<Bob />} />
            <Route path="/verifylocation" element={<Verifylocation />} />
            <Route path="/selfiekyc" element={<SelfieKYC />} />
            <Route path="/signature" element={<Signature />} />
            <Route path="/drawsignature" element={<Signature2 />} />
            <Route path="/nominee" element={<Nominee />} />
            <Route path="/addnominee" element={<Addnominee />} />
            <Route path="/finishsetup" element={<Finishaccountsetup />} />
            <Route path="/allset" element={<Allset />} />
            <Route path="/pan-details" element={<Pandetails />} />
            <Route path="/occupation-form" element={<OccupationForm />} />
            <Route path="/view-transactions" element={<ViewTransation />} />
            <Route path="/start-swp" element={<Startswp />} />
            <Route path="/start-stp" element={<Startstp />} />
            <Route path="/switch-fund" element={<SwitchFunds />} />
            <Route path="/mysips" element={<Mysips />} />
            <Route path="/sip-details" element={<SipDetails />} />
            <Route path="/orderdetails" element={<Orderdetails />} />
            <Route path="/orderdetailscmplt" element={<OrderdetailsCmplt />} />
            <Route path="/rderdetailsfailed" element={<Oderdetailsfailed />} />
            <Route path="/externalfunds" element={<Externalfunds />} />
            <Route path="/allorders" element={<Allorders />} />
            <Route path="/bankdetails" element={<BankDetails />} />
            <Route path="/addbank" element={<AddBankDetails />} />
            <Route path="/addbankverify" element={<AddBankverify />} />
            <Route path="/editifsc" element={<EditIFSC />} />
            <Route path="/mycart" element={<Mycart />} />
            <Route path="/watchlist" element={<Watchlist />} />
            {/* <Route path="/bankdetailsconform" element={<Bankdetailsconform />} /> */}
            {/* You can add more routes here as needed */}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
