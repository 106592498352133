// src/useDeviceDetection.js
import { useState, useEffect } from "react";

const useDeviceDetection = () => {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    const detectDevice = () => {
      const userAgent = navigator.userAgent;

      if (/mobile/i.test(userAgent)) {
        setDeviceType("Mobile");
      } else if (/tablet/i.test(userAgent)) {
        setDeviceType("Tablet");
      } else{
        setDeviceType("Desktop");
      } 
    };

    detectDevice();

    const handleResize = () => {
      detectDevice();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceType;
};

export default useDeviceDetection;
