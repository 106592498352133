import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Link } from 'react-router-dom';


const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleNext = () => {
        if (selectedDate) {
            console.log('Selected date:', selectedDate);
        }
    };

    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">
                        <div className="dob-container">
                            <IconButton className="back-button back-arrowbtn" aria-label="go back">
                                <KeyboardBackspaceIcon />
                            </IconButton>

                            <Typography className="form-title">
                                Enter your date of birth
                            </Typography>

                            <Typography className="form-subtitle" mb={2}>
                                Important for completing your KYC
                            </Typography>

                            <div className="date-picker-wrapper">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker

                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                    backgroundColor: 'rgba(245, 245, 245, 1)',

                                                    '& fieldset': {
                                                        border: 'none',
                                                    },
                                                    '& input': {
                                                        padding: '9px 10px', // Adjust padding to control height
                                                    },
                                                },

                                            }}
                                            format="DD/MM/YYYY"
                                            className='w-100 holding-cal-icon'
                                            // sx={{ width: "100%" }}
                                            placeholder="DD/MM/YYYY"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>


                        </div>
                    </CardContent>

                    <CardActions className="bob-card-actions">

                        <Stack sx={{ width: '100%' }} direction='column' spacing={3}>

                            <Typography className="form-subtitle font-14" textAlign='center'>
                                Trading will be enabled in equity segment of NSE & BSE
                            </Typography>

                            <Button
                                variant="contained"
                                className="next-button"
                                onClick={handleNext}
                                disabled={!selectedDate}
                                component={Link}
                                to="/verifylocation"
                            >
                                Next
                            </Button>

                        </Stack>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default SelectBank;
