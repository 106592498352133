import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    Divider,
    IconButton,
    TextField,
    CardContent,
    Card,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
    InputAdornment,

} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import Fund1 from '../assets/images/birla.svg';
import SWP from '../assets/images/swpsystematic.svg';
import SearchIcon from "@mui/icons-material/Search";
import Info from "../assets/images/info.svg";


function SwitchFunds() {
    const [proceedClicked, setProceedClicked] = useState(false); // Track button click

    // Function to handle the "Proceed" button click
    const handleProceedClick = () => {
        setProceedClicked(true); // Show the new content
    };

    const [selectedFund, setSelectedFund] = useState("");

    const handleFundSelection = (event) => {
        setSelectedFund(event.target.value);
    };

    const [sortOption, setSortOption] = useState("high-to-low");

    const handleChange = (event) => {
        setSortOption(event.target.value);
    };

    return (
        <>
            <Header2 />
            <Grid className="wrapper pan-card-margin border-top">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <Box className="mb-3">
                            <Box className="mb-2 mt-2 d-flex align-center">
                                <Box ml={3}>
                                    <Typography variant="h3" fontWeight="600" mb={0}>
                                        Switch Funds
                                    </Typography>
                                    {/* Blue line below the heading */}
                                    <Box className='blue-switchline' />
                                    <Typography mt={2}>View and manage your active SIPs.</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>

                    {!proceedClicked ? (
                        <Grid item xs={12} md={7} sx={{ mb: 4, mt: 3 }} >
                            <Typography mb={2}>From</Typography>
                            <Box className="watchlist-card ">
                                <Box className="card-content">
                                    {/* <CardContent> */}
                                    {/* Header Section */}
                                    <Box display="flex" alignItems="center" sx={{ mb: 2, mt: 2 }} className="card-padding">
                                        <img src={Fund1} alt="fund card img" />
                                        <Box>
                                            <Typography ml={1}>
                                                SBI PSU Direct Plan Growth
                                            </Typography>
                                            <Typography ml={1}><b> ₹500</b>  </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} className="card-padding">
                                        <Box display="flex" alignItems="center">
                                            <Typography className='font-14'>Available to switch</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography className='font-14'> 24 Units</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="end">
                                            <Typography className='font-14'><b>₹500.00</b></Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className="card-padding" mt={1}>
                                        <Box display="flex" alignItems="center">
                                            <Typography mr={1} className='font-14'>Exit load by fund house</Typography>
                                            <img src={Info} alt="info icon" className="h-100" />
                                        </Box>
                                        <Box display="flex" alignItems="end">
                                            <Typography className='font-14'><b>₹2.00</b></Typography>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className="card-padding" mb={2} >
                                        <Box display="flex" alignItems="center">
                                            <FormControlLabel
                                                control={<Checkbox className="custom-checkbox" />}
                                                label="Exclude amount/units on which exit load is charged"
                                                classes={{ label: 'font-14' }} // Add your custom className for the label
                                            />
                                        </Box>
                                    </Box>
                                    <Divider />
                                    {/* Bank Transfer Info */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className="card-padding" mt={2} mb={2}>
                                        <Box display="flex" alignItems="center">
                                            <Typography className='font-14'>Amount/units to be switched</Typography>
                                        </Box>
                                        <Box display="flex" alignItems="end">
                                            <Typography className='font-14' ><b>₹500.00</b></Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={2} textAlign='end'>
                                <Button variant="contained" className='submit-btn' onClick={handleProceedClick}> Proceed </Button>
                            </Box>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={7} sx={{ mb: 4, mt: 5 }}>
                            {/* Render new content when Proceed is clicked */}
                            <Box>
                                {/* From Section */}
                                <Box mb={4}>
                                    <Typography mb={2}>
                                        From
                                    </Typography>
                                    <Box
                                        p={2}
                                        border="1px solid #E0E0E0"
                                        borderRadius="8px"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box>
                                            <Typography >SBI PSU Direct Plan Growth</Typography>
                                            <Typography className='font-12'>Equity • Thematic</Typography>
                                        </Box>
                                        <Box display="flex" gap={4}>
                                            <Typography textAlign="center">
                                                73.27% <br />
                                                <Typography >1Y</Typography>
                                            </Typography>
                                            <Typography textAlign="center">
                                                38.54% <br />
                                                <Typography >2Y</Typography>
                                            </Typography>
                                            <Typography textAlign="center">
                                                34.38% <br />
                                                <Typography >3Y</Typography>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {/* To Section */}
                                <Box>
                                    <Typography >
                                        To
                                    </Typography>
                                    <Typography mb={2} color="text.secondary">
                                        This fund can be switched to other SBI Mutual Fund funds only
                                    </Typography>
                                    {/* Search Bar */}
                                    <TextField
                                        placeholder="Search fund"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon style={{ color: "#9E9E9E" }} />
                                                </InputAdornment>
                                            ),
                                            style: { borderRadius: "8px", height: '40px' }, // Adjust border radius
                                        }}
                                    />
                                    {/* Sort Options */}
                                    <Box className="watchlist-card " mt={2}>
                                        <Box className="card-content">
                                            <Box display="flex" justifyContent='space-between'
                                                flexDirection={{ xs: "column", sm: "row" }}
                                                alignItems={{ xs: "center", sm: "center" }}
                                                p={2}>
                                                <Box display="flex" alignItems="center" gap={1} p={2}>
                                                    <Typography color="textSecondary">Sort By:</Typography>
                                                    <Select
                                                        value={sortOption}
                                                        onChange={handleChange}
                                                        variant="standard"
                                                        disableUnderline
                                                        sx={{
                                                            color: "rgba(11, 36, 194, 1)", // Highlighted blue color for the text
                                                            "& .MuiSelect-icon": { color: "#1C5DB8" }, // Arrow color
                                                        }}
                                                    >
                                                        <MenuItem value="high-to-low">Returns: High to Low</MenuItem>
                                                        <MenuItem value="low-to-high">Returns: Low to High</MenuItem>
                                                        <MenuItem value="alphabetical">Alphabetical</MenuItem>
                                                    </Select>
                                                </Box>
                                                <Box display="flex"
                                                    justifyContent="space-around"
                                                    alignItems="center"
                                                    width={{ xs: "100%", sm: "30%" }} // Full width on small screens, 30% on medium+
                                                    flexWrap="wrap" // Ensures returns wrap on smaller screens
                                                    gap={3}>
                                                    <Typography >1Y</Typography>
                                                    <Typography >2Y</Typography>
                                                    <Typography >3Y</Typography>
                                                </Box>
                                            </Box>
                                            <Divider />
                                            <RadioGroup value={selectedFund} onChange={handleFundSelection}>
                                                {[
                                                    {
                                                        id: 1,
                                                        name: "SBI Long Term Equity Fund Direct Plan Growth",
                                                        category: "Equity • ELSS • 5★",
                                                        returns: ["40%", "25%", "25%"],
                                                    },
                                                    {
                                                        id: 2,
                                                        name: "SBI Savings Fund Direct Growth",
                                                        category: "Debt • Money Market • 3★",
                                                        returns: ["8%", "7%", "7%"],
                                                    },
                                                    {
                                                        id: 3,
                                                        name: "SBI Liquid Fund Direct Plan Growth",
                                                        category: "Debt • Liquid • 3★",
                                                        returns: ["7%", "6%", "6%"],
                                                    },
                                                ].map((fund, index) => (
                                                    <React.Fragment key={fund.id}>
                                                        <Box
                                                            p={2}
                                                            borderRadius="8px"
                                                            mb={1}
                                                            display="flex"
                                                            flexDirection={{ xs: "column", sm: "row" }} // Responsive layout: stack vertically on small screens
                                                            justifyContent="space-between"
                                                            alignItems={{ xs: "flex-start", sm: "center" }} // Aligns items on different breakpoints
                                                            gap={2} // Adds spacing
                                                        >
                                                            {/* Left Side: Radio + Fund Details */}
                                                            <Box>
                                                                <FormControlLabel
                                                                    value={fund.name}
                                                                    control={<Radio className="custom-radio" />}
                                                                    label={
                                                                        <Box>
                                                                            <Typography variant="body1">{fund.name}</Typography>
                                                                            <Typography variant="body2" color="textSecondary">
                                                                                {fund.category}
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </Box>

                                                            {/* Right Side: Returns Section */}
                                                            <Box
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                width={{ xs: "100%", sm: "30%" }} // Full width on small screens, 30% on medium+
                                                                flexWrap="wrap" // Ensures returns wrap on smaller screens
                                                                gap={2}
                                                            >
                                                                {fund.returns.map((returnRate, idx) => (
                                                                    <Box key={idx} textAlign="center" flex="1 1 auto">
                                                                        <Typography variant="body1">{returnRate}</Typography>
                                                                    </Box>
                                                                ))}
                                                            </Box>
                                                        </Box>

                                                        {/* Proceed Button */}
                                                        {selectedFund === fund.name && (
                                                            <Box className="text-end" mb={2} mr={2}>
                                                                <Button variant="contained" className="submit-btn">
                                                                    Proceed
                                                                </Button>
                                                            </Box>
                                                        )}

                                                        {/* Divider */}
                                                        {index !== 2 && <Divider />}
                                                    </React.Fragment>
                                                ))}
                                            </RadioGroup>

                                        </Box>
                                    </Box>
                                    {/* Fund List */}
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Grid >
        </>
    );
}

export default SwitchFunds;
