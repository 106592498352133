import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../assets/css/common.css";
import AnnualIncomeForm from "./annual-income";
import Header2 from "../../pages/home/Appbar2";

const OccupationForm = () => {
    const [selectedOccupation, setSelectedOccupation] = useState("");
    const [checked, setChecked] = useState(false);
    const [step, setStep] = useState(1);
    const [motherName, setMotherName] = useState("");
    const [fatherName, setFatherName] = useState("");

    const occupations = [
        "Private Sector Service",
        "Government Service",
        "Housewife",
        "Student",
        "Self employed",
        "Business",
        "Professional",
        "Retired",
        "Farmer",
        "Service",
        "Agriculturist",
    ];

    // Handle occupation selection
    const handleOccupationSelect = (occupation) => {
        setSelectedOccupation(occupation);
        if (checked) setStep(2);
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        setChecked(e.target.checked);
        if (selectedOccupation && e.target.checked) setStep(2);
    };

    // Navigate to the previous step
    const handleBack = () => setStep((prev) => prev - 1);

    // Check if the Next button should be enabled
    const isNextButtonEnabled = motherName.trim() !== "" && fatherName.trim() !== "";

    return (
        <>
            <Header2 />
            <div className="wrapper pan-card-margin bgcolor" >
                <Box className="pandetails-bg">
                    <Card
                        className="pan-box"
                        sx={{
                            // width: 500,
                            padding: 4,
                            boxShadow: 3,
                            borderRadius: 2,
                        }}
                    >
                        {step === 1 && (
                            <>
                                <IconButton
                                    aria-label="back"
                                    className="back-arrowbtn"
                                >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    What’s your occupation?
                                </Typography>
                                <Grid container spacing={2}>
                                    {occupations.map((occupation, index) => (
                                        <Grid item xs={6} key={index}>
                                            <Button
                                                className="occupation-btns"
                                                variant={
                                                    selectedOccupation === occupation ? "contained" : "outlined"
                                                }
                                                fullWidth
                                                onClick={() => handleOccupationSelect(occupation)}
                                                sx={{
                                                    textTransform: "none",
                                                    color: selectedOccupation === occupation ? "#fff" : "#000",
                                                    padding: { xs: "6px", md: "20px" }, // Responsive padding
                                                }}
                                            >
                                                {occupation}
                                            </Button>
                                        </Grid>
                                    ))}
                                </Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            className="custom-checkbox"
                                        />
                                    }
                                    label={
                                        <Typography className="custom-checkbox-label">
                                            I do not have any proceedings initiated/pending by SEBI/stock exchange or any other authority against me in the last 3 years.
                                        </Typography>
                                    }


                                    sx={{ mt: 2 }}
                                />
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <IconButton
                                    aria-label="back"
                                    className="back-arrowbtn"
                                    onClick={handleBack}
                                >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    Enter your parent's name
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 3, color: "text.secondary" }}>
                                    These are required to confirm identification.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }} className="label-mar">
                                    Mother’s name
                                </Typography>
                                <TextField
                                    placeholder="Enter your mother’s full name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={motherName}
                                    onChange={(e) => setMotherName(e.target.value)}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "rgba(245, 245, 245, 1)",
                                            "& fieldset": { border: "none" },
                                            "& input": { padding: "8px 12px" },
                                        },
                                    }}
                                />
                                <Typography variant="body1" className="label-mar" sx={{ mt: 2 }}>
                                    Father’s name
                                </Typography>
                                <TextField
                                    placeholder="Enter your father’s full name"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={fatherName}
                                    onChange={(e) => setFatherName(e.target.value)}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                            backgroundColor: "rgba(245, 245, 245, 1)",
                                            "& fieldset": { border: "none" },
                                            "& input": { padding: "8px 12px" },
                                        },
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="start-sipbtn next-btn-margin"
                                    fullWidth
                                    sx={{ mt: 6 }}
                                    onClick={() => setStep(3)} // Navigate to Annual Income form
                                    disabled={!isNextButtonEnabled} // Disable if inputs are empty
                                >
                                    Next
                                </Button>
                            </>
                        )}

                        {step === 3 && <AnnualIncomeForm onBack={() => setStep(2)} />}
                    </Card>
                </Box>
            </div>
        </>
    );
};

export default OccupationForm;
