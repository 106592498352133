import { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  CardActions,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ArrowBack } from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
const SelectBank = () => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [nomineeCount, setNomineeCount] = useState(1);
  const [nomineeName, setNomineeName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [identityProof, setIdentityProof] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [addressSameAsMine, setAddressSameAsMine] = useState(false);
  const { userNomineeDet, userOnboardGetData } = useSelector(
    (state) => state.users
  );
  const [nominees, setNominees] = useState([
    { name: "", relation: "", dob: "", percent: "", pan: "" },
  ]);
  const addNominee = () => {
    if (nomineeCount != 3) {
      //   setNominees([
      //     ...nominees,
      //     { name: "", relation: "", dob: "", percent: "", pan: "" },
      //   ]);
      setNomineeCount(nomineeCount + 1);
      formik.setValues({
        ...values,
        [`nominee${nomineeCount + 1}_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_name`
          ] || "",
        [`nominee${nomineeCount + 1}_relation`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_relation`
          ] || "",
        [`nominee${nomineeCount + 1}_dob`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_dob`
          ] != null
            ? userOnboardGetData?.data?.user_profile?.[
                `nominee${nomineeCount + 1}_dob`
              ]
            : "" || "",
        [`nominee${nomineeCount + 1}_percent`]:
          parseFloat(
            userOnboardGetData?.data?.user_profile?.[
              `nominee${nomineeCount + 1}_percent`
            ]
          ).toFixed(2) || "",
        [`nominee${nomineeCount + 1}_guard_name`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_name`
          ] || "",
        [`nominee${nomineeCount + 1}_guard_pan`]:
          userOnboardGetData?.data?.user_profile?.[
            `nominee${nomineeCount + 1}_guard_pan`
          ] || "",
      });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const formik = useFormik({
    initialValues: {
      nominee1_name: userNomineeDet?.data?.nominees?.nominee1_name || "",
      nominee1_relation:
        userNomineeDet?.data?.nominees?.nominee1_relation || "",
      nominee1_dob:
        userNomineeDet?.data?.nominees?.nominee1_dob != null
          ? userNomineeDet?.data?.nominees?.nominee1_dob
          : "" || "",
      nominee1_percent:
        userNomineeDet?.data?.nominees?.nominee1_percent == null
          ? 0
          : parseFloat(
              userNomineeDet?.data?.nominees?.nominee1_percent
            ).toFixed(2) || 0,
      nom1_pan: userNomineeDet?.data?.nominees?.nom1_pan || "",
      nominee1_guard_name:
        userNomineeDet?.data?.nominees?.nominee1_guard_name || "",
      nominee1_guard_pan:
        userNomineeDet?.data?.nominees?.nominee1_guard_pan || "",
      nominee2_name: userNomineeDet?.data?.nominees?.nominee2_name || "",
      nominee2_relation:
        userNomineeDet?.data?.nominees?.nominee2_relation || "",
      ...(userNomineeDet?.data?.nominees?.nom2_pan
        ? { nom2_pan: userNomineeDet?.data?.nominees?.nom2_pan }
        : {}),
      nominee2_dob:
        userNomineeDet?.data?.nominees?.nominee2_dob != null
          ? userNomineeDet?.data?.nominees?.nominee2_dob
          : "" || "",
      nominee2_percent: userNomineeDet?.data?.nominees?.nominee2_percent
        ? parseFloat(userNomineeDet?.data?.nominees?.nominee2_percent).toFixed(
            2
          )
        : 0 || 0,
      nominee2_guard_name:
        userNomineeDet?.data?.nominees?.nominee2_guard_name || "",
      nominee2_guard_pan:
        userNomineeDet?.data?.nominees?.nominee2_guard_pan || "",
      nominee3_name: userNomineeDet?.data?.nominees?.nominee3_name || "",
      nominee3_relation:
        userNomineeDet?.data?.nominees?.nominee3_relation || "",
      nominee3_dob:
        userNomineeDet?.data?.nominees?.nominee3_dob != null
          ? userNomineeDet?.data?.nominees?.nominee3_dob
          : "" || "",
      ...(userNomineeDet?.data?.nominees?.nom3_pan
        ? { nom3_pan: userNomineeDet?.data?.nominees?.nom3_pan }
        : {}),
      nominee3_percent: userNomineeDet?.data?.nominees?.nominee3_percent
        ? parseFloat(userNomineeDet?.data?.nominees?.nominee3_percent).toFixed(
            2
          )
        : 0 || 0,
      nominee3_guard_name:
        userNomineeDet?.data?.nominees?.nominee3_guard_name || "",
      nominee3_guard_pan:
        userNomineeDet?.data?.nominees?.nominee3_guard_pan || "",
      nom1_guardian_relation:
        userNomineeDet?.data?.nominees?.nom1_guardian_relation || "",
      nom2_guardian_relation:
        userNomineeDet?.data?.nominees?.nom2_guardian_relation || "",
      nom3_guardian_relation:
        userNomineeDet?.data?.nominees?.nom3_guardian_relation || "",
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};
      if (isFormSubmitted) {
        for (let i = 1; i <= nomineeCount; i++) {
          const nameKey = `nominee${i}_name`;
          const relationKey = `nominee${i}_relation`;
          const dobKey = `nominee${i}_dob`;
          const percentKey = `nominee${i}_percent`;
          const guardianNameKey = `nominee${i}_guard_name`;
          const guardianRelationKey = `nom${i}_guardian_relation`;
          const guardianPanKey = `nominee${i}_guard_pan`;
          const panKey = `nom${i}_pan`;
          const address = `nominee${i}_address`;

          const currentDate = new Date();
          const dob = new Date(values[dobKey]);
          const age = currentDate.getFullYear() - dob.getFullYear();
          const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
          if (!values[nameKey]) {
            errors[nameKey] = "Name is required";
          } else if (!/^[A-Za-z\s]+$/.test(values[nameKey])) {
            errors[nameKey] = "Name must contain only alphabets";
          }
          if (age >= 18) {
            if (!values[panKey]) {
              errors[panKey] = "PAN Number is required";
            } else if (!panRegex.test(values[panKey])) {
              errors[panKey] = "Invalid PAN Details";
            }
          }
          if (!values[relationKey] || values[relationKey] === "Select")
            errors[relationKey] = "Relation is required";
          if (!values[dobKey]) errors[dobKey] = "Date of birth is required";
          if (
            !values[percentKey] ||
            values[percentKey] <= 0 ||
            values[percentKey] > 100
          ) {
            errors[percentKey] = "Percentage must be between 1 and 100";
          }

          if (!values[address]) {
            errors[address] = "Address is required";
          }
          if (age < 18) {
            if (!values[guardianNameKey]) {
              errors[guardianNameKey] = "Guardian Name is required";
            } else if (!/^[A-Za-z\s]+$/.test(values[guardianNameKey])) {
              errors[guardianNameKey] =
                "Guardian Name must contain only alphabets";
            }

            if (
              !values[guardianRelationKey] ||
              values[guardianRelationKey] === "Select"
            )
              errors[guardianRelationKey] = "Guardian Relation is required";

            if (!values[guardianPanKey]) {
              errors[guardianPanKey] = "Guardian PAN is required";
            } else if (!panRegex.test(values[guardianPanKey])) {
              errors[guardianPanKey] = "Invalid Guardian PAN format";
            }
          }
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      const payload = {
        ...values,
        // nominee1_dob: values?.nominee1_dob
        //   ? convertDateToDDMMMYYYY(values?.nominee1_dob)
        //   : "",
        // nominee2_dob: values?.nominee2_dob
        //   ? convertDateToDDMMMYYYY(values?.nominee2_dob)
        //   : "",
        // nominee3_dob: values?.nominee3_dob
        //   ? convertDateToDDMMMYYYY(values?.nominee3_dob)
        //   : "",
      };

      const nom1 = values?.nominee1_percent
        ? parseFloat(values?.nominee1_percent)
        : 0;
      const nom2 = values?.nominee2_percent
        ? parseFloat(values?.nominee2_percent)
        : 0;
      const nom3 = values?.nominee3_percent
        ? parseFloat(values?.nominee3_percent)
        : 0;
      if (nom1 + nom2 + nom3 == 100) {
        // dispatch(userNomineeDetailAdd(payload));
      } else {
        toast.error("Total percentage cannot exceed 100%");
      }
      //   const formatDate = (dateString) => {
      //     const date = new Date(dateString);

      //     const options = {
      //       day: "2-digit",
      //       month: "short", // 'short' gives abbreviated month names like 'Jan', 'Feb', etc.
      //       year: "numeric",
      //     };

      //     return date.toLocaleDateString("en-GB", options).replace(/ /g, "-");
      //   };

      //   const payload = {
      //     ...values,
      //     nominee1_dob: values?.nominee1_dob
      //       ? formatDate(values?.nominee1_dob)
      //       : "",
      //     nominee2_dob: values?.nominee2_dob
      //       ? formatDate(values?.nominee2_dob)
      //       : "",
      //     nominee3_dob: values?.nominee3_dob
      //       ? formatDate(values?.nominee3_dob)
      //       : "",
      //     id: userData?.id,
      //     completed_wizard_step: 3,
      //   };
    },
  });
  const deleteNominee = (position) => {
    const updatedValues = { ...formik.values };

    // Remove the specific nominee
    for (let i = position; i < nomineeCount; i++) {
      updatedValues[`nominee${i}_name`] =
        updatedValues[`nominee${i + 1}_name`] || "";
      updatedValues[`nominee${i}_relation`] =
        updatedValues[`nominee${i + 1}_relation`] || "";
      updatedValues[`nominee${i}_dob`] =
        updatedValues[`nominee${i + 1}_dob`] || "";
      updatedValues[`nominee${i}_percent`] =
        updatedValues[`nominee${i + 1}_percent`] || "";
      updatedValues[`nominee${i}_guard_name`] =
        updatedValues[`nominee${i + 1}_guard_name`] || "";
      updatedValues[`nominee${i}_guard_pan`] =
        updatedValues[`nominee${i + 1}_guard_pan`] || "";
      updatedValues[`nominee${i}_address`] =
        updatedValues[`nominee${i + 1}_address`] || "";
    }

    // Remove the last nominee (shifted forward)
    delete updatedValues[`nominee${nomineeCount}_name`];
    delete updatedValues[`nominee${nomineeCount}_relation`];
    delete updatedValues[`nominee${nomineeCount}_dob`];
    delete updatedValues[`nominee${nomineeCount}_percent`];
    delete updatedValues[`nominee${nomineeCount}_guard_name`];
    delete updatedValues[`nominee${nomineeCount}_guard_pan`];
    delete updatedValues[`nominee${nomineeCount}_address`];

    setNomineeCount(nomineeCount - 1);
    formik.setValues(updatedValues);
  };

  const { values, errors, handleChange, setFieldValue, handleSubmit } = formik;
  const relationships = ["Parent", "Sibling", "Spouse", "Child", "Friend"];
  const identityProofOptions = [
    "Aadhar Card",
    "PAN Card",
    "Passport",
    "Voter ID",
  ];
  const getOrdinalSuffix = (index) => {
    const j = index % 10,
      k = index % 100;

    if (j === 1 && k !== 11) return `${index}st`;
    if (j === 2 && k !== 12) return `${index}nd`;
    if (j === 3 && k !== 13) return `${index}rd`;
    return `${index}th`;
  };
  const handleBack =()=>{
    navigate("/userdetails")
  }
  return (
    <>
      <div className="pin-setup-header">
        <Header2 />
      </div>
      <div className="bank-selection-container">
        <Card className="bank-selection-card bob-selection-card">
          <form onSubmit={formik.handleSubmit} id="manage_nominee">
            <CardContent className="bob-card-content">
              <div className="form-header">
                <KeyboardBackspaceIcon className="back-button-kyc" onClick={handleBack} style={{cursor:'pointer'}}/>

                <Typography className="form-title mb-nominee">
                  Add nominee
                </Typography>
              </div>
              {[...Array(nomineeCount)].map((_, index) => {
                const i = index + 1;
                const nameKey = `nominee${i}_name`;
                const relationKey = `nominee${i}_relation`;
                const dobKey = `nominee${i}_dob`;
                const percentKey = `nominee${i}_percent`;
                const panKey = `nom${i}_pan`;
                const guardianNameKey = `nominee${i}_guard_name`;
                const guardianPanKey = `nominee${i}_guard_pan`;
                const address = `nominee${i}_address`;
                const guardianRelationKey = `nom${i}_guardian_relation`;

                const dob = new Date(values[dobKey]);
                const age = isNaN(dob.getTime())
                  ? null
                  : new Date().getFullYear() - dob.getFullYear();

                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="nominee_title">
                        {`${getOrdinalSuffix(i)} Nominee:`}
                      </h5>
                      <button
                        type="button"
                        className="delete_button text-decoration-none"
                        onClick={deleteNominee}
                      >
                        <span>-</span> Delete nominee
                      </button>
                    </div>

                    <Box className="mb-2">
                      <Typography className="form-subtitle nominee-mb-1">
                        Nominee name
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter nominee name"
                        value={values[nameKey]}
                        onChange={handleChange}
                        name={nameKey}
                        className="nominee-inputs-bg"
                        sx={{ borderRadius: "5px" }}
                      />
                      {errors[nameKey] && (
                        <div className="text-danger">{errors[nameKey]}</div>
                      )}
                    </Box>
                    <Box className="mb-2">
                      <Typography className="form-subtitle nominee-mb-1">
                        Nominee is your
                      </Typography>
                      <Autocomplete
                        disablePortal
                        options={relationships}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={relationKey}
                            placeholder="Select"
                            className="nominee-inputs-bg"
                            sx={{ borderRadius: "5px" }}
                            value={values[relationKey]}
                            changeFunction={(value) =>
                              setFieldValue(relationKey, value)
                            }
                          />
                        )}
                      />
                      {errors[relationKey] && (
                        <div className="text-danger">{errors[relationKey]}</div>
                      )}
                    </Box>
                    <Box className="mb-2">
                      <Typography className="form-subtitle nominee-mb-1">
                        DOB
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="DD/MM/YYYY"
                          sx={{ width: "100%", borderRadius: "5px" }}
                          value={values[dobKey] ? dayjs(values[dobKey]) : null} // Ensure value is a dayjs object
                          //   maxDate={new Date().toISOString().split("T")[0]}
                          onChange={(date) => setFieldValue(dobKey, date)}
                          className="nominee-inputs-bg holding-cal-icon"
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="mb-2">
                      <Typography className="form-subtitle nominee-mb-1">
                        Nominee %*
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Nominee %"
                        value={values[percentKey]}
                        onChange={handleChange}
                        name={percentKey}
                        className="nominee-inputs-bg"
                        sx={{ borderRadius: "5px" }}
                      />
                      {errors[percentKey] && (
                        <div className="text-danger">{errors[percentKey]}</div>
                      )}
                    </Box>
                    {age >= 18 || age === null ? (
                      <>
                        {" "}
                        <Box className="mb-2">
                          <Typography className="form-subtitle nominee-mb-1">
                            Enter Pan Number*
                          </Typography>
                          <TextField
                            fullWidth
                            placeholder="Enter Pan Number"
                            value={values[panKey]}
                            onChange={handleChange}
                            name={panKey}
                            className="nominee-inputs-bg"
                            sx={{ borderRadius: "5px" }}
                          />
                          {errors[panKey] && (
                            <div className="text-danger">{errors[panKey]}</div>
                          )}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className="mb-2">
                          <Typography className="form-subtitle nominee-mb-1">
                            Enter Guardian Name*
                          </Typography>
                          <TextField
                            fullWidth
                            placeholder="Enter Guardian Name"
                            value={values[guardianNameKey]}
                            onChange={handleChange}
                            name={guardianNameKey}
                            className="nominee-inputs-bg"
                            sx={{ borderRadius: "5px" }}
                          />
                          {errors[guardianNameKey] && (
                            <div className="text-danger">
                              {errors[guardianNameKey]}
                            </div>
                          )}
                        </Box>
                        <Box className="mb-2">
                          <Typography className="form-subtitle nominee-mb-1">
                            Enter Guardian Pan*
                          </Typography>
                          <TextField
                            fullWidth
                            placeholder="Enter Guardian Pan"
                            value={values[guardianPanKey]}
                            onChange={handleChange}
                            name={guardianPanKey}
                            className="nominee-inputs-bg"
                            sx={{ borderRadius: "5px" }}
                          />
                          {errors[guardianPanKey] && (
                            <div className="text-danger">
                              {errors[guardianPanKey]}
                            </div>
                          )}
                        </Box>
                      </>
                    )}

                    <Box className="mb-2">
                      <Typography className="form-subtitle nominee-mb-1">
                        Address*
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Enter Address"
                        value={values[address]}
                        onChange={handleChange}
                        name={address}
                        className="nominee-inputs-bg"
                        sx={{ borderRadius: "5px" }}
                      />
                      {errors[address] && (
                        <div className="text-danger">{errors[address]}</div>
                      )}
                    </Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked
                            className="signature-checkbox"
                            checked={addressSameAsMine}
                            onChange={handleChange}
                          />
                        }
                        label="Address same as my own"
                      />
                    </FormGroup>
                  </>
                );
              })}
              <Box className="add-another-nominee">
                {nomineeCount < 3 && (
                  <Typography
                    className="add-another-nominee-text text-center"
                    mt={2}
                    mb={2}
                  >
                    <Button
                      className="add-another-nominee "
                      variant="text"
                      startIcon={<AddCircleIcon />}
                      onClick={addNominee}
                    >
                      {" "}
                      Add another nominee
                    </Button>
                    <span className="nominee-option">(Optional)</span>
                  </Typography>
                )}
              </Box>
              <Typography className="form-footer-text">
                Double-check details entered above before confirming. Existing
                nominee details cannot be changed later.
              </Typography>
            </CardContent>
            <CardActions
            // className="kyc-card-actions"
            >
              <Stack
                sx={{ width: "100%", mt: 1, mb: 2 }}
                direction="column"
                spacing={3}
              >
                <Button
                  variant="contained"
                  className="open-camera"
                  type="submit"
                  onClick={() => setIsFormSubmitted(true)}
                >
                  Finish Nominee Addition
                </Button>
              </Stack>
            </CardActions>
          </form>
        </Card>
      </div>
    </>
  );
};

export default SelectBank;
