import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { IconButton, Menu } from "@mui/material";
import '../../assets/css/header.css';
import MisshikaLogo from '../../assets/images/Logo.svg';
// import LoginPopup from './LoginPopup';

export default function AppBarMobile() {
    const [expandedRes, setExpandedRes] = useState(false);
    const [expandedMarket, setExpandedMarket] = useState(false);
    const [expandedSalary, setExpandedSalary] = useState(false);
    const [expandedReport, setExpandedReport] = useState(false);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        // Function to handle resize
        const handleResize = () => {
            if (window.innerWidth > 990 && state.left) {
                // If the window width is greater than 990px and the drawer is open, close it
                setState({ ...state, left: false });
            }
        };
        // Add resize event listener
        window.addEventListener("resize", handleResize);
        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [state.left]);

    const ResourcesMenu = (panel) => (event, isExpandedRes) => {
        setExpandedRes(isExpandedRes ? panel : false);
    };

    const MarketMenu = (panel) => (event, isExpandedMarket) => {
        setExpandedMarket(isExpandedMarket ? panel : false);
    };
    const salaryMenu = (panel) => (event, isExpandedSalary) => {
        setExpandedSalary(isExpandedSalary ? panel : false);
    };
    const ReportMenu = (panel) => (event, isExpandedReport) => {
        setExpandedReport(isExpandedReport ? panel : false);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const resourceClose = () => {
        setExpandedRes(false);
    };

    // New popup Logic

    const [open, setOpen] = useState(false);
    const [moreAnchorEl, setMoreAnchorEl] = useState(null); // State for "More" menu

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMoreMenuOpen = (event) => {
        setMoreAnchorEl(event.currentTarget);
    };

    const handleMoreMenuClose = () => {
        setMoreAnchorEl(null);
    };

    // New popup Logic

    const [open1, setOpen1] = useState(false);

    const handleClickOpen1 = () => {
        setOpen(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
            role="presentation"
            className="web-menu-drawwer"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <nav className="w-100">

                <Link className="header-menu-link">

                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/">

                                    <div className="logo-mishika-menu">
                                        <Link to="/">
                                            <img src={MisshikaLogo} alt="Misshika Capital Logo" />
                                        </Link>
                                    </div>

                                </Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>

                </Link>

                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/about-us">About Us</Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>

                <Link className="header-menu-link">
                    <Accordion
                        className="sub-menuAccordion"
                        elevation={0}
                        disableGutters // This will remove the default gutter spacing
                    >
                        <AccordionSummary
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="link-content"
                        >
                            <Button id="resource-menu">
                                <Link className="header-select-link" to="/contact">Contact us</Link>
                            </Button>
                        </AccordionSummary>
                    </Accordion>
                </Link>



                {/* More URLs Dropdown */}

                <Accordion
                    className="sub-menuAccordion"
                    elevation={0}
                    disableGutters
                >
                    <AccordionSummary
                        aria-controls="more-urls-content"
                        id="more-urls-header"
                        className="link-content"
                    >
                        <Button
                            id="more-urls-menu"
                            variant="text"
                            className="nav-link"
                            onClick={handleMoreMenuOpen}
                        >
                            More URLs
                        </Button>
                    </AccordionSummary>
                </Accordion>

                <Menu
                    anchorEl={moreAnchorEl}
                    open={Boolean(moreAnchorEl)}
                    onClose={handleMoreMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem component={Link} to="/pan-details" onClick={handleMoreMenuClose}>
                        PAN Details
                    </MenuItem>
                    <MenuItem component={Link} to="/occupation-form" onClick={handleMoreMenuClose}>
                        Occupation
                    </MenuItem>
                    <MenuItem component={Link} to="/setuppin" onClick={handleMoreMenuClose}>
                        Set up PIN
                    </MenuItem>
                    <MenuItem component={Link} to="/selectbank" onClick={handleMoreMenuClose}>
                        Select Bank
                    </MenuItem>
                    <MenuItem component={Link} to="/dob" onClick={handleMoreMenuClose}>
                        DOB
                    </MenuItem>
                    <MenuItem component={Link} to="/verifylocation" onClick={handleMoreMenuClose}>
                        Verify location
                    </MenuItem>
                    <MenuItem component={Link} to="/selfiekyc" onClick={handleMoreMenuClose}>
                        Selfie KYC
                    </MenuItem>
                    <MenuItem component={Link} to="/signature" onClick={handleMoreMenuClose}>
                        Signature
                    </MenuItem>
                    <MenuItem component={Link} to="/nominee" onClick={handleMoreMenuClose}>
                        Nominee
                    </MenuItem>
                    <MenuItem component={Link} to="/finishsetup" onClick={handleMoreMenuClose}>
                        Finish account setup
                    </MenuItem>

                    <MenuItem component={Link} to="/mutualfund-details" onClick={handleMoreMenuClose}>
                        Mutual Fund Details
                    </MenuItem>

                    <MenuItem component={Link} to="/allmutualfunds" onClick={handleMoreMenuClose}>
                        All Mutual Funds
                    </MenuItem>

                    <MenuItem component={Link} to="/orderdetails" onClick={handleMoreMenuClose}>
                        Order Details
                    </MenuItem>

                    <MenuItem component={Link} to="/externalfunds" onClick={handleMoreMenuClose}>
                        Import external funds
                    </MenuItem>

                    <MenuItem component={Link} to="/allorders" onClick={handleMoreMenuClose}>
                        All Orders
                    </MenuItem>

                    <MenuItem component={Link} to="/orderdetailscmplt" onClick={handleMoreMenuClose}>
                        New SIP Completed
                    </MenuItem>

                    <MenuItem component={Link} to="/rderdetailsfailed" onClick={handleMoreMenuClose}>
                        New SIP - Failed
                    </MenuItem>
                    <MenuItem component={Link} to="/view-transactions" onClick={handleMoreMenuClose}>
                        View Transactions
                    </MenuItem>
                    <MenuItem component={Link} to="/start-swp" onClick={handleMoreMenuClose}>
                        Start SWP
                    </MenuItem>
                    <MenuItem component={Link} to="/start-stp" onClick={handleMoreMenuClose}>
                        Start STP
                    </MenuItem>
                    <MenuItem component={Link} to="/switch-fund" onClick={handleMoreMenuClose}>
                        Switch Funds
                    </MenuItem>
                    <MenuItem component={Link} to="/mysips" onClick={handleMoreMenuClose}>
                        My SIPs
                    </MenuItem>
                    <MenuItem component={Link} to="/sip-details" onClick={handleMoreMenuClose}>
                        SIP Details
                    </MenuItem>
                    <MenuItem component={Link} to="/bankdetails" onClick={handleMoreMenuClose}>
                        Bank Details
                    </MenuItem>

                    <MenuItem component={Link} to="/addbank" onClick={handleMoreMenuClose}>
                        Add bank
                    </MenuItem>

                    <MenuItem component={Link} to="/addbankverify" onClick={handleMoreMenuClose}>
                        Add bank verify
                    </MenuItem>

                    <MenuItem component={Link} to="/editifsc" onClick={handleMoreMenuClose}>
                        Edit IFSC code
                    </MenuItem>

                    <MenuItem component={Link} to="/userdetails" onClick={handleMoreMenuClose}>
                        Sidebar Menu
                    </MenuItem>
                    <MenuItem component={Link} to="/helpsupport" onClick={handleMoreMenuClose}>
                        Help & Support
                    </MenuItem>
                    <MenuItem component={Link} to="/mycart" onClick={handleMoreMenuClose}>
                        My Cart
                    </MenuItem>
                    <MenuItem component={Link} to="/watchlist" onClick={handleMoreMenuClose}>
                        Watchlist
                    </MenuItem>
                </Menu>

                {/* <Button variant="contained" className="login-button" onClick={handleClickOpen1}>
                    Login / Register
                </Button>
                <LoginPopup open={open1} onClose={handleClose1} /> */}

            </nav>
        </Box >
    );

    return (
        <div>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box ml={1}>
                        <IconButton
                            onClick={toggleDrawer(anchor, true)}
                        >
                            <MenuIcon fontSize="medium" className="menu-icons-color" />
                            {/* <WidgetsIcon fontSize="medium" className="menu-icons-color" /> */}
                        </IconButton>
                    </Box>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}
