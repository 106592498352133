import React, { useState } from "react";
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    Stack,
    Button,
    Autocomplete,
    InputAdornment
} from '@mui/material';
import MoreVert from "@mui/icons-material/MoreVert";
import InfoIcon from '@mui/icons-material/Info';

import { TextField, Avatar } from "@mui/material";



import { ChevronRight } from '@mui/icons-material';
import Header2 from "../home/Appbar2";

import "../../assets/css/funddetails.css"
import "../../assets/css/onbording.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';

import hdfcIcon from "../../assets/images/hdfc.svg"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Edit, ContentCopy } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SbiIcon from "../../assets/images/sbi-icon.svg"
import HdfcIcon from "../../assets/images/hdfc.svg"
import IcicIcon from "../../assets/images/icic-bank.svg"
import KokatIcon from "../../assets/images/kokat.svg"
import AxisBankIcon from "../../assets/images/axis-bank.svg"
import PunjabBankIcon from "../../assets/images/punjab-bank.svg"
import AbhyudayaBankIcon from "../../assets/images/Abhyudaya-bank.svg"

import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";

export default function TransactionList() {
    const navigate = useNavigate();

    const closeButton = ()=>{
        navigate("/bankdetails");
    }

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpen = (event, index) => {
        setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleInvestClick = () => {
        console.log("Invest action triggered");
        handleMenuClose();
    };

    const handleRedeemClick = () => {
        console.log("Redeem action triggered");
        handleMenuClose();
    };

    const handleViewTransactionsClick = () => {
        console.log("View Transactions action triggered");
        handleMenuClose();
    };

    const handleStartSwpClick = () => {
        console.log("Start SWP action triggered");
        handleMenuClose();
    };

    const handleStartStpClick = () => {
        console.log("Start STP action triggered");
        handleMenuClose();
    };

    const banks = [
        { id: 1, name: "HDFC BANK", number: "XXXX1234", logo: hdfcIcon },
        // Add more bank objects if needed
    ];

    const banks2 = [
        { id: 1, name: "Add Another Bank", },
        // Add more bank objects if needed
    ];

    // -------------//

    const [anchorElAutoPayMenu, setAnchorElAutoPayMenu] = useState(null);

    const handleMenuOpen1 = (event) => {
        setAnchorElAutoPayMenu(event.currentTarget);
    };

    const handleMenuClose1 = () => {
        setAnchorElAutoPayMenu(null);
    };

    const handleAddAutopay = () => {
        console.log("Add Autopay action triggered");
        handleMenuClose();
    };

    const handleEditAutopay = () => {
        console.log("Edit Autopay action triggered");
        handleMenuClose();
    };

    const handleDeleteAutopay = () => {
        console.log("Delete Autopay action triggered");
        handleMenuClose();
    };

    const handlePauseAutopay = () => {
        console.log("Pause Autopay action triggered");
        handleMenuClose();
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const banksinput = [
        { name: "AXIS BANK", logo: AxisBankIcon },
        { name: "ICICI BANK LIMITED", logo: IcicIcon },
        { name: "STATE BANK OF INDIA", logo: SbiIcon },
        { name: "KOTAK MAHINDRA BANK LIMITED", logo: KokatIcon },
    ];



    return (

        <>
            <Header2 />
            <div className="wrapper">
                <Box className="bank-mt-17">
                </Box>
                <Grid container spacing={2} className="transaction-container">
                    <Grid item xs={12} md={12}>
                        <Typography className='bank-text-head'> Bank Account Details </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="transaction-container">
                    <Grid item xs={12} md={6} >
                        <div className="bank-table-container">
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {banks.map((bank, index) => (
                                            <TableRow
                                                className="bank-row-bg"
                                                key={bank.id}
                                            >
                                                <TableCell >
                                                    <Stack direction='row' spacing={2}>
                                                        <img src={bank.logo} alt={`${bank.name} Logo`} className="bank-logo" />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell >{bank.number}</TableCell>
                                                <TableCell >
                                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    {menuAnchorEl?.index === index && (
                                                        <Menu
                                                            anchorEl={menuAnchorEl.anchorEl}
                                                            open={Boolean(menuAnchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem onClick={handleInvestClick}>
                                                                <Box display="flex" alignItems="center">
                                                                    {/* <div className="square"></div> */}
                                                                    <DeleteIcon />
                                                                    <Typography>Delete</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        </Menu>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                    {/* <TableBody>
                                        {banks2.map((bank, index) => (
                                            <TableRow
                                            
                                                key={bank.id}
                                            >
                                                <TableCell
                                             
                                                >
                                                    <Stack direction='row' spacing={2}>
                                                        <AddCircleOutlineIcon />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell ></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody> */}

                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="details-container">
                            <header className="add-bank-header">
                                <div>

                                    <div className='d-flex align-items-center justify-content-between'>

                                        <h5 className="bank-title">Choose bank for Anand Arjun Rao</h5>

                                        <ClearIcon className="edit-icon" onClick={closeButton}/>

                                    </div>
                                    <p

                                    >
                                        Bank is compulsory for opening an investment account</p>
                                </div>
                            </header>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Autocomplete
                                        options={banksinput}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search for your bank" variant="outlined"
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} display="flex" alignItems="center" gap={1}>
                                                <Avatar src={option.logo} alt={option.name} sx={{ width: 24, height: 24 }} />
                                                {option.name}
                                            </Box>
                                        )}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <lable> Enter your IFSC number for </lable>
                                    <TextField
                                        sx={{ mt: 3 }}
                                        fullWidth
                                        placeholder="Type the code or search branch"
                                        variant="outlined"
                                        className="search-field"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={SbiIcon} alt="sbi-icon" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* 
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
                open={open}
                onClose={handleClose}
                className="autopay-dialog"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle className="dialog-title">
                    <Box className="title-container">
                        <InfoIcon className="info-icon" />
                        <Typography variant="h6" className="title-text">
                            Important
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Typography variant="h5" className="main-question">
                        Are you sure you want to change your primary AutoPay?
                    </Typography>
                    <Typography className="info-text">
                        Your existing instalments will continue being debited from the older AutoPay.
                    </Typography>
                    <Typography className="info-text">
                        Your new instalments will be debited from your new primary AutoPay.
                    </Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button
                        onClick={handleClose}
                        className="cancel-button"
                        variant="text"
                    >
                        CANCEL
                    </Button>
                    <Button
                        onClick={handleClose}
                        className="confirm-button"
                        variant="text"
                    >
                        MAKE PRIMARY
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

