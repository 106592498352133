import React from 'react';
import { Box, Typography, Button, Divider, FormControl, Select, MenuItem } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

const Fundhistory = () => {
    const [selectedYear, setSelectedYear] = React.useState("Apr 2024 - Mar 2025");

    const financialYears = [
        "Apr 2023 - Mar 2024",
        "Apr 2024 - Mar 2025",
        "Apr 2025 - Mar 2026",
    ];

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        fromDate: Yup.date()
            .nullable()
            .required("From date is required.")
            .max(dayjs(), "From date cannot be in the future."),
        toDate: Yup.date()
            .nullable()
            .required("To date is required.")
            .min(Yup.ref('fromDate'), "To date cannot be before from date.")
            .max(dayjs(), "To date cannot be in the future."),
    });

    const handleSubmit = (values) => {
        console.log("Selected Values:", values);
    };

    return (
        <Formik
            initialValues={{ fromDate: null, toDate: null }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Box className="card-content w-100 watchlist-card">
                        <Typography mt={2} className="font-600 align-center d-flex card-padding">
                            Mutual Funds - ELSS Statement
                        </Typography>
                        <Divider sx={{ my: 2 }} />

                        {/* Choose Financial Year */}
                        <Box className="d-flex justify-between watchlist-textpadding">
                            <Box sx={{ width: "100%", margin: "auto" }}>
                                <Typography mb={1} className="font-14">
                                    Choose financial year
                                </Typography>
                                <FormControl fullWidth variant="outlined">
                                    <Select
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        displayEmpty
                                        inputProps={{ "aria-label": "Choose financial year" }}
                                        sx={{
                                            backgroundColor: "#fff",
                                            border: "1px solid #E0E0E0",
                                            borderRadius: "8px",
                                            "& .MuiSelect-select": {
                                                padding: "10px 16px",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        }}
                                    >
                                        {financialYears.map((year, index) => (
                                            <MenuItem key={index} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        {/* Date Picker Section */}
                        <Box className="date-flex justify-between watchlist-textpadding mb-150" gap={2} mt={3}>
                            <Box className="funcard-padding">
                                <Typography className="font-14">From <span style={{color:'red'}}>*</span></Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            value={values.fromDate}
                                            onChange={(newValue) => setFieldValue("fromDate", newValue)}
                                            format="DD/MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        height: "48px",
                                                        backgroundColor: "#fff",
                                                        border: "1px solid #E0E0E0",
                                                        borderRadius: "8px",
                                                        "& .MuiOutlinedInput-root": {
                                                            height: "48px",
                                                            padding: "10px 12px",
                                                        },
                                                        "& .MuiOutlinedInput-input": {
                                                            padding: "10px 12px",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <ErrorMessage
                                    name="fromDate"
                                    component="div"
                                    style={{ color: "red", fontSize: "16px", marginTop: "4px" }}
                                />
                            </Box>

                            <Box className="funcard-padding">
                                <Typography className="font-14">To <span style={{color:'red'}}>*</span></Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            value={values.toDate}
                                            onChange={(newValue) => setFieldValue("toDate", newValue)}
                                            format="DD/MM/YYYY"
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        height: "48px",
                                                        backgroundColor: "#fff",
                                                        border: "1px solid #E0E0E0",
                                                        borderRadius: "8px",
                                                        "& .MuiOutlinedInput-root": {
                                                            height: "48px",
                                                            padding: "10px 12px",
                                                        },
                                                        "& .MuiOutlinedInput-input": {
                                                            padding: "10px 12px",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                <ErrorMessage
                                    name="toDate"
                                    component="div"
                                    style={{ color: "red", fontSize: "16px", marginTop: "4px" }}
                                />
                            </Box>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        {/* Download Button */}
                        <Box className="card-padding" sx={{ mb: 2 }}>
                            <Button
                                type="submit"
                                className="start-sipbtn"
                                variant="contained"
                                fullWidth
                                sx={{ bgcolor: "#1C5DB8", color: "#fff", mt: 2 }}
                            >
                                Download
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default Fundhistory;
