import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  Divider,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  ExpandMore,
  ExpandLess,
  MoreVert,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EventIcon from "@mui/icons-material/Event";
import OTPInput from "../pages/home/opt";
import InfoIcon from "@mui/icons-material/Info";
import Autopay from "./autopay";

import { LineChart } from "@mui/x-charts/LineChart";
import { deleteWatchList, addedWatchList } from "../actions/watchListAction";
import SbiLinechart from "./LineChart";
import {
  getLocalStorageUserData,
  getLocalStorageUserToken,
  getLocalStorageUserId,
} from "../utils/userData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const data = [
  {
    name: "SBI Magnum Mid Cap Direct Plan Growth",
    dayChange: "₹123 (12%)",
    returns: "-97 (10%)",
    current: "₹5,219",
    expanded: false,
  },
  {
    name: "Aditya Birla Sun Life PSU Equity Fund Direct Growth",
    dayChange: "₹123 (12%)",
    returns: "-97 (10%)",
    current: "₹5,219",
    expanded: true, // Expanded to show details by default for demonstration
  },
  {
    name: "Motilal Oswal Midcap Fund Direct Growth",
    dayChange: "₹123 (12%)",
    returns: "-97 (10%)",
    current: "₹5,219",
    expanded: false,
  },
];

export default function DashboardInvestment({ dataProduct }) {
  const dispatch = useDispatch(); // Initialize dispatch
  const [selectedOption, setSelectedOption] = useState("invest");
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [showAutoPayDetails, setShowAutoPayDetails] = useState(false); // State for AutoPay details view
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [buttonCheck, setButtonCheck] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(dataProduct?.watch_list);

  const [isAddInCart, setIsAddInCart] = useState(dataProduct?.plus);
  const userData = getLocalStorageUserData();
  const token = getLocalStorageUserToken();
  const navigate = useNavigate();
  const redirectToLoginIfNoToken = () => {
    if (!token) {
      navigate("/login");
    }
  };

  const handleStartSIP = () => {
    setShowOtpSection(true); // Show OTP section when 'Start SIP' is clicked
  };
  const handleBackToSIP = () => {
    setShowOtpSection(false); // Hide OTP section and go back to the SIP section
  };
  const handleAutoPayClick = () => {
    setShowAutoPayDetails(true); // Show the AutoPay details section
  };

  const handleBackToMain = () => {
    setShowAutoPayDetails(false); // Go back to the main card view
  };

  const [rows, setRows] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...rows].sort((a, b) => {
      if (key === "name") {
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        const aValue = parseFloat(a[key].replace(/[₹,%\s]/g, ""));
        const bValue = parseFloat(b[key].replace(/[₹,%\s]/g, ""));
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
    setRows(sortedData);
  };

  const getSortIcon = (key) => {
    return sortConfig.key === key ? (
      sortConfig.direction === "asc" ? (
        <ArrowDropUpIcon className="drop-icon" />
      ) : (
        <ArrowDropDownIcon className="drop-icon" />
      )
    ) : null;
  };

  const toggleExpand = (index) => {
    setRows((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, expanded: !row.expanded } : row
      )
    );
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMenuOpen = (event, index) =>
    setMenuAnchorEl({ anchorEl: event.currentTarget, index });
  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleRedeemClick = () => {
    setSelectedOption("redeem");
    handleMenuClose();
  };

  const handleInvestClick = () => {
    setSelectedOption("invest");
    handleMenuClose();
  };

  const handleBookmarkClick = () => {
    redirectToLoginIfNoToken();
    if (!token) return; // Prevent further execution if no token

    const payload = {
      id: getLocalStorageUserId(), // Get userId from localStorage (or any other source)
      product_id: dataProduct?.id, // Use the product ID from props
    };
    // If already bookmarked, remove from the watchlist
    if (isBookmarked) {
      dispatch(deleteWatchList(payload));
      //   dispatch(changewatchlistState(!loadData));
    } else {
      dispatch(addedWatchList(payload)); // Dispatch add action
    }
    setIsBookmarked(!isBookmarked);
  };

  const AddToCart = (invest, investNow) => {
    redirectToLoginIfNoToken();
    if (!token) return;
    if (investNow == "InvestNow") {
      const payload = {
        id: userData.id,
        product_id: dataProduct?.product?.id,
        amc_product_id: dataProduct?.product?.productlimits[0]?.amc_product_id,
        amc_code: dataProduct?.product?.productlimits[0]?.amc_code,
        product_code: dataProduct?.product?.productlimits[0]?.product_code,
        isin: dataProduct?.product?.isin,
        is_additional_purchase: invest == 1 ? true : false,
        reinvest: dataProduct?.product?.reinvest_tag,
      };
      //   dispatch(AddToMyCart(payload)); // Dispatch add action
    } else {
      if (isAddInCart) {
        // dispatch(
        //   DeleteMyCart(
        //     dataProduct?.product?.productlimits[0]?.amc_product_id,
        //     1
        //   )
        // ); // Dispatch delete action
        // dispatch(changeAddToCartState(!loadData));
      } else {
        const payload = {
          id: userData.id,
          product_id: dataProduct?.product?.id,
          amc_product_id:
            dataProduct?.product?.productlimits[0]?.amc_product_id,
          amc_code: dataProduct?.product?.productlimits[0]?.amc_code,
          product_code: dataProduct?.product?.productlimits[0]?.product_code,
          isin: dataProduct?.product?.isin,
          is_additional_purchase: invest == 1 ? true : false,
          reinvest: dataProduct?.product?.reinvest_tag,
        };
        // dispatch(AddToMyCart(payload)); // Dispatch add action
      }
      setIsAddInCart(!isAddInCart);
      setButtonCheck(true);
    }
  };

  return (
    <div className="wrapper">
      <Grid container textAlign="start" spacing={2}>
        <Grid item xs={12} md={8.5}>
          <Grid container textAlign="start" spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                {" "}
                <div>
                  <img
                    src={dataProduct?.amc?.icon}
                    alt="sbiicon"
                    height="64px"
                    width="64px"
                  />
                </div>
                <Typography mt={2} mb={2} variant="h5" className="chart-title ">
                  {dataProduct?.product_long_name}
                </Typography>
              </Box>

            </Grid>

            <Grid item xs={12} md={12} sx={{ mb: 6 }}>
              <div>
                <SbiLinechart />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3.5} sx={{ mb: 6 }}>
          <Grid container textAlign="start" spacing={2}>
            <Grid item xs={12} md={12}>
              <Box textAlign="end">
              <IconButton
                  sx={{ background: "rgba(245, 245, 245, 1)", mb: 2 }}
                  onClick={handleBookmarkClick}
                >
                  {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                </IconButton>
              </Box>
              <Box className="watchlist-card">
                {selectedOption === "invest" && (
                  <Box className="card-content">
                    {!showOtpSection ? (
                      !showAutoPayDetails ? (
                        <>
                          {/* <Typography align='center' className='font-600 watchlist-textpadding' sx={{ mt: 2 }}>
                                                        Aditya Birla Sun Life PSU Equity Fund Direct Growth
                                                    </Typography> */}
                          {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}
                          <Box
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              mb: 2,
                            }}
                          >
                            <Tabs
                              value={tabValue}
                              onChange={handleTabChange}
                              textColor="primary"
                              indicatorColor="primary"
                              variant="fullWidth"
                              className="sip-tabs"
                            >
                              <Tab label="Monthly SIP" />
                              <Tab label="One-Time" />
                            </Tabs>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            className="watchlist-textpadding"
                          >
                            <Typography>SIP Amount</Typography>
                            <Box
                              className="sip-aamount-box "
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <CurrencyRupeeIcon fontSize="small" />
                              <Typography sx={{ fontWeight: "bold" }}>
                                5000
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                            className="watchlist-textpadding"
                          >
                            <Typography>Monthly SIP Date</Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              className="sip-aamount-box"
                              justifyContent="space-between"
                            >
                              <EventIcon fontSize="small" />
                              <Typography sx={{ fontWeight: "bold" }}>
                                8th
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                                                        Next SIP instalment is on 8th Nov
                                                    </Typography>
                                                    <Divider className="custom-hr" sx={{ my: 2 }} />
                                                    <Box >
                                                        <Typography className='font-600 watchlist-textpadding'>Pay via AutoPay</Typography>
                                                        <Box className="d-flex justify-between watchlist-textpadding">
                                                            <Typography sx={{ mb: 2, mt: 2 }} className="d-flex "><div class="square"></div>HDFC BANK....1234</Typography>
                                                            <IconButton onClick={handleAutoPayClick}>
                                                                <ChevronRightIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box> */}

                          <Divider className="custom-hr" sx={{ my: 2 }} />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{ mb: 2, mt: 2 }}
                            className="watchlist-textpadding"
                          >
                            <Button
                              className="add-cartbtn"
                              fullWidth
                              sx={{ mr: 1 }}
                              onClick={() => {
                                AddToCart(0);
                              }}
                            >
                              Add to Cart
                            </Button>
                            <Button
                              className="start-sipbtn "
                              fullWidth
                              sx={{ ml: 1 }}
                              onClick={handleStartSIP}
                            >
                              Start SIP
                            </Button>
                          </Box>
                        </>
                      ) : (
                        <div>
                          <Autopay onBack={handleBackToMain} />
                        </div>
                      )
                    ) : (
                      <Box className="card-content">
                        <Typography
                          sx={{ mt: 2 }}
                          className="font-600 align-center d-flex card-padding"
                        >
                          <KeyboardBackspaceIcon
                            sx={{ mr: 2 }}
                            onClick={handleBackToSIP}
                          />{" "}
                          Authorize with OTP
                        </Typography>
                        <Divider className="custom-hr" sx={{ my: 2 }} />
                        <Box alignItems="center" textAlign="center" mb={2}>
                          <Typography sx={{ mb: 4, mt: 2 }}>
                            Enter a 6-digit OTP sent to abc@gmail.com &
                            9999999999
                          </Typography>
                          <OTPInput
                            inputStyles={{ width: "30px", height: "30px" }}
                            separatorWidth={0}
                          />
                          <Typography sx={{ mt: 2 }}>
                            Didn't receive OTP? 
                            <a className="text-blue resend-link">Resend</a>
                          </Typography>
                        </Box>
                        <Box sx={{ mb: 2, mt: 6 }} className="card-padding">
                          <Button
                            className="confirmbtn"
                            fullWidth
                            sx={{ mr: 1 }}
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
                {selectedOption === "redeem" && (
                  <Box className="card-content">
                    {/* Back Button */}
                    <Typography
                      sx={{ mt: 2 }}
                      className="font-600 align-center d-flex card-padding"
                    >
                      <KeyboardBackspaceIcon
                        onClick={handleBackToSIP}
                        sx={{ mr: 2 }}
                      />{" "}
                      Redeem
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      className="watchlist-textpadding"
                    >
                      <Typography>Enter Amount</Typography>
                      <Box
                        className="sip-aamount-box"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CurrencyRupeeIcon fontSize="small" />
                        <Typography sx={{ fontWeight: "bold" }}>0</Typography>
                      </Box>
                    </Box>
                    <Box
                      className="d-flex align-center card-padding justify-end redeem-form-check"
                      sx={{ mb: 2 }}
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Redeem All"
                      />
                    </Box>
                    <Box className="d-flex justify-between">
                      <Typography
                        variant="body2"
                        className="card-padding"
                        sx={{ mb: 2 }}
                      >
                        <b> Total Redeemable (approx.):</b>
                      </Typography>
                      <Typography
                        variant="body2"
                        className="card-padding"
                        sx={{ mb: 2 }}
                      >
                        <b>₹466.11</b>
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box
                      className="d-flex text-center card-padding align-center"
                      sx={{ mb: 6 }}
                    >
                      <Typography variant="body2">
                        Expected completion in 2 working days (by 07 Nov 2024){" "}
                        <InfoIcon
                          sx={{
                            ml: 1,
                            fontSize: 25,
                            color: "rgba(11, 36, 194, 1)",
                          }}
                        />
                      </Typography>
                    </Box>
                    <Box className="card-padding" sx={{ mb: 2, mt: 6 }}>
                      <Button
                        className="start-sipbtn"
                        variant="contained"
                        fullWidth
                        sx={{ bgcolor: "#1C5DB8", color: "#fff", mt: 2 }}
                      >
                        Redeem
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
