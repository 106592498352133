import React, { useState } from "react";
import {
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../assets/css/profile-reports.css";
import Reportimg from "../../assets/images/selectreport.svg";
import ELSSDetails from "../profiledetails/Elss-details"; // Import the ELSSDetails component
import CapitalGains from "./capital-gains";
import HoldingStatement from "./holding-statement";
import Fundhistory from "./fund-history";

const Reports = () => {
    const [selectedReport, setSelectedReport] = useState("");

    const categories = [
        {
            title: "Tax",
            reports: ["Mutual Funds - ELSS statement", "Mutual Funds - Capital gains"],
        },
        {
            title: "Holdings",
            reports: ["Mutual Funds - Holdings statement"],
        },
        {
            title: "Transactions",
            reports: ["Mutual Funds - Order history"],
        },
    ];

    // Function to render the corresponding component
    const renderReportComponent = () => {
        switch (selectedReport) {
            case "Mutual Funds - ELSS statement":
                return <ELSSDetails />; // Render the ELSSDetails component
            case "Mutual Funds - Capital gains":
                return <CapitalGains />;
            case "Mutual Funds - Holdings statement":
                return <HoldingStatement />;
            case "Mutual Funds - Order history":
                return <Fundhistory />;
            default:
                return (
                    <Box className="placeholder right-section" >
                        <Box mt={6} mb={6}>
                            <img src={Reportimg} alt="Warning" />
                            <Typography className="placeholder-text">
                                Select a report to get started
                            </Typography>
                        </Box>
                    </Box>
                );
        }
    };

    return (

        <Grid container spacing={1}>
            {/* Left Section */}
            <Grid item xs={12} md={6} className="left-section">
                {categories.map((category, index) => (
                    <Box key={index} className="category border-section">
                        <Typography className="category-title">{category.title}</Typography>
                        <List>
                            {category.reports.map((report, idx) => (
                                <ListItem key={idx} disablePadding>
                                    <ListItemButton
                                        className={`report-item ${selectedReport === report ? "active" : ""
                                            }`}
                                        onClick={() => setSelectedReport(report)}
                                    >
                                        <Typography>{report}</Typography>
                                        <ArrowForwardIosIcon className="arrow-icon" />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                ))}
            </Grid>

            {/* Right Section */}
            <Grid item xs={12} md={6} p={0}>
                {renderReportComponent()}
            </Grid>
        </Grid>

    );
};

export default Reports;
