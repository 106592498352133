import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
    DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from '@mui/icons-material/Percent';
import CallMadeIcon from '@mui/icons-material/CallMade';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import MenuIcon from '@mui/icons-material/Menu';
import Hourglass from '../assets/images/hourglassicon.svg';
import Withdraw from '../assets/images/withdrawpopupicon.svg';
import Amount from '../assets/images/changeamountpopup.svg';
import Goal from '../../src/assets/images/goalpopupicon.svg';

const CancelSIPPopup = ({ open, handleClose }) => {
    const [showSecondPopup, setShowSecondPopup] = useState(false);
    const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
    const [showAmountPopup, setShowAmountPopup] = useState(false);
    const [showGoalPopup, setShowGoalPopup] = useState(false);
    const [showOtherreasonPopup, setShowOtherreasonPopup] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    // Function to handle the first option click
    const handleFirstOptionClick = () => {
        handleClose(); // Close the first popup
        setShowSecondPopup(true); // Open the second popup
    };

    // Function to close the second popup
    const handleSecondPopupClose = () => {
        setShowSecondPopup(false);
    };

    // Function to open the "Withdraw Money" popup
    const handleWithdrawOptionClick = () => {
        handleClose();
        setShowWithdrawPopup(true);
    };

    // Function to close the "Withdraw Money" popup
    const handleWithdrawPopupClose = () => {
        setShowWithdrawPopup(false);
    };

    // Function to open the "change amount and date" popup
    const handleAmountOptionClick = () => {
        handleClose();
        setShowAmountPopup(true);
    };

    // Function to close the "change amount and date" popup
    const handleAmountPopupClose = () => {
        setShowAmountPopup(false);
    };
    // Function to close the second popup
    const handleGoalOptionClick = () => {
        handleClose(); // Close the first popup
        setShowGoalPopup(true); // Open the second popup
    };
    const handleGoalPopupClose = () => {
        setShowGoalPopup(false);
    };
    // Function to close the other reason popup
    const handleOtherreasonOptionClick = () => {
        handleClose(); // Close the first popup
        setShowOtherreasonPopup(true);
    };
    const handleOtherreasonPopupClose = () => {
        setShowOtherreasonPopup(false);
    };

    const handleCancelClick = () => {
        setShowMessage(true); // Show the message
    };


    const reasons = [
        {
            icon: <PercentIcon className="list-icon" />,
            text: (
                <>
                    Not satisfied with <Typography component="span" fontWeight="500">returns</Typography>
                </>
            ),
            action: handleFirstOptionClick, // Close first popup and open second
        },
        {
            icon: <CallMadeIcon />,
            text: (
                <>
                    Want to <Typography component="span" fontWeight="500">withdraw</Typography> money
                </>
            ),
            action: handleWithdrawOptionClick, // Open the "Withdraw Money" popup
        },
        {
            icon: <EditIcon />,
            text: (
                <>
                    Want to <Typography component="span" fontWeight="500">change SIP Amount/date</Typography>
                </>
            ),
            action: handleAmountOptionClick, // Open the "change amount and date" popup
        },
        {
            icon: <DoneIcon />,
            text: (
                <>
                    <Typography component="span">Goal is completed</Typography>
                </>
            ),
            action: handleGoalOptionClick,
        },
        {
            icon: <MenuIcon />,
            text: (
                <>
                    <Typography>Other reasons to cancel</Typography>
                </>
            ),
            action: handleOtherreasonOptionClick,
        },
    ];

    return (
        <>
            {/* First Popup */}
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <div className="cancel-sip-popup">
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Choose your reason to cancel</Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            {reasons.map((reason, index) => (
                                <ListItemButton
                                    key={index}
                                    className="list-border"
                                    onClick={reason.action || handleClose}
                                >
                                    <ListItemIcon>{reason.icon}</ListItemIcon>
                                    <ListItemText primary={reason.text} />
                                </ListItemButton>
                            ))}
                        </List>
                    </DialogContent>
                </div>
            </Dialog>
            {/* Second Popup */}
            <Dialog open={showSecondPopup} onClose={handleSecondPopupClose} fullWidth maxWidth="xs" textAlign='center'>
                <div className='cancel-sip-popup'>
                    <DialogTitle textAlign='center' mt={2}>
                        <img src={Hourglass} alt="Warning" />
                    </DialogTitle>
                    <DialogContent>
                        <Box textAlign="center">
                            <Typography variant="body1" fontWeight="bold" mt={2}>
                                Your SIP is still young
                            </Typography>
                            <Typography variant="body2" mt={1} className='font-12'>
                                Investing consistently over time can deliver better returns, even in volatile markets.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button variant="contained" className='start-sipbtn' fullWidth onClick={handleSecondPopupClose}>
                            Cancel SIP
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button variant="text" className='continue-investingbtn' fullWidth onClick={handleSecondPopupClose}>
                            Continue Investing
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {/* Withdraw Money Popup */}
            <Dialog open={showWithdrawPopup} onClose={handleWithdrawPopupClose} fullWidth maxWidth="xs">
                <div className='cancel-sip-popup'>
                    <DialogTitle textAlign='center' mt={2}>
                        <img src={Withdraw} alt="Warning" />
                    </DialogTitle>

                    <DialogContent>
                        <Box textAlign="center">
                            <Typography variant="body1" fontWeight="bold" mt={1}>
                                Your money won’t be withdrawn by cancelling
                            </Typography>
                            <Typography variant="body2" mt={1} className='font-12'>
                                Only your future instalments will be stopped. ‘Redeem’ separately to withdraw invested amount.
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button variant="contained" className='start-sipbtn' fullWidth onClick={handleWithdrawPopupClose}>
                            Cancel SIP
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button variant="text" className='continue-investingbtn' fullWidth onClick={handleWithdrawPopupClose}>
                            Redeem Investments
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {/* change sip date and ammount popup */}
            <Dialog open={showAmountPopup} onClose={handleAmountPopupClose} fullWidth maxWidth="xs">
                <div className='cancel-sip-popup'>
                    <DialogTitle textAlign='center' mt={2}>
                        <img src={Amount} alt="Warning" />
                    </DialogTitle>
                    <DialogContent>
                        <Box textAlign="center">
                            <Typography variant="body1" fontWeight="bold" mt={1}>
                                You can change SIP amount/date without <br></br>cancelling
                            </Typography>

                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button variant="contained" className='start-sipbtn' fullWidth onClick={handleAmountPopupClose}>
                            Cancel SIP
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button variant="text" className='continue-investingbtn' fullWidth onClick={handleAmountPopupClose}>
                            Redeem Investments
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {/* goal popup */}
            <Dialog open={showGoalPopup} onClose={handleGoalPopupClose} fullWidth maxWidth="xs">
                <div className='cancel-sip-popup'>
                    <DialogTitle textAlign='center' mt={2}>
                        <img src={Goal} alt="Warning" />
                    </DialogTitle>
                    <DialogContent>
                        <Box textAlign="center">
                            <Typography variant="body1" fontWeight="bold" mt={1}>
                                Your dedication and patience paid off!
                            </Typography>
                            <Typography variant="body2" mt={1} className='font-12'>
                                Happy to join on your path to financial success
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center", mb: 1 }} >
                        <Button variant="contained" className='start-sipbtn' fullWidth onClick={handleGoalPopupClose}>
                            Cancel SIP
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
            {/* other reasons popups */}
            <Dialog open={showOtherreasonPopup} onClose={handleOtherreasonPopupClose} fullWidth maxWidth="xs">
                <div className='cancel-sip-popup'>
                    <DialogTitle textAlign='center' mt={2}>
                        <img src={Withdraw} alt="Warning" />
                    </DialogTitle>
                    <DialogContent>
                        <Box textAlign="center">
                            <Typography variant="body1" fontWeight="bold" mt={1}>
                                Future instalments will be stopped
                            </Typography>
                            <Typography variant="body2" mt={1} className='font-12'>
                                Your invested amount stays in this fund until you redeem
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: "center", flexDirection: "column", gap: 1 }}>
                        {/* Conditional rendering of the message */}
                        {showMessage && (
                            <Box
                                sx={{
                                    backgroundColor: "#F5F5F5",
                                    borderRadius: "8px",
                                    textAlign: "center",
                                    width: "100%",
                                }}
                            >
                                <Typography variant="body2" className='font-12' p={2}>
                                    ₹500 will be deducted on 06 Nov 2024 as we have already started processing your order
                                </Typography>
                            </Box>
                        )}
                        <Button variant="contained" className='start-sipbtn' fullWidth onClick={handleCancelClick}>
                            Cancel SIP
                        </Button>
                    </DialogActions>
                    <DialogActions sx={{ justifyContent: "center", mb: 2 }}>
                        <Button variant="text" className='continue-investingbtn' fullWidth onClick={handleOtherreasonPopupClose}>
                            Redeem Investments
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

export default CancelSIPPopup;
