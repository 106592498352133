import React from 'react';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar, Link } from '@mui/material';
import Header from '../pages/home/Appbar';
import Hero from '../assets/images/abouthero.svg';
import Who1 from '../assets/images/who1.svg';
import Who2 from '../assets/images/who2.svg';
import Who3 from '../assets/images/who3.svg';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';
import '../assets/css/aboutus.css';
import WebFooter from '../components/Web-footer';
import GetStart from '../components/getstart-card-about';

function AboutUs() {
    return (
        <>
            <div>
                <Header />
                <Grid container alignItems="center" className="heroSectionContainer">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h1" className="heroText mb-3" sx={{ mb: 3 }}>
                            Easy,<br></br>
                            Simple &<br></br>
                            Transparent.
                        </Typography>
                        <Typography variant="body1" className="heroSubText mb-4" sx={{ mb: 4 }}>
                            We are here to empower people, guide them to<br></br> make intelligent
                            investment and achieve their financial goals.
                        </Typography>
                        {/* <Button variant="contained" className="getStartedBtn">
                            Get Started
                        </Button> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            src={Hero}
                            alt="hero"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Grid>
                </Grid>
                <div className='wrapper'>
                    <Grid container alignItems="center" sx={{ mt: 5, mb: 5 }}>
                        <Grid item xs={12} md={12}>
                            <Typography className='who-head mb-3'>
                                Who Are We
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography className='who-title'>Service First</Typography>
                            <Typography>We are committed to make decision that will be centered around
                                our customer first approach.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="end ">
                            <img
                                src={Who1}
                                className='whoimg'
                                alt="who1"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="start ">
                            <img
                                src={Who2}
                                className='whoimg'
                                alt='who2'
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography className='who-title'>Tailored for you</Typography>
                            <Typography>No two financial journeys are the same. That’s why we focus on
                                personalized solutions, offering a range of services like SIPs, SWPs,
                                and Mutual Funds to cater to your unique goals and preferences.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography className='who-title'>Honesty & Integrity</Typography>
                            <Typography>We believe in transparency as the cornerstone of trust.
                                Our fee structures are straightforward, and our commitment to security ensures
                                that your financial data is always protected.</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="end ">
                            <img
                                src={Who3}
                                className='whoimg'
                                alt="who3"
                            />
                        </Grid>
                    </Grid>
                </div>
                <GetStart />
                <WebFooter />
            </div >
        </>
    );
}

export default AboutUs;
