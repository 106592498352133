import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Grid,
    Avatar,
    Link,
    Box,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    IconButton,
    TableBody,
    MenuItem,
    Divider,
    FormControlLabel,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import UPI from '../assets/images/upi.svg';
import HDFC from '../assets/images/hdfc.svg';
import Info from '../assets/images/info.svg';
import "../assets/css/home.css";
import "../assets/css/common.css";
import "../assets/css/aboutus.css";
import OTPInput from '../pages/home/opt';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Formik, useFormik } from 'formik';
import * as Yup from "yup";



function Authorisedotp({ onBack }) {
    const [showOtpSection, setShowOtpSection] = useState(false);
    const validationSchema = Yup.object().shape({
        otp: Yup.string().when("verifyOtp", (verifyOtp, schema) => {
              return verifyOtp[0] === true
                ? schema
                    .required("OTP is required")
                    .length(6, "OTP must be exactly 6 digits")
                : schema;
            }),
    })

    const formik = useFormik({
        initialValues:{
            otp:''
        },
        enableReinitialize: true,
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            console.log("values",values);
        }
    });
    const handleBackToSIP = () => {
        setShowOtpSection(false);  // Hide OTP section and go back to the SIP section
    };

    return (
        <>
        <form>
        <Box className="card-content">
                <Typography sx={{ mt: 2 }} className='font-600 align-center d-flex card-padding'>
                    <KeyboardBackspaceIcon className="back-arrowbtn" sx={{ mr: 2 }} onClick={onBack} /> Authorize with OTP
                </Typography>
                <Divider className="custom-hr" sx={{ my: 2 }} />
                <Box alignItems="center" textAlign='center' mb={2} >
                    <Typography sx={{ mb: 4, mt: 2 }}>Enter a 6-digit OTP sent to abc@gmail.com &
                        9999999999</Typography>
                    <OTPInput inputStyles={{ width: '30px', height: '30px', }}length={6} formik={formik} />
                    <Typography sx={{ mt: 2 }}>Didn't receive OTP?
                        <a className='text-blue resend-link'>Resend</a>
                    </Typography>
                </Box>
                <Box sx={{ mb: 2, mt: 6 }} className='card-padding'>
                    <Button className='confirmbtn' fullWidth sx={{ mr: 1 }}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </form>
        </>
    );
}

export default Authorisedotp;
