import React, { useState } from "react";
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    Stack,
    Button,
    TextField,
    Tooltip
} from '@mui/material';
import MoreVert from "@mui/icons-material/MoreVert";
import InfoIcon from '@mui/icons-material/Info';


import { ChevronRight } from '@mui/icons-material';
import Header2 from "../home/Appbar2";

import "../../assets/css/funddetails.css"
import "../../assets/css/onbording.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';

import hdfcIcon from "../../assets/images/hdfc.svg"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Edit, ContentCopy } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from "react-router-dom";

export default function TransactionList() {

    const navigate = useNavigate();
const [openEditModal, setOpenEditModal] = useState(false);
const [ifscCode, setIfscCode] = useState("ABCD1234567");
const [tooltipText, setTooltipText] = useState("Copy");
const autopayId = "ABCD123456789012345678912";

const handleEditClick = () => {
  setOpenEditModal(true); // Open modal on edit button click
};

const handleCloseEditModal = () => {
  setOpenEditModal(false); // Close modal
};

const handleSaveEdit = () => {
  setOpenEditModal(false); // Close modal and save value
  console.log("New IFSC Code: ", ifscCode); // You can also handle API calls here
};


    const nextPage = ()=>{
        navigate('/addbank');
    }
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
        setTooltipText("Copied!");
        setTimeout(() => setTooltipText("Copy"), 2000);
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpen = (event, index) => {
        setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleInvestClick = () => {
        console.log("Invest action triggered");
        handleMenuClose();
    };

    const handleRedeemClick = () => {
        console.log("Redeem action triggered");
        handleMenuClose();
    };

    const handleViewTransactionsClick = () => {
        console.log("View Transactions action triggered");
        handleMenuClose();
    };

    const handleStartSwpClick = () => {
        console.log("Start SWP action triggered");
        handleMenuClose();
    };

    const handleStartStpClick = () => {
        console.log("Start STP action triggered");
        handleMenuClose();
    };

    const banks = [
        { id: 1, name: "HDFC BANK", number: "XXXX1234", logo: hdfcIcon },
        // Add more bank objects if needed
    ];

    const banks2 = [
        { id: 1, name: "Add Another Bank", },
        // Add more bank objects if needed
    ];

    // -------------//

    const [anchorElAutoPayMenu, setAnchorElAutoPayMenu] = useState(null);

    const handleMenuOpen1 = (event) => {
        setAnchorElAutoPayMenu(event.currentTarget);
    };

    const handleMenuClose1 = () => {
        setAnchorElAutoPayMenu(null);
    };

    const handleAddAutopay = () => {
        console.log("Add Autopay action triggered");
        handleMenuClose();
    };

    const handleEditAutopay = () => {
        console.log("Edit Autopay action triggered");
        handleMenuClose();
    };

    const handleDeleteAutopay = () => {
        console.log("Delete Autopay action triggered");
        handleMenuClose();
    };

    const handlePauseAutopay = () => {
        console.log("Pause Autopay action triggered");
        handleMenuClose();
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Header2 />
            <div className="wrapper border-top pan-card-margin">
                {/* <Box className="bank-mt-17">
                </Box> */}
                <Grid container spacing={2} className="transaction-container">
                    <Grid item xs={12} md={12}>
                        <Typography className='bank-text-head'> Bank Account Details </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="transaction-container">
                    <Grid item xs={12} md={6} >
                        <div className="bank-table-container">
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {banks.map((bank, index) => (
                                            <TableRow
                                                className="bank-row-bg"
                                                key={bank.id}
                                            >
                                                <TableCell >
                                                    <Stack direction='row' spacing={2} >
                                                        <img src={bank.logo} alt={`${bank.name} Logo`} />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell >{bank.number}</TableCell>
                                                <TableCell alignItems="end">
                                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    {menuAnchorEl?.index === index && (
                                                        <Menu
                                                            anchorEl={menuAnchorEl.anchorEl}
                                                            open={Boolean(menuAnchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem onClick={handleInvestClick}>
                                                                <Box display="flex" alignItems="center">
                                                                    {/* <div className="square"></div> */}
                                                                    <DeleteIcon />
                                                                    <Typography>Delete</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        </Menu>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableBody>
                                        {banks2.map((bank, index) => (
                                            <TableRow
                                                // className="bank-row"
                                                key={bank.id}
                                            >
                                                <TableCell
                                                >
                                                    <Box display='flex' alignItems='center'>
                                                        <AddCircleIcon className="text-blue" />
                                                        <Typography ml={1} className="text-blue bank-text" fontWeight='600' onClick={nextPage} style={{cursor:'pointer'}}> {bank.name} </Typography>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="details-container">
                            <header className="bank-header">
                                <img
                                    src={hdfcIcon}
                                    alt="HDFC Bank Logo"
                                // className="bank-logo"
                                />
                                <div>
                                    <h4 className="bank-title">HDFC BANK</h4>
                                    <p className="bank-subtitle">Primary bank</p>
                                </div>
                            </header>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={14.5}>
                                            <div className="details-label">Status</div>
                                            <div className="details-value">Verified</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={13}>
                                            <div className="details-label">Account</div>
                                            <div className="details-value">XXXXXXXXXX1234</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={10.9}>
                                        <div className="details-label white-space-nowrap">IFSC Code</div>
                                        <div className="details-value white-space-nowrap">
                                            {ifscCode}
                                            <IconButton className="edit-button" size="small" onClick={handleEditClick}>
                                            <Edit fontSize="small" className="copyicon" />
                                            </IconButton>
                                        </div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Dialog
                                    open={openEditModal}
                                    onClose={handleCloseEditModal}
                                    maxWidth="sm"
                                    fullWidth
                                    >
                                    <DialogTitle>Edit IFSC Code</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                        label="IFSC Code"
                                        value={ifscCode}
                                        onChange={(e) => setIfscCode(e.target.value)}
                                        style={{marginTop:"10px"}}
                                        fullWidth
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseEditModal} color="primary">
                                        Cancel
                                        </Button>
                                        <Button onClick={handleSaveEdit} color="primary">
                                        Save
                                        </Button>
                                    </DialogActions>
                                </Dialog>


                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">

                                        <Stack direction='row' spacing={8.2}>

                                            <div className="details-label">Branch Name</div>
                                            <div className="details-value">Wagholi</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box className='section-title-box'>

                                        <h2 className="section-title mb-2">Active Autopay mandates</h2>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Stack
                                        direction="row"
                                        spacing={3}
                                        sx={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h2 className="section-title">Autopay via UPI</h2>
                                        <IconButton
                                            className="menu-button"
                                            size="small"
                                            onClick={handleMenuOpen1}
                                        >
                                            <MoreVert fontSize="small" />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorElAutoPayMenu}
                                            open={Boolean(anchorElAutoPayMenu)}
                                            onClose={handleMenuClose1}
                                            anchorOrigin={{
                                                vertical: 'bottom', // Dropdown appears below the MoreVert icon
                                                horizontal: 'right', // Center aligned horizontally
                                            }}
                                            transformOrigin={{
                                                vertical: 'top', // Dropdown originates from the top
                                                horizontal: 'right', // Dropdown aligns its center with the anchor
                                            }}
                                        >
                                            <MenuItem onClick={handleAddAutopay}>
                                                <Box display="flex" alignItems="center">
                                                    {/* <div className="square"></div> */}
                                                    <EditIcon />
                                                    <Typography onClick={handleClickOpen}> Make Primary</Typography>
                                                </Box>
                                            </MenuItem>
                                            <Divider className="custom-hr" />
                                            <MenuItem onClick={handleEditAutopay}>
                                                <Box display="flex" alignItems="center">
                                                    {/* <div className="square"></div> */}
                                                    <DeleteIcon />
                                                    <Typography>Delete</Typography>
                                                </Box>
                                            </MenuItem>
                                        </Menu>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={12}>
                                            <div className="details-label">Linked to</div>
                                            <div className="details-value">Mutual Fund SIPs</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={10.5}>
                                            <div className="details-label">Created on</div>
                                            <div className="details-value">30 Sep '24</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">
                                        <Stack direction='row' spacing={15}>
                                            <div className="details-label">Status</div>
                                            <div className="details-value">Approved</div>
                                        </Stack>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="details-row">

                                        <Stack direction='row'
                                            // spacing={10.4}
                                            spacing={{ xs: 12.6, md: 10.4 }}
                                        >

                                            <div className="details-label">Autopay ID</div>

                                            {/* className="details-value" */}

                                            <div className="transaction-value2">
                                                ABCD123456789012345678912
                                                <Tooltip title={tooltipText} arrow>
                                                    <IconButton
                                                        className="copy-button"
                                                        size="small"
                                                        onClick={() => handleCopy(autopayId)}
                                                    >
                                                        <ContentCopy fontSize="small" className="copyicon" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Stack>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* 
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Dialog
                open={open}
                onClose={handleClose}
                className="autopay-dialog"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle className="dialog-title">
                    <Box className="title-container">
                        <InfoIcon className="info-icon" />
                        <Typography variant="h6" className="title-text">
                            Important
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Typography variant="h5" className="main-question">
                        Are you sure you want to change your primary AutoPay?
                    </Typography>
                    <Typography className="info-text">
                        Your existing instalments will continue being debited from the older AutoPay.
                    </Typography>
                    <Typography className="info-text">
                        Your new instalments will be debited from your new primary AutoPay.
                    </Typography>
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button
                        onClick={handleClose}
                        className="cancel-button"
                        variant="text"
                    >
                        CANCEL
                    </Button>
                    <Button
                        onClick={handleClose}
                        className="confirm-button"
                        variant="text"
                    >
                        MAKE PRIMARY
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

