import React, { useState } from "react";
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    Stack,
    Button,
    Autocomplete,
    InputAdornment,
    Snackbar,
    Alert
} from '@mui/material';
import MoreVert from "@mui/icons-material/MoreVert";
import InfoIcon from '@mui/icons-material/Info';

import { TextField, Avatar } from "@mui/material";



import { ChevronRight } from '@mui/icons-material';
import Header2 from "../home/Appbar2";

import "../../assets/css/funddetails.css"
import "../../assets/css/onbording.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';

import hdfcIcon from "../../assets/images/hdfc.svg"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Edit, ContentCopy } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SbiIcon from "../../assets/images/sbi-icon.svg"
import HdfcIcon from "../../assets/images/hdfc.svg"
import IcicIcon from "../../assets/images/icic-bank.svg"
import KokatIcon from "../../assets/images/kokat.svg"
import AxisBankIcon from "../../assets/images/axis-bank.svg"
import PunjabBankIcon from "../../assets/images/punjab-bank.svg"
import AbhyudayaBankIcon from "../../assets/images/Abhyudaya-bank.svg"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ClearIcon from '@mui/icons-material/Clear';

export default function TransactionList() {

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpen = (event, index) => {
        setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleInvestClick = () => {
        console.log("Invest action triggered");
        handleMenuClose();
    };

    const handleRedeemClick = () => {
        console.log("Redeem action triggered");
        handleMenuClose();
    };

    const handleViewTransactionsClick = () => {
        console.log("View Transactions action triggered");
        handleMenuClose();
    };

    const handleStartSwpClick = () => {
        console.log("Start SWP action triggered");
        handleMenuClose();
    };

    const handleStartStpClick = () => {
        console.log("Start STP action triggered");
        handleMenuClose();
    };

    const banks = [
        { id: 1, name: "HDFC BANK", number: "XXXX1234", logo: hdfcIcon },
        // Add more bank objects if needed
    ];

    const banks2 = [
        { id: 1, name: "Add Another Bank", },
        // Add more bank objects if needed
    ];

    // -------------//

    const [anchorElAutoPayMenu, setAnchorElAutoPayMenu] = useState(null);

    const handleMenuOpen1 = (event) => {
        setAnchorElAutoPayMenu(event.currentTarget);
    };

    const handleMenuClose1 = () => {
        setAnchorElAutoPayMenu(null);
    };

    const handleAddAutopay = () => {
        console.log("Add Autopay action triggered");
        handleMenuClose();
    };

    const handleEditAutopay = () => {
        console.log("Edit Autopay action triggered");
        handleMenuClose();
    };

    const handleDeleteAutopay = () => {
        console.log("Delete Autopay action triggered");
        handleMenuClose();
    };

    const handlePauseAutopay = () => {
        console.log("Pause Autopay action triggered");
        handleMenuClose();
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const banksinput = [
        { name: "AXIS BANK", logo: AxisBankIcon },
        { name: "ICICI BANK LIMITED", logo: IcicIcon },
        { name: "STATE BANK OF INDIA", logo: SbiIcon },
        { name: "KOTAK MAHINDRA BANK LIMITED", logo: KokatIcon },
    ];

    // const [ifscCode, setIfscCode] = useState(""); // IFSC code input value
    // const [showDetails, setShowDetails] = useState(false); // To toggle bank details
    // const [toastOpen, setToastOpen] = useState(false); // For Snackbar state


    // const handleConfirmClick = () => {
    //     setShowDetails(true); // Show bank details
    //     setToastOpen(true); // Open toast

    // };

    // const handleToastClose = () => {
    //     setToastOpen(false); // Close toast
    // };


    const [inputValue, setInputValue] = useState("");
    const [showDetails, setShowDetails] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setShowDetails(!!value.trim()); // Show details if input is not empty
    };

    const handleConfirm = () => {
        setToastOpen(true); // Show toast message
    };

    const handleToastClose = () => {
        setToastOpen(false);
    };

    return (

        <>

            <Header2 />

            <div className="wrapper">

                <Box className="bank-mt-17">
                </Box>


                <Grid container spacing={2} className="transaction-container">

                    <Grid item xs={12} md={12}>
                        <Typography className='bank-text-head'> Bank Account Details </Typography>
                    </Grid>

                </Grid>

                <Grid container spacing={2} className="transaction-container">


                    <Grid item xs={12} md={6} >
                        <div className="bank-table-container">
                            <TableContainer>
                                <Table>

                                    <TableBody>
                                        {banks.map((bank, index) => (

                                            <TableRow
                                                className="bank-row-bg"
                                                key={bank.id}
                                            >

                                                <TableCell >
                                                    <Stack direction='row' spacing={2}>
                                                        <img src={bank.logo} alt={`${bank.name} Logo`} className="bank-logo" />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell >{bank.number}</TableCell>

                                                <TableCell >
                                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    {menuAnchorEl?.index === index && (

                                                        <Menu
                                                            anchorEl={menuAnchorEl.anchorEl}
                                                            open={Boolean(menuAnchorEl)}
                                                            onClose={handleMenuClose}
                                                        >

                                                            <MenuItem onClick={handleInvestClick}>

                                                                <Box display="flex" alignItems="center">
                                                                    {/* <div className="square"></div> */}
                                                                    <DeleteIcon />
                                                                    <Typography>Delete</Typography>
                                                                </Box>

                                                            </MenuItem>




                                                        </Menu>
                                                    )}
                                                </TableCell>

                                            </TableRow>

                                        ))}
                                    </TableBody>

                                    <TableBody>

                                        {/* {banks2.map((bank, index) => (


                                            <TableRow
                                                key={bank.id}
                                            >

                                                <TableCell
                                                >

                                                    <Stack direction='row' spacing={2}>
                                                        <AddCircleOutlineIcon />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell ></TableCell>

                                                <TableCell></TableCell>

                                            </TableRow>

                                        ))} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>



                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>

                        <div className="details-container">
                            <header >


                                <div>

                                    <div className='d-flex align-items-center justify-content-between'>

                                        <h5 className="bank-title">  Enter new IFSC code    </h5>

                                        <ClearIcon className="edit-icon" />

                                    </div>
                                    <p
                                    >
                                        Please ensure correct bank information for successful transactions.
                                    </p>
                                </div>
                            </header>

                            <Grid container spacing={2}>



                                <Grid item xs={12} sm={12} md={12}>

                                    <TextField
                                        // value={ifscCode}
                                        value={inputValue}
                                        // onChange={(e) => setIfscCode(e.target.value)}
                                        onChange={handleInputChange}


                                        fullWidth
                                        placeholder="Type the code or search branch"
                                        variant="outlined"

                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={SbiIcon} alt="sbi-icon" />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />



                                </Grid>

                                {/* Bank Details UI */}

                                {showDetails && (

                                    <>

                                        <Box mt={4} ml={2}>

                                            <Box>
                                                <h5 className="bank-verify-title"> Bank Details </h5>
                                            </Box>

                                            <Box mt={3} className="details-container">

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <div className="details-row">
                                                            <Stack direction="row" spacing={14}>
                                                                <div className="details-label">Bank Name</div>
                                                                <div className="details-value">HDFC BANK</div>
                                                            </Stack>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <div className="details-row">
                                                            <Stack direction="row" spacing={12.5}>
                                                                <div className="details-label">Bank Address</div>
                                                                <div className="details-value">Wagholi, Pune</div>
                                                            </Stack>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <div className="details-row">
                                                            <Stack direction="row" spacing={12.5}>
                                                                <div className="details-label">Branch Name</div>
                                                                <div className="details-value">Wagholi</div>
                                                            </Stack>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                        </Box>

                                    </>


                                )}




                                <Grid item xs={12} sm={12} md={12}>
                                    <Button
                                        variant="contained"
                                        className="open-camera"
                                        // disabled={!ifscCode.trim()} 
                                        // onClick={handleConfirmClick}
                                        onClick={handleConfirm}
                                    >
                                        Confirm
                                    </Button>
                                </Grid>

                                {/* Snackbar for toast message */}
                                <Snackbar
                                    open={toastOpen}
                                    autoHideDuration={3000}
                                    onClose={handleToastClose}
                                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                >
                                    <Alert onClose={handleToastClose} icon={<CheckCircleIcon fontSize="inherit" />} sx={{
                                        backgroundColor: "#fff", // White background
                                        border: "1px solid #e0e0e0", // Light gray border
                                        color: "#4caf50", // Text color matching the icon
                                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                                        padding: "8px 16px",
                                        alignItems: "center", // Aligns icon and text vertically
                                        borderLeft: "5px solid rgb(76, 175, 139)", // Green left border
                                    }} severity="success" >
                                        IFSC Updated successfully
                                    </Alert>
                                </Snackbar>


                            </Grid>

                        </div>

                    </Grid>


                </Grid>

            </div>


        </>

    );
}

