import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  AppBar,
  Toolbar,
  Box,
  Button,
  Stack,
  IconButton,
  Badge,
  InputBase,
  Menu,
  Tooltip,
  MenuItem,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MisshikaLogo from "../../assets/images/Logo.svg";
import Notification from "../../assets/images/notification.svg";
import Notification2 from "../../assets/images/notification2.svg";
import Shoppingcart from "../../assets/images/shoppingcart.svg";
import Profile from "../../assets/images/profileicon.svg";
import Bookmark from "../../assets/images/bookmark.svg";
import LoginPopup from "./LoginPopup";
import AppBarMobile2 from "./AppbarMobile2";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import "../../assets/css/header.css";
import "../../assets/css/Login.css";
import Settings from "../../assets/images/Settings.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AllOrder from "../../assets/images/AllOrder.svg";
import BankDetails from "../../assets/images/BankDetails.svg";
import CustomerSupport from "../../assets/images/CustomerSupport.svg";
import Reports from "../../assets/images/Reports.svg";
import { useNavigate } from "react-router-dom";
import NotificationPanel from "./Notification.js";
import { getLocalStorageUserData, logoutUser } from "../../utils/userData.js";

export default function Header2() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const notificationRef = useRef(null);

  const userData = getLocalStorageUserData();

  const handleAllOrdersClick = () => {
    navigate("/allorders");
  };
  const handleSetting = () => {
    navigate("/userdetails");
  };

  const handleLoginPopupOpen = () => {
    setOpen(true);
  };

  const handleLoginPopupClose = () => {
    setOpen(false);
  };

  const isActiveLink = (path) => (location.pathname === path ? "selected" : "");

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleProfileMenuClose();
  };

  const handleMoreMenuOpen = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreMenuClose = () => {
    setMoreAnchorEl(null);
  };

  const toggleNotification = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        !event.target.closest(".nav-link") // Ensure the bell icon click doesn't close it
      ) {
        setNotificationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Desktop Content */}

      <div className="desktop-content">
        <AppBar position="static" className="header-container">
          <Toolbar className="toolbar">
            {/* Logo */}
            <div className="logo-mishika">
              <Link to="/">
                <img src={MisshikaLogo} alt="Misshika Capital Logo" />
              </Link>
            </div>
            {/* Navigation Links */}
            <Box className="nav-links">
              <Button
                variant="text"
                component={Link}
                to="/explore"
                className={`nav-link ${isActiveLink("/explore")}`}
              >
                Explore
              </Button>
              <Button
                variant="text"
                component={Link}
                to="/dashboard"
                className={`nav-link ${isActiveLink("/dashboard")}`}
              >
                Dashboard
              </Button>
              {/* More Dropdown */}

              {/* <Button
                                variant="text"
                                className="nav-link"
                                onClick={handleMoreMenuOpen}
                            >
                                More
                            </Button>

                            <Menu
                                anchorEl={moreAnchorEl}
                                open={Boolean(moreAnchorEl)}
                                onClose={handleMoreMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem component={Link} to="/pan-details" onClick={handleMoreMenuClose}>
                                    PAN Details
                                </MenuItem>
                                <MenuItem component={Link} to="/occupation-form" onClick={handleMoreMenuClose}>
                                    Occupation
                                </MenuItem>
                            
                            </Menu> */}
            </Box>
            {/* Search Bar */}
            <Box
              className="search-bar w-30"
              sx={{
                display: "flex",
                alignItems: "center",
                mx: 2,
                px: 2,
                border: "1px solid rgba(237, 237, 237, 1)",
                borderRadius: "30px",
              }}
            >
              <SearchIcon sx={{ color: "gray", mr: 1 }} />
              <InputBase placeholder="Search" />
            </Box>
            {/* Icons */}
            <Stack direction="row" alignItems="center" spacing={2}>
              {/* Notification Icon with Badge */}
              <Tooltip title="Notifications">
                <IconButton color="inherit" onClick={toggleNotification}>
                  <Badge badgeContent={0} color="primary">
                    <img src={Notification} alt="notification icon" />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* <IconButton color="inherit">
                                <Badge badgeContent={3} color="primary">
                                    <img src={Notification2} alt="notification icon" />
                                </Badge>
                            </IconButton> */}
              <Tooltip title="Watchlists">
                <IconButton color="inherit">
                  <Badge
                    badgeContent={0}
                    color="primary"
                    component={Link}
                    to="/watchlist"
                  >
                    <img src={Bookmark} alt="notification icon" />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* Cart Icon with Badge */}
              <Tooltip title="My Cart">
                <IconButton color="inherit">
                  <Badge
                    badgeContent={1}
                    color="primary"
                    component={Link}
                    to="/mycart"
                  >
                    <img src={Shoppingcart} alt="shopping cart icon" />
                  </Badge>
                </IconButton>
              </Tooltip>
              {/* Account Icon */}
              <Tooltip title="Profile">
                <IconButton color="inherit" onClick={handleProfileMenuOpen}>
                  <img src={Profile} alt="profile icon" />
                </IconButton>
              </Tooltip>

              <Menu
                // className='myprofile-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
                // anchorOrigin={{
                //     vertical: "top",
                //     horizontal: "right",
                // }}
                // transformOrigin={{
                //     vertical: "top",
                //     horizontal: "right",
                // }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    backgroundColor: "#fff",
                  }}
                >
                  <Box
                    // padding={2}
                    className="profile-card-padding"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="h6" fontWeight="600">
                        {userData?.name}
                      </Typography>
                      <Typography className="text-gray">
                        {userData?.email}
                      </Typography>
                    </Box>
                    <IconButton size="small">
                      <img
                        src={Settings}
                        alt="Settings"
                        style={{ width: 20, height: 20 }}
                        onClick={handleSetting}
                      />
                    </IconButton>
                  </Box>

                  <List
                    sx={{
                      borderTop: "1px solid #EDEDED",
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 2,
                    }}
                  >
                    <ListItem button onClick={handleAllOrdersClick}>
                      <ListItemIcon>
                        <img
                          src={AllOrder}
                          alt="AllOrder"
                          style={{ width: 20, height: 20 }}
                        />
                      </ListItemIcon>
                      {/* <Box
                                                sx={{ flex: 1, borderBottom: "1px solid #EDEDED" }}
                                            > */}
                      <Link to="/allorders" className="profile-link">
                        <ListItemText
                          className="profile-list"
                          primary="All Orders"
                        />
                      </Link>
                      {/* </Box> */}

                      <ListItemSecondaryAction>
                        <ChevronRightIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                      <ListItemIcon>
                        <img
                          src={BankDetails}
                          alt="BankDetails"
                          style={{ width: 20, height: 20 }}
                        />
                      </ListItemIcon>
                      <Link to="/bankdetails" className="profile-link">
                        <ListItemText
                          to="/bankdetails"
                          primary="Bank Details"
                          className="profile-list"
                        />
                      </Link>

                      <ListItemSecondaryAction>
                        <ChevronRightIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                      <ListItemIcon>
                        <img
                          src={CustomerSupport}
                          alt="CustomerSupport"
                          style={{ width: 20, height: 20 }}
                        />
                      </ListItemIcon>
                      <Link to="/helpsupport" className="profile-link">
                        <ListItemText
                          primary="24x7 Customer Support"
                          className="profile-list"
                        />
                      </Link>
                      <ListItemSecondaryAction>
                        <ChevronRightIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem button>
                      <ListItemIcon>
                        <img
                          src={Reports}
                          alt="Reports"
                          style={{ width: 20, height: 20 }}
                        />
                      </ListItemIcon>
                      <Link to="/coming-soon" className="profile-link">
                        <ListItemText
                          primary="Reports"
                          className="profile-list"
                        />
                      </Link>
                      <ListItemSecondaryAction>
                        <ChevronRightIcon />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </List>

                  <Box
                    // mt={1}
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "end",
                      backgroundColor: "rgba(245, 245, 245, 1)",
                      padding: "8px 16px",
                      borderRadius: "4px",
                    }}
                  >
                    <button
                      className="transparent-button"
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Log out
                    </button>
                  </Box>
                </Box>

                {/* <MenuItem onClick={handleProfileMenuClose} component={Link} to="/userdetails">
                                    My Account
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
              </Menu>
            </Stack>
            {/* Login Popup */}
            <LoginPopup open={open} onClose={handleLoginPopupClose} />

            {/* Notification */}
            <NotificationPanel
              isOpen={isNotificationOpen}
              onClose={toggleNotification}
              notificationRef={notificationRef}
            />
          </Toolbar>
          {/* <Divider /> */}
        </AppBar>
      </div>

      {/* Mobile Content */}

      <div className="mobile-content">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <AppBarMobile2 />
            {/* <div className="logo-mishika-mobile">
                            <Link to="/">
                                <img src={MisshikaLogo} alt="Misshika Capital Logo" />
                            </Link>
                        </div> */}
          </Stack>

          <Box
            className="search-bar w-30"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              mx: 2,
              px: 2,
              border: "1px solid rgba(237, 237, 237, 1)",
              borderRadius: "30px",
            }}
          >
            <SearchIcon sx={{ color: "gray", mr: 1 }} />
            <InputBase placeholder="Search" />
          </Box>

          <Tooltip title="Profile">
            <IconButton color="inherit" onClick={handleProfileMenuOpen}>
              <img src={Profile} alt="profile icon" />
            </IconButton>
          </Tooltip>

          {/* <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleProfileMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem onClick={handleProfileMenuClose} component={Link} to="/userdetails">
                            My Account
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>

                    </Menu> */}

          {/* <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleProfileMenuClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >

                        <Box
                            sx={{
                                width: "100%",

                                maxWidth: 300,
                                backgroundColor: "#fff",
                                borderRadius: 3,
                                padding: 2,
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography variant="h6" fontWeight="bold">
                                        Anand Arjun Rao
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        anandrao@gmail.com
                                    </Typography>
                                </Box>
                                <IconButton size="small">
                                    <img
                                        src={Settings}
                                        alt="Settings"
                                        style={{ width: 20, height: 20 }}
                                    />
                                </IconButton>
                            </Box>

                            <List
                                sx={{
                                    borderTop: "1px solid #EDEDED",
                                    paddingTop: 0,
                                    marginTop: 2,
                                }}
                            >
                                <ListItem button onClick={handleAllOrdersClick}>
                                    <ListItemIcon>
                                        <img
                                            src={AllOrder}
                                            alt="AllOrder"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    </ListItemIcon>
                                    <Box
                                        sx={{ flex: 1, borderBottom: "1px solid #EDEDED" }}
                                    >
                                        <ListItemText
                                            primary="All Orders"
                                            sx={{
                                                color: "#3E4154",
                                                fontSize: "16px",
                                                maxWidth: "244px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        />
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <img
                                            src={BankDetails}
                                            alt="BankDetails"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    </ListItemIcon>
                                    <Box
                                        sx={{ flex: 1, borderBottom: "1px solid #EDEDED" }}
                                    >
                                        <ListItemText
                                            primary="Bank Details"
                                            sx={{
                                                color: "#3E4154",
                                                fontSize: "16px",
                                                maxWidth: "244px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        />
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <img
                                            src={CustomerSupport}
                                            alt="CustomerSupport"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    </ListItemIcon>
                                    <Box
                                        sx={{ flex: 1, borderBottom: "1px solid #EDEDED" }}
                                    >
                                        <ListItemText
                                            primary="24x7 Customer Support"
                                            sx={{
                                                color: "#3E4154",
                                                fontSize: "16px",
                                                maxWidth: "244px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                            }}
                                        />
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <img
                                            src={Reports}
                                            alt="Reports"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    </ListItemIcon>

                                    <ListItemText
                                        primary="Reports"
                                        sx={{
                                            color: "#3E4154",
                                            fontSize: "16px",
                                            maxWidth: "244px",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    />

                                    <ListItemSecondaryAction>
                                        <ChevronRightIcon />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>

                            <Box
                                mt={1}
                                sx={{
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent: "space-between",
                                    backgroundColor: "#EDEDED",
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    width: "100%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "#3E4154",
                                        fontWeight: "bold",
                                        flex: 1,
                                        textAlign: "right",
                                    }}
                                >
                                    Log out
                                </Typography>
                            </Box>
                        </Box>


                    </Menu> */}
        </Stack>
      </div>
    </>
  );
}
