import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "../assets/css/funddetails.css";
import "../assets/css/common.css";
import {
  Typography,
  Box,
  Divider,
  Button,
  Grid,
} from "@mui/material";

const chartData = {
  "1M": [
    { value: 10 },
    { value: 50 },
    { value: 65 },
    { value: 15 },
    { value: 50 },
    { value: 10 },
    { value: 60 },
    { value: 15 },
    { value: 55 },
    { value: 40 },
    { value: 20 },
    { value: 90 },
    { value: 15 },
    { value: 25 },
  ],
  "6M": [
    { value: 5 },
    { value: 15 },
    { value: 20 },
    { value: 10 },
    { value: 25 },
    { value: 15 },
    { value: 55 },
    { value: 40 },
    { value: 20 },
    { value: 90 },
    { value: 15 },
    { value: 15 },
    { value: 50 },
    { value: 10 },
    { value: 60 },
  ],
  "1Y": [
    { value: 8 },
    { value: 12 },
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
    { value: 10 },
    { value: 60 },
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
  ],
  "3Y": [
    { value: 7 },
    { value: 14 },
    { value: 21 },
    { value: 28 },
    { value: 35 },
    { value: 15 },
    { value: 55 },
    { value: 40 },
    { value: 20 },
    { value: 90 },
    { value: 15 },
  ],
  "5Y": [
    { value: 3 },
    { value: 9 },
    { value: 15 },
    { value: 21 },
    { value: 27 },
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
  ],
  All: [
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
    { value: 1 },
    { value: 4 },
    { value: 7 },
    { value: 10 },
    { value: 13 },
    { value: 18 },
    { value: 22 },
    { value: 27 },
    { value: 15 },
    { value: 50 },
  ],
};

const SBIPSUChart = () => {
  const [timeRange, setTimeRange] = useState("1M");

  // Handler for button click
  const handleTimeRangeChange = (range) => {
    setTimeRange(range);
  };

  return (
    <div className="chart-container">
      {/* <div>
        <img src={Sbiicon} alt="sbiicon" height="64px" width="64px" />
      </div>

      <Typography mt={2} mb={2} variant="h5" className="chart-title ">
        SBI PSU Direct Plan Growth
      </Typography> */}
      <Box sx={{ width: "100%" }}>
        {/* Title and Percentage */}
        <Typography variant="h4" fontWeight="500" sx={{ mb: 1 }}>
          40%{" "}
          <Typography variant="body2" component="span">
            {timeRange}
          </Typography>
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          <span className="text-green"> +1.11% </span>1D
        </Typography>

        {/* Line Chart */}
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={chartData[timeRange]} // Dynamically show data based on selected time range
            margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
          >
            <Line
              type="monotone"
              dataKey="value"
              stroke="rgba(0, 142, 247, 1)"
              strokeWidth={4}
              dot={false} // No points
            />
          </LineChart>
        </ResponsiveContainer>

        {/* Time Range Buttons */}
        <Box className="toggle-container" justifyContent="center">
          <Box sx={{ mt: 2, gap: "10px" }} variant="outlined" size="small">
            {["1M", "6M", "1Y", "3Y", "5Y", "All"].map((range) => (
              <Button
                key={range}
                onClick={() => handleTimeRangeChange(range)}
                className={`chart-toggle-button ${
                  timeRange === range ? "active" : "inactive"
                }`}
              >
                {range}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>

      <Box className="accordian-funds-bg">
        <Box display="flex" justifyContent="flex-start" className="folio-box">
          <Typography className="folio-num">
            Folio Number: <span> 123456789012 </span>
          </Typography>
        </Box>
        <Divider />

        {/* <Box display="flex" justifyContent="space-around" mt={3}>

                    <Box>
                        <Typography variant="h5" >₹5,219</Typography>
                        <Typography variant="body2" color="textSecondary">
                            Current Value</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5" >₹5000</Typography>
                        <Typography variant="body2" color="textSecondary">
                            Invested Value</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5" className='text-green' >
                            +₹295 (6.00%)</Typography>
                        <Typography variant="body2" color="textSecondary">
                            Total Returns</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5" className='text-red'>
                            -₹58 (1.11%)</Typography>
                        <Typography variant="body2" color="textSecondary">
                            1D Returns</Typography>
                    </Box>

                    <Box>
                        <Typography variant="h5" >
                            13.495</Typography>
                        <Typography variant="body2" color="textSecondary">
                            Units</Typography>
                    </Box>

                </Box> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box className="grow-card-custom">
              <Grid container spacing={2} className="justify-between">
                <Grid item>
                  <Typography
                    variant="h5"
                    // className="investemnt-card-head2"
                  >
                    ₹5,219
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Current Value
                  </Typography>
                </Grid>

                {/* Vertical line */}

                {/* <Grid item>
                                    <div
                                        style={{
                                            height: '120%',
                                            width: '1px',
                                            backgroundColor: '#EDEDED',
                                            margin: '0 16px', // Spacing between the vertical line and the grid items
                                        }}
                                    />
                                </Grid> */}

                <Grid item>
                  <Typography
                    // className="investemnt-card-head2"
                    variant="h5"
                  >
                    ₹5,000
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Invested Value
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className="text-green">
                    +₹295 (6.00%
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Total Returns
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className="text-red">
                    -₹58 (1.11%)
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    1D Returns
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    // className='investment-1dreturn'
                  >
                    13.495
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Units
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="chart-footer" mt={4}>
        <span>NAV: ₹40</span>
        <span>Min. SIP Amount: ₹500</span>
      </Box>
    </div>
  );
};

export default SBIPSUChart;
