import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Box,
  Typography,
  Tabs,
  Tab,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EventIcon from "@mui/icons-material/Event";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Slider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { calculateSip, calculateLumpsum } from "../actions/calculatorAction";
import debounce from "lodash.debounce";
import { number } from "yup";

export default function DashboardInvestment() {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const lumpsumData = useSelector((state) =>
    tabValue === 0
      ? state.calculator.sipCalculatorData
      : state.calculator.lumpsumCalculatorData
  );
  const [amount, setAmount] = useState(500);
  const [timePeriod, setTimePeriod] = useState(1);
  const [returnRate, setReturnRate] = useState(10);

  // Separate state for SIP and Lump Sum
  const [sipAmount, setSipAmount] = useState(500);
  const [sipTimePeriod, setSipTimePeriod] = useState(1);
  const [sipReturnRate, setSipReturnRate] = useState(10);

  const [lumpsumAmount, setLumpsumAmount] = useState(500);
  const [lumpsumTimePeriod, setLumpsumTimePeriod] = useState(1);
  const [lumpsumReturnRate, setLumpsumReturnRate] = useState(10);

  const handleAmountChange = (event, newValue) => {
    setAmount(newValue);
  };

  const handleTimePeriodChange = (event, newValue) => {
    setTimePeriod(newValue);
  };

  const handleReturnRateChange = (event, newValue) => {
    setReturnRate(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Reset input values when switching tabs
  useEffect(() => {
    if (tabValue === 0) {
      setSipAmount(500);
      setSipTimePeriod(1);
      setSipReturnRate(10);
    } else {
      setLumpsumAmount(500);
      setLumpsumTimePeriod(1);
      setLumpsumReturnRate(10);
    }
  }, [tabValue]);

  // Debounced API call
  const debouncedCalculation = useCallback(
    debounce(() => {
      if (tabValue === 0) {
        dispatch(
          calculateSip({
            amount: Number(sipAmount),
            timePeriod: Number(sipTimePeriod) * 12,
            returnRate: Number(sipReturnRate),
          })
        );
      } else {
        dispatch(
          calculateLumpsum({
            amount: Number(lumpsumAmount),
            years: Number(lumpsumTimePeriod),
            returns: Number(lumpsumReturnRate),
          })
        );
      }
    }, 500),
    [sipAmount, sipTimePeriod, sipReturnRate, lumpsumAmount, lumpsumTimePeriod, lumpsumReturnRate, tabValue]
  );

  useEffect(() => {
    debouncedCalculation();
    return debouncedCalculation.cancel;
  }, [sipAmount, sipTimePeriod, sipReturnRate, lumpsumAmount, lumpsumTimePeriod, lumpsumReturnRate, tabValue, debouncedCalculation]);

  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };

  const formatCurrency = (value) => {
    if (typeof value === "string") {
      value = parseFloat(value?.replace(/,/g, ""));
    }

    if (isNaN(value)) {
      console.error("Invalid value for currency formatting:", value);
      return "Invalid value";
    }

    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(value);
  };

  return (
    <div className="wrapper">
      <Grid container textAlign="start" spacing={2}>
        <Grid item xs={12} md={8.5} sx={{ mb: 6 }}>
          <Grid container textAlign="start" spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                {" "}
                <Typography variant="h6" className="explore-sections-head">
                  {" "}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box>
                      {" "}
                      <Typography
                        variant="h6"
                        className="explore-sections-head"
                      >
                        {" "}
                        Return calculator{" "}
                      </Typography>
                    </Box>
                    {/* <Box className="alllink"> <a>All Mutual Funds</a></Box> */}
                  </Grid>{" "}
                </Typography>
              </Box>
              {/* <Box className="alllink"> <a>All Mutual Funds</a></Box> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="watchlist-card">
                <Box className="card-content">
                  <Box sx={{ mb: 2, p: 2 }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      textColor="primary"
                      indicatorColor="primary"
                      variant="fullWidth"
                      className="sip-tabs calculator-tabs"
                    >
                      <Tab label="Monthly SIP" />
                      <Tab label="One-Time" />
                    </Tabs>
                  </Box>

                  <div className="slider-container">
                    {/* {[
                      {
                        label: "Amount",
                        value: amount,
                        setter: setAmount,
                        min: 1000,
                        max: 10000,
                        step: 500,
                        unit: "₹",
                      },
                      {
                        label: "Time Period",
                        value: timePeriod,
                        setter: setTimePeriod,
                        min: 1,
                        max: 30,
                        step: 1,
                        unit: "Yr",
                      },
                      {
                        label: "Return Rate",
                        value: returnRate,
                        setter: setReturnRate,
                        min: 1,
                        max: 40,
                        step: 1,
                        unit: "%",
                      },
                    ].map(({ label, value, setter, min, max, step, unit }) => (
                      <Box key={label} className="slider-box">
                        <Box display="flex" justifyContent="space-between">
                          <Typography className="label">{label}</Typography>
                          <TextField
                            className="value-box sip-aamount-box return-textfield"
                            value={`${unit} ${value}`}
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true,
                            }}
                            variant="standard"
                            size="small"
                          />
                        </Box>
                        <Box className="slider-wrapper">
                          <Slider
                            value={value}
                            min={min}
                            max={max}
                            step={step}
                            onChange={handleSliderChange(setter)}
                            className="slider"
                          />
                        </Box>
                      </Box>
                    ))} */}
                    {/* Amount Slider */}
                    <Box className="slider-box">
                      <Box display="flex" justifyContent="space-between">
                        <Typography className="label">Amount</Typography>
                        <TextField
                          className="value-box sip-aamount-box return-textfield"
                          // value={`₹ ${amount}`}
                          value={tabValue === 0 ? `₹ ${sipAmount}` : `₹ ${lumpsumAmount}`}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          variant="standard"
                          size="small"
                        />
                      </Box>
                      <Box className="slider-wrapper">
                        <Slider
                          value={tabValue === 0 ? sipAmount : lumpsumAmount}
                          min={1000}
                          max={10000}
                          step={500}
                          onChange={(e, v) => tabValue === 0 ? setSipAmount(v) : setLumpsumAmount(v)}
                          className="slider"
                        />
                      </Box>
                    </Box>

                    {/* Time Period Slider */}
                    <Box className="slider-box">
                      <Box display="flex" justifyContent="space-between">
                        <Typography className="label">Time Period</Typography>
                        <TextField
                          className="value-box sip-aamount-box return-textfield"
                          // value={`${timePeriod} Yr`}
                          value={tabValue === 0 ? `${sipTimePeriod} Yr` : `${lumpsumTimePeriod} Yr`}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          variant="standard"
                          size="small"
                        />
                      </Box>
                      <Box className="slider-wrapper">
                        <Slider
                          value={tabValue === 0 ? sipTimePeriod : lumpsumTimePeriod}
                          min={1}
                          max={5}
                          step={1}
                          onChange={(e, v) => tabValue === 0 ? setSipTimePeriod(v) : setLumpsumTimePeriod(v)}
                          className="slider"
                        />
                      </Box>
                    </Box>

                    {/* Return Slider */}
                    <Box className="slider-box">
                      <Box display="flex" justifyContent="space-between">
                        <Typography className="label">Return Rate</Typography>
                        <TextField
                          className="value-box sip-aamount-box return-textfield"
                          // value={`${returnRate} %`}
                          value={tabValue === 0 ? `${sipReturnRate} %` : `${lumpsumReturnRate} %`}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                          variant="standard"
                          size="small"
                        />
                      </Box>
                      <Box className="slider-wrapper">
                        <Slider
                          value={tabValue === 0 ? sipReturnRate : lumpsumReturnRate}
                          min={1}
                          max={40}
                          step={1}
                          onChange={(e, v) => tabValue === 0 ? setSipReturnRate(v) : setLumpsumReturnRate(v)}
                          className="slider"
                        />
                      </Box>
                    </Box>
                  </div>

                  <Divider className="custom-hr" sx={{ my: 2 }} />
                  <Box>
                    <Typography className="watchlist-textpadding">
                      Total investment of{" "}
                      {lumpsumData?.invested_amount
                        ? formatCurrency(lumpsumData?.invested_amount)
                        : "Calculating..."}{" "}
                    </Typography>
                    <Box className="d-flex justify-between watchlist-textpadding">
                      <Typography
                        sx={{
                          mb: 2,
                          mt: 2,
                          color: "#3E4154",
                          fontSize: "17px",
                          fontWeight: "600",
                        }}
                        className="d-flex "
                      >
                        Would have become:{" "}
                        {lumpsumData?.future_value
                          ? formatCurrency(lumpsumData?.future_value)
                          : "Calculating..."}{" "}
                        <span className="return-textfield">
                          {" "}
                          (+{lumpsumData?.returns}%){" "}
                        </span>
                         
                      </Typography>
                      {/* <IconButton>
                                                <ChevronRightIcon />
                                            </IconButton> */}
                    </Box>
                  </Box>
                  {/* <Divider className="custom-hr" sx={{ my: 2 }} /> */}

                  {/* <Box display="flex" justifyContent="space-between" sx={{ mb: 2, mt: 2 }} className='watchlist-textpadding'>
                                        <Button className='add-cartbtn' fullWidth sx={{ mr: 1 }}>Start SIP</Button>
                                    </Box> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
