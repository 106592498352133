import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, IconButton,
    InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EmailPopup = ({ open, handleClose }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    // Step 1: Mobile Input, Step 2: Mobile OTP, Step 3: Email Input, Step 4: Email OTP
    const [step, setStep] = useState(1);

    const handleMobileChange = (event) => {
        setMobileNumber(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1);
        setOtp(newOtp);
        if (value && index < otp.length - 1) focusInput(index + 1);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);
            focusInput(index - 1);
        }
    };

    const focusInput = (index) => {
        const input = document.querySelectorAll('input')[index];
        if (input) input.focus();
    };

    const handleSendOtp = () => {
        if (step === 1) {
            console.log("Mobile OTP sent to:", mobileNumber);
            setStep(2);
        } 
        // else if (step === 3) {
        //     console.log("Email OTP sent to:", email);
        //     setStep(4);
        // }
    };

    const handleVerifyOtp = () => {
        const otpValue = otp.join('');
        if (step === 2) {
            console.log("Verified Mobile OTP:", otpValue);
            setOtp(Array(6).fill('')); // Reset OTP for email verification
            // setStep(3);
        } 
        // else if (step === 4) {
        //     console.log("Verified Email OTP:", otpValue);
        //     // navigate('/setuppin');
        // }
    };

    const getTitle = () => {
        switch (step) {
            // case 1:
            //     return "Enter New Mobile Number";
            // case 2:
            //     return "Verify OTP";
            case 1:
                return "Enter New Email";
            case 2:
                return "Verify OTP";
            default:
                return "";
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '12px',
                    padding: '8px'
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 24px'
            }}>
                <Typography variant="h6" sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                    color: '#3E4154'
                }}>
                    {getTitle()}
                </Typography>
                <IconButton
                    onClick={handleClose}
                    sx={{ padding: 0 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ padding: '24px' }}>
                {/* {(step === 1 || step === 2) && (
                    <TextField
                        variant="outlined"
                        value={mobileNumber}
                        onChange={handleMobileChange}
                        disabled={step === 2}
                        fullWidth
                        // placeholder={step === 1 ? "Enter mobile number" : undefined}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +91
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            mb: step === 2 ? 2 : 0,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: 'rgba(245, 245, 245, 1)',
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                )} */}

                {(step === 1 || step === 2) && (
                    <TextField
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        disabled={step === 2}
                        fullWidth
                        placeholder={step === 3 ? "Enter email address" : undefined}
                        sx={{
                            mb: step === 2 ? 1 : 0,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: 'rgba(245, 245, 245, 1)',
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                )}

                {(step === 2) && (
                    <>
                        <Typography variant="body2" sx={{ mb: 2, color: '#1C1E23' }}>
                            Enter a 6-digit OTP sent to this {step === 2 ? 'Mobile Number' : 'Email'}
                        </Typography>

                        <Box display="flex" gap={1} sx={{ mb: 2, justifyContent: 'space-between' }}>
                            {otp.map((digit, index) => (
                                <TextField
                                    key={index}
                                    variant="standard"
                                    value={digit}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: {
                                            width: '40px',
                                            height: '40px',
                                            fontSize: '1.2rem',
                                            textAlign: 'center',
                                            color: '#000',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            borderBottom: '2px solid rgba(62, 65, 84, 1)',
                                        }
                                    }}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottom: 'none' },
                                        '& .MuiInput-underline:after': { borderBottom: 'none' },
                                        '& .MuiInputBase-input:focus': {
                                            borderBottom: '2px solid #0047FF',
                                            outline: 'none',
                                        },
                                    }}
                                />
                            ))}
                        </Box>

                        <Typography
                            variant="body2"
                            sx={{
                                textAlign: 'center',
                                color: '#3E4154'
                            }}
                        >
                            Didn't receive OTP? {' '}
                            <Button
                                onClick={handleSendOtp}
                                sx={{
                                    textTransform: 'none',
                                    padding: 0,
                                    minWidth: 'auto',
                                    color: '#0047FF',
                                    fontWeight: 400,
                                    '&:hover': {
                                        background: 'none'
                                    }
                                }}
                            >
                                Resend
                            </Button>
                        </Typography>
                    </>
                )}
            </DialogContent>

            <DialogActions
                // sx={{ padding: '0 24px 24px 24px' }}
                sx={{ justifyContent: 'center', pb: 3 }}
            >
                <Button
                    onClick={step === 2 ? handleVerifyOtp : handleSendOtp}
                    fullWidth
                    className='start-sipbtn'
                    sx={{
                        textTransform: 'none',
                        width: '40%',
                        height: "48px",
                        borderRadius: '8px'
                    }}
                >
                    {step === 2  ? 'Submit' : 'Send OTP'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EmailPopup;

