import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';
import '../assets/css/home.css'
import { Link } from 'react-router-dom';
import LoginPopup from '../pages/home/LoginPopup';

export default function Component() {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="cta-container">
            <Box className="cta-content">
                <Typography variant="subtitle1" className="cta-subtitle">
                    Ready to Grow Your Wealth?
                </Typography>
                <Typography variant="h3" className="cta-title">
                    Join thousands of investors and start your mutual fund journey today.
                </Typography>
            </Box>
            <Button variant="contained" color="primary" className="cta-button"
                // componet={Link} to='/coming-soon'
                component={Link}
                // to='coming-soon'
                onClick={handleClickOpen}
            >
                Get Started
            </Button>
            <LoginPopup open={open} onClose={handleClose} />
        </Box>
    );
}