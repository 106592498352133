import React, { useState } from 'react';
import {
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    Card,
    Divider,
    IconButton,
    InputAdornment,
} from "@mui/material";
import Header2 from "../pages/home/Appbar2";
import Fund1 from '../assets/images/birla.svg';
import SWP from '../assets/images/swpsystematic.svg';
import STP from '../assets/images/stp.svg';
import "../assets/css/common.css";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
 
function Startstp() {
    const [showDashboard, setShowDashboard] = useState(false); // State to toggle views
    const [isEditingInstallments, setIsEditingInstallments] = useState(false);
    const [installments, setInstallments] = useState(0);
    // Handle click on the edit/save icon for Installments
    const handleInstallmentsEditClick = () => {
        setIsEditingInstallments(!isEditingInstallments); // Toggle between edit and view mode
    };
    // Handle change in the Installments input value
    const handleInstallmentsInputChange = (event) => {
        setInstallments(event.target.value);
    };
    // Handle the Open Dashboard button click
    const handleOpenDashboard = () => {
        setShowDashboard(true); // Show the Dashboard view
    };
 
    // Handle back to STP Overview
    const handleBackToOverview = () => {
        setShowDashboard(false); // Show the original STP Overview content
    };
    // Handle click on the edit/save icon for SWP Date
    const handleSWPDateEditClick = () => {
        setIsEditingSWPDate(!isEditingSWPDate); // Toggle between edit and view mode
    };
 
    const [isEditingSWPDate, setIsEditingSWPDate] = useState(false);
    const [swpDate, setSwpDate] = useState(1);
    // Handle change in the SWP Date input value
    const handleSWPDateInputChange = (event) => {
        setSwpDate(event.target.value);
    };
 
    const initialValues = {
        amount: "",
    };
 
    const validationSchema = Yup.object({
        amount: Yup.string()
            .required("Amount is required")
            .matches(/^\d+$/, "Amount must be a valid number")
            .test("positive", "Amount must be greater than zero", (value) => parseFloat(value) > 0),
    });
 
    const handleSubmit = (values) => {
        console.log("Submitted values:", values);
    };
    return (
        <>
            <Header2 />
            {/* Toggle between Overview and Dashboard */}
            {!showDashboard ? (
                // STP Overview Layout
                <Grid className="wrapper pan-card-margin">
                    <Grid container spacing={3}>
 
                        <Grid item xs={12} md={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "70vh",
                                }}
                            >
                                <Box className="d-flex align-center justify-center start-stp-container">
                                    <Box>
                                        <img src={STP} alt="fund card img" className='start-stp-img' />
                                    </Box>
                                    <Box ml={3}>
                                        <Typography variant="h5" fontWeight="600" mb={2}>
                                            STP (Systematic Transfer Plan)
                                        </Typography>
                                        <Typography>
                                            Amount available in this fund is less than the amount required for
                                            <br />
                                            placing an STP order
                                        </Typography>
                                        <Button
                                            className="start-sipbtn"
                                            sx={{ ml: 1, mt: 2 }}
                                            onClick={handleOpenDashboard}
                                        >
                                            Open Dashboard
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
 
                    </Grid>
                </Grid>
            ) : (
 
                // Dashboard Layout
                <Grid className="wrapper pan-card-margin">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8.5}>
                            <Box className=' mb-3' >
                                <Box className='mb-2 mt-2 d-flex align-center '>
                                    <Box>
                                        <img src={SWP} alt="fund card img" />
                                    </Box>
                                    <Box ml={3}>
                                        <Typography variant='h5' fontWeight='600' mb={2}>STP (Systematic Transfer Plan)</Typography>
                                        <Typography>Transfer a fixed amount of money every month from one mutual fund to another within same fund house</Typography>
                                    </Box>
                                </Box>
                                <Divider className="custom-hr" />
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <Typography  >TRANSFER FROM</Typography>
                            </Box>
                            <Box className='d-flex justify-between ' sx={{ mt: 3 }}>
                                <Box className='d-flex' >
                                    <img src={Fund1} alt="fund card img" className='birla-cardimg' />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography ><b>Aditya Birla Sun Life PSU Equity Fund Direct Growth</b></Typography>
                                        <Typography className='font-12 ' sx={{ mt: 1 }}>Exit load and capital gain tax may be applicable</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{ ml: 2 }} className='text-end'>
                                        <Typography ><b>₹969.74</b></Typography>
                                        <Typography className='font-12 ' sx={{ mt: 1 }}>Available to Transfer</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ mb: 2, mt: 3 }}>
                                <Typography  >TRANSFER TO</Typography>
                            </Box>
                            <Grid container >
                                <Grid item xs={12} md={6}>
                                    <Box className='d-flex' >
                                        <img src={Fund1} alt="fund card img" className='birla-cardimg mr-2' />
                                        <TextField
                                            placeholder="Search for a fund"
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon style={{ color: "#9E9E9E" }} />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton>
                                                            <ArrowDropDownIcon style={{ color: "#9E9E9E" }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                style: { borderRadius: "8px", height: '40px' }, // Adjust border radius
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ mb: 4, }} >
                            <Box className="watchlist-card swp-card-mar">
                            <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange }) => (
                                <Form>
                                <Box className="card-content">
                                    {/* <CardContent> */}
                                    {/* Header Section */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }} className="card-padding">
                                        <Typography >
                                            Total STP Amount
                                        </Typography>
                                        <Typography fontWeight="bold">
                                            ₹0.00
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    {/* Monthly SWP Amount Section */}
                                    <Box my={2} className="card-padding" width="50%" mx="auto" textAlign="center">
                                        <Typography className="text-center" mb={1}>
                                            Monthly STP Amount <span style={{color:'red'}}>*</span>
                                        </Typography>
                                        <Box alignItems="center" display="flex" justifyContent="center">
                                            <Typography className="rs-fontsize" fontWeight="bold">
                                                ₹
                                            </Typography>
                                            <TextField
                                                variant="standard"
                                                name='amount'
                                                placeholder="0"
                                                inputProps={{ style: { fontSize: "1.5rem", textAlign: "center" } }}
                                                style={{ width: "80%" }} // Adjust width of the TextField
                                                onChange={handleChange}
                                                value={values.amount}
                                            />
                                        </Box>
                                        <ErrorMessage
                                            name="amount"
                                            component="div"
                                            style={{ color: "red", fontSize: "0.9rem", marginTop: "0.5rem" }}
                                        />
                                    </Box>
                                    {/* Installments and SWP Date Section */}
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3} className="card-padding">
                                        <Box display="flex" alignItems="center">
                                            <Typography mr={1} className='font-12' >Installments</Typography>
                                            {isEditingInstallments ? (
                                                <TextField
                                                    variant="standard"
                                                    value={installments}
                                                    onChange={handleInstallmentsInputChange}
                                                    inputProps={{
                                                        style: { textAlign: "center" },
                                                    }}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            width: "50px", // Default width
                                                            "@media (max-width: 600px)": {
                                                                width: "20px", // Reduced width for mobile
                                                            },
                                                        },
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Typography variant="body2">{installments}</Typography>
                                            )}
                                            <IconButton size="small" sx={{ ml: 1 }} onClick={handleInstallmentsEditClick}>
                                                {isEditingInstallments ? (
                                                    <CheckIcon fontSize="small" className="text-blue" />
                                                ) : (
                                                    <EditIcon fontSize="small" className="text-blue" />
                                                )}
                                            </IconButton>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <Typography mr={1} className='font-12'>SWP Date</Typography>
                                            {isEditingSWPDate ? (
                                                <TextField
                                                    variant="standard"
                                                    value={swpDate}
                                                    onChange={handleSWPDateInputChange}
                                                    inputProps={{
                                                        style: { textAlign: "center" },
                                                    }}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            width: "50px", // Default width
                                                            "@media (max-width: 600px)": {
                                                                width: "20px", // Reduced width for mobile
                                                            },
                                                        },
                                                    }}
                                                    autoFocus
                                                />
                                            ) : (
                                                <Typography variant="body2">{swpDate}</Typography>
                                            )}
                                            <IconButton size="small" sx={{ ml: 1 }} onClick={handleSWPDateEditClick}>
                                                {isEditingSWPDate ? (
                                                    <CheckIcon fontSize="small" className="text-blue" />
                                                ) : (
                                                    <EditIcon fontSize="small" className="text-blue" />
                                                )}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    {/* Bank Transfer Info */}
                                    {/* <Box mt={3} mb={2} className="card-padding">
                                        <Typography fontWeight='bold'>
                                            Amount will be transferred to your bank
                                        </Typography>
                                        <Typography mt={1} >
                                            HDFC BANK (XXXXXXXXXX2291)
                                        </Typography>
                                    </Box> */}
 
                                    {/* Footer Section */}
                                    {/* <Typography mb={2} mt={6} className="card-padding font-12 text-center">
                                        Your first order will be placed on 1st of next month.
                                    </Typography> */}
                                    <Box className="card-padding" mb={3} mt={8}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            className='submit-btn'
                                            type='submit'
                                        >
                                            Confirm STP
                                        </Button>
                                    </Box>
                                    {/* </CardContent> */}
                                </Box>
                                </Form>
                                )}
                            </Formik>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
}
 
export default Startstp;
