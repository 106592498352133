// import React from "react";
// import {
//   Box,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
//   Divider,
//   IconButton,
// } from "@mui/material";
// import LogoutIcon from "@mui/icons-material/Logout";
// import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
// import ComputerIcon from "@mui/icons-material/Computer";
// import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";


// const ActiveDevices = () => {
//   return (
//     <Box sx={{
//       padding: 3,
//       // height: "100vh",
//       // width: "1000px",
//       overflowY: "auto",
//       border: "1px solid rgba(237, 237, 237, 1)",
//       borderRadius: "8px",
//       backgroundColor: "#fff",
//       boxShadow: "none",
//       mb: 3,
//     }} >
//       {/* Header Section */}
//       <Typography sx={{ fontSize: "20px", fontWeight: "500", marginBottom: "8px" }}>

//         {/* <Typography variant="h6" fontWeight="bold" gutterBottom> */}
//         Active devices
//       </Typography>
//       <Typography variant="body2" color="text.secondary" gutterBottom>
//         You’re currently logged in on these devices. Multiple active sessions on the same device indicate you’ve opened the app in more than one browser.
//       </Typography>

//       {/* Current Device Section */}
//       <Typography sx={{ marginTop: 3 }}>
//         Current device
//       </Typography>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           padding: 2,
//           borderBottom: "1px solid #e0e0e0",
//           borderRadius: 1,
//           marginBottom: 2,
//           "@media (max-width: 600px)": { // For mobile screens
//             flexDirection: "column", // Stack the contents vertically
//             alignItems: "flex-start", // Align items to the start
//           },
//         }}
//       >
//         <Box sx={{ display: "flex", alignItems: "center" }}>
//           <ComputerIcon sx={{ fontSize: 40, marginRight: 2, color: "grey" }} />

//           {/* <DesktopWindowsIcon fontSize="large" sx={{ marginRight: 2, color: "#3f51b5" }} /> */}
//           <Box>
//             <Typography variant="body1" sx={{ fontWeight: "500" }}>Chrome, Windows</Typography>
//             <Typography variant="body2" color="text.secondary">
//               Active Now
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             width: 44,
//             height: 16,
//             borderRadius: "50%",
//             color: "#0B24C2",
//             cursor: "pointer",
//             textAlign: "center",
//           }}
//         >
//           Logout
//         </Box>
//         {/* <IconButton color="primary">
//           <LogoutIcon />
//         </IconButton> */}
//       </Box>

//       {/* Other Active Devices Section */}
//       <Typography sx={{ marginTop: 2 }}>
//         Active devices
//       </Typography>
//       <List>
//         <ListItem
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             padding: 2,
//             borderBottom: "1px solid #e0e0e0",
//             borderRadius: 1,
//             marginBottom: 2,
//             "@media (max-width: 600px)": { // For mobile screens
//               flexDirection: "column", // Stack the contents vertically
//               alignItems: "flex-start", // Align items to the start
//             },
//           }}
//         >
//           <Box sx={{ display: "flex", alignItems: "center" }}>
//             <SmartphoneIcon fontSize="large" sx={{ marginRight: 2, color: "grey" }} />
//             <Box>
//               <Typography sx={{ fontWeight: "500" }}>OnePlus Nord 2T 5G</Typography>
//               <Typography variant="body2" color="text.secondary">
//                 Logged on 07 Jul, 11:56 AM
//               </Typography>
//             </Box>
//           </Box>
//           {/* <IconButton color="primary">
//             Logout
//           <LogoutIcon /> 
//           </IconButton> */}
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               width: 44,
//               height: 16,
//               borderRadius: "50%",
//               color: "#0B24C2",
//               cursor: "pointer",
//               textAlign: "center",
//             }}
//           >
//             Logout
//           </Box>
//         </ListItem>
//       </List>
//     </Box >
//   );
// };

// export default ActiveDevices;

// src/ActiveDevices.js
import React from "react";
import { Box, Typography, List, ListItem, IconButton } from "@mui/material";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import useDeviceDetection from "./useDeviceDetection"; // Import the custom hook

const ActiveDevices = () => {
  const deviceType = useDeviceDetection(); // Get the current device type from the hook

  const getDeviceIcon = () => {
    switch (deviceType) {
      case "Mobile":
        return <SmartphoneIcon sx={{ fontSize: 40, color: "grey" }} />;
      case "Desktop":
        return <DesktopWindowsIcon sx={{ fontSize: 40, color: "grey" }} />;
      case "Tablet":
        return <DesktopWindowsIcon sx={{ fontSize: 40, color: "grey" }} />; // You can use a different icon for tablets if needed
      default:
        return <DesktopWindowsIcon sx={{ fontSize: 40, color: "grey" }} />;
    }
  };

  return (
    <Box sx={{
      padding: 3,
      overflowY: "auto",
      border: "1px solid rgba(237, 237, 237, 1)",
      borderRadius: "8px",
      backgroundColor: "#fff",
      boxShadow: "none",
      mb: 3,
    }}>
      <Typography sx={{ fontSize: "20px", fontWeight: "500", marginBottom: "8px" }}>
        Active devices
      </Typography>

      {/* Current Device Section */}
      <Typography sx={{ marginTop: 3 }}>
        Current device
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2,
          borderBottom: "1px solid #e0e0e0",
          borderRadius: 1,
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {getDeviceIcon()}
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "500" }}>
              {deviceType}, Chrome
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Active Now
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 44,
            height: 16,
            borderRadius: "50%",
            color: "#0B24C2",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          Logout
        </Box>
      </Box>

      {/* Other Active Devices Section */}
      {/* <Typography sx={{ marginTop: 2 }}>
        Active devices
      </Typography>
      <List>
        <ListItem
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
            borderBottom: "1px solid #e0e0e0",
            borderRadius: 1,
            marginBottom: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SmartphoneIcon fontSize="large" sx={{ marginRight: 2, color: "grey" }} />
            <Box>
              <Typography sx={{ fontWeight: "500" }}>OnePlus Nord 2T 5G</Typography>
              <Typography variant="body2" color="text.secondary">
                Logged on 07 Jul, 11:56 AM
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 44,
              height: 16,
              borderRadius: "50%",
              color: "#0B24C2",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            Logout
          </Box>
        </ListItem>
      </List> */}
    </Box>
  );
};

export default ActiveDevices;
