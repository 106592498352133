import React, { useState } from "react";
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Profile from "../../src/assets/images/profileimg.svg";

const Sidebar = ({ onSelect }) => {
  const [selectedItem, setSelectedItem] = useState("basic-details");

  const menuItems = [
    { id: "basic-details", label: "Basic Details" },
    { id: "reports", label: "Reports" },
    { id: "change-password", label: "Change Password" },
    { id: "change-pin", label: "Change PIN" },
    { id: "nominee-details", label: "Nominee Details" },
    { id: "active-devices", label: "Active Devices" },
  ];

  const handleSelect = (id) => {
    setSelectedItem(id);
    onSelect(id);
  };

  return (
    <Box
      // sx={{
      //   height: "80vh",
      //   bgcolor: "white",
      //   boxShadow: 'none',
      //   border: "1px solid rgba(237, 237, 237, 1)",
      //   borderRadius: "10px"
      // }}
      className="user-details-meun"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: 2,
        }}
      >
        <Box mt={2}>
          <img
            src={Profile}
            alt="profile"
            style={{ width: '100%', height: 'auto' }}
          />
        </Box>
        <Typography mt={1} mb={3} variant="h6">Anand Arjun Rao</Typography>
      </Box>

      <List>
        {menuItems.map((item) => (
          <ListItem
            className="profile-list"
            button
            key={item.id}
            onClick={() => handleSelect(item.id)}
            // onClick={() => onSelect(item.id)}
            sx={{
              bgcolor: selectedItem === item.id ? "rgba(233, 236, 255, 1)" : "white",
              color: selectedItem === item.id ? "rgba(62, 65, 84, 1)" : "inherit",
            }}
          >
            <ListItemText primary={item.label} />
            <ListItemIcon minWidth="0">
              <ChevronRightIcon />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
