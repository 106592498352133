import { GET_NOTIFICATION_LIST } from "../actions/types";

const initialState = {
    notificationList: [],
    loading: false,
};

const notificationReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_LIST:
            return {...state, loading: false, notificationList: action.payload };
        default:
            return state;
    }
};

export default notificationReducers;
