import axios from "axios";
import { GET_EXPLORE_FUNDS_LIST, GET_AMC_LISTS, GET_EXPLORE_FUNDS_DETAIL } from "./types";
import { toast } from "react-toastify";
import { getLocalStorageUserToken } from "../utils/userData";
const token = getLocalStorageUserToken();
export const getExploreFundsList = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_EXPLORE_FUNDS_LIST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}allMutualFunds`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_EXPLORE_FUNDS_LIST, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_EXPLORE_FUNDS_LIST, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching explore funds list:", error.message);
    }
  };
};

export const getAMCLists = (payload) => {
  return async (dispatch) => {
    dispatch({ type: GET_AMC_LISTS });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}amc-list`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_AMC_LISTS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_AMC_LISTS, error: error.message }); // Dispatching an error state if there's a problem
      console.error("Error fetching AMC lists:", error.message);
    }
  };
};

export const getFundDetails = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}show-fund`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      dispatch({ type: GET_EXPLORE_FUNDS_DETAIL, payload: response.data });
    } catch (error) {
      console.error("Error fetching fund details:", error.message);
    }
  };
};