import React, { useState, useRef } from 'react';
import Header2 from "../home/Appbar2";

import WebFooter from "../../components/Web-footer";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import "../../assets/css/funddetails.css"
import SbiIcon from "../../assets/images/sbi.png"

import {
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Stack,
    Box,
    Grid,
    IconButton,
    Divider
} from '@mui/material';
import "../../assets/css/onbording.css"
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function Dashboard() {
    const [pin, setPin] = useState(Array(4).fill(''));
    const inputRefs = useRef([]);

    const handlePinChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newPin = [...pin];
            newPin[index] = value.slice(-1);
            setPin(newPin);

            if (value && index < pin.length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace') {
            const newPin = [...pin];
            if (!pin[index] && index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
            newPin[index] = '';
            setPin(newPin);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    return (
        <>
            <Header2 />
            <Grid className="wrapper pan-card-margin border-top">
                <Grid container className="" >
                    <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                        <Box className="mt-3">
                            <Typography > Home {">"} Import Funds </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} className='pl-0'>
                        <div className="scheme-container">
                            <div className="scheme-content">
                                <Typography className="external-title">Import external funds</Typography>
                                <div className='external-boder'></div>
                                <p className="scheme-subtitle">Import to track, invest in & redeem <br />
                                    from your non-Groww funds</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={12} mb={2}>
                        <Card className="import-funds-card" elevation={0}>
                            <CardContent className="card-content">
                                <Box display="flex" alignItems="center" mb={2}>
                                    <KeyboardBackspaceIcon />
                                    <Typography variant="h6" ml={1} className="authorize-title">
                                        Authorize with OTP
                                    </Typography>
                                </Box>
                                <Divider sx={{ mb: 2 }} className='external-divider' />
                                <Typography variant="body1" className="subtitle">
                                    Enter a 6-digit OTP sent to abc@gmail.com & 9999999999
                                </Typography>
                                <Box className="external-otp-input">
                                    {pin.map((digit, index) => (
                                        <TextField
                                            sx={{ width: '100%' }}
                                            key={index}
                                            variant="standard"
                                            value={digit}
                                            onChange={(e) => handlePinChange(index, e.target.value)}
                                            inputRef={(el) => (inputRefs.current[index] = el)}
                                            className="pin-input"
                                            inputProps={{
                                                maxLength: 1,
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                    ))}
                                </Box>
                                <div className="external-otp-restart">
                                    <span className="email">Didn't receive OTP?</span>
                                    <button className="logout-link">  Resend </button>
                                </div>
                                <Stack direction='column'
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: 3
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        className="next-button "
                                        onClick={() => console.log("PIN:", pin.join(''))}
                                        component={Link}
                                    // to="/selectbank"
                                    >
                                        Confirm
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}

export default Dashboard;
