export const recentProductList = (fund) => {
  let recentProducts = JSON.parse(localStorage.getItem("recentProducts")) || [];

  const exists = recentProducts.some((product) => product.id === fund.id);

  if (!exists) {
    recentProducts.push(fund);
    if (recentProducts.length > 5) {
      recentProducts.shift();
    }
    localStorage.setItem("recentProducts", JSON.stringify(recentProducts));
  }
};

export const getRecentProductList = (id) => {
    return JSON.parse(localStorage.getItem("recentProducts")) || [];
};
