import React, { useState } from 'react';
import {
    Button,
    Typography,
    Container,
    Grid,
    Box,
    TextField,
    IconButton,
    Card,
    CardContent,
    InputAdornment,
    CircularProgress,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import "../../assets/css/home.css";
import "../../assets/css/common.css";
import "../../assets/css/aboutus.css";
import '../../assets/css/pandetails.css';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import Pancard from '../../assets/images/pancardimg.svg';
import Header2 from "../../pages/home/Appbar2";

function Pandetails({ onBack }) {
    const [pan, setPan] = useState("");
    const [step, setStep] = useState(1); // Tracks the current step (1, 2, or 3)
    const [userName, setUserName] = useState(""); // Simulates fetched name
    const handleClear = () => {
        setPan(""); // Clear the PAN input field
    };

    const handleContinue = () => {
        setStep(2); // Show fetching screen
        // Simulate an API call
        setTimeout(() => {
            setUserName("ANAND ARJUN RAO"); // Simulated user name from API
            setStep(3); // Move to verification screen
            setTimeout(() => {
                setStep(4); // Move to the confirm details screen
            }, 3000); // Simulated verification delay
        }, 3000); // Simulated fetch delay
    };

    return (
        <>
            <Header2 />
            <div className="wrapper pan-card-margin bgcolor" style={{ backgroundColor: "#f5f7fb", }}>
                {/* <Grid container textAlign="start" spacing={2}> */}
                {/* <Grid item xs={12} md={4} sx={{ mb: 6 }}> */}
                <Box className='pandetails-bg'>
                    {step === 1 && (
                        <Card
                            sx={{
                                width: 500,
                                padding: 2,
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            {/* Back Icon */}

                            {/* Title */}
                            <CardContent>
                                <IconButton aria-label="back" className="back-arrowbtn">
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    Enter your PAN
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                    PAN is compulsory for investing in India
                                </Typography>
                                {/* PAN Input Field */}
                                {/* PAN Input Field with Clear Button */}
                                <TextField
                                    fullWidth
                                    placeholder="ABCDE0000A"
                                    variant="outlined"
                                    size="small"
                                    value={pan}
                                    onChange={(e) => setPan(e.target.value)}
                                    InputProps={{
                                        endAdornment: pan && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClear}
                                                    aria-label="clear input"
                                                    edge="end"
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        mb: 2,
                                    }}
                                />
                                {/* Secure Text */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        mb: 2, mt: 5,
                                        fontSize: 14,
                                        color: "textSecondary",
                                    }}
                                >
                                    <GppGoodIcon fontSize="small" sx={{ mr: 1, color: 'gray' }} />
                                    <Typography variant="caption" color="textSecondary" >
                                        Your PAN details are 100% safe & secure with us
                                    </Typography>
                                </Box>
                                {/* Continue Button */}
                                <Button
                                    fullWidth
                                    className="submit-btn"
                                    variant="contained"
                                    onClick={handleContinue}
                                // disabled={!pan.trim()}
                                >
                                    Continue
                                </Button>
                            </CardContent>
                        </Card>
                    )}
                    {step === 2 && (
                        <Card
                            sx={{
                                width: 500,
                                padding: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            <CircularProgress sx={{ color: "#1C5DB8", mb: 2 }} />
                            <Typography variant="body1" color="textSecondary">
                                Fetching your name as on PAN...
                            </Typography>
                        </Card>
                    )}
                    {step === 3 && (
                        <Card
                            sx={{
                                width: 500,
                                padding: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Hello!
                            </Typography>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                                {userName}
                            </Typography>
                            <CircularProgress sx={{ color: "#1C5DB8", mb: 2 }} />
                            <Typography variant="body1" color="textSecondary">
                                Verifying your details
                            </Typography>
                        </Card>
                    )}
                    {step === 4 && (
                        <Card
                            sx={{
                                width: 500,
                                padding: 2,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                boxShadow: 3,
                                borderRadius: 2,
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ mb: 3 }}>
                                    Confirm PAN details
                                </Typography>

                                <Box className='pan-border'>
                                    <img
                                        src={Pancard}
                                        alt="Coming Soon!"
                                        className="w-100"
                                    />
                                    <Typography display='flex' alignItems='center' olor="textSecondary" sx={{ mt: 2, mb: 1 }}>
                                        <WarningAmberRoundedIcon sx={{ mr: 1, color: 'rgba(255, 164, 18, 1)' }} /> Once confirmed, PAN details CANNOT be changed later.
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <FormControlLabel required control={<Checkbox className="custom-checkbox" />} label="I give my consent to undertake Online KYC and to upload/download my KYC record to/from Central KYC Records Registry (CKYCRR) & KRA." />
                                </Box>
                                <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }} >
                                    <Button className='add-cartbtn' fullWidth sx={{ mr: 1 }}>
                                        No, Edit PAN
                                    </Button>
                                    <Button className='start-sipbtn ' fullWidth sx={{ ml: 1 }} >
                                        Yes, Confirm
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    )}
                </Box>
                {/* </Grid> */}
                {/* </Grid> */}
            </div>
        </>
    );
}

export default Pandetails;
