import { DASHBOARD_VALUATION } from "../actions/types";

const initialState = {
    dashbaordValuation: "",
    loading: false,
  };

  const dashbaordReducer =( state = initialState, action) =>{
    switch (action.type) {
        case DASHBOARD_VALUATION:
            return { ...state, loading: false, dashbaordValuation: action.payload };
        default:
            return state;;
    }
  }

  export default dashbaordReducer;  