import { combineReducers } from "redux";
import usersReducer from "./usersReducers";
import exploreFundsReducers from "./exploreFundsReducers";
import watchListReducers from "./watchListReducers";
import notificationReducers from "./notificationReducers";
import dashbaordReducer from "./dashboardReducer";
import sipCalculatorReducers from "./calculatorReducers";
const rootReducer = combineReducers({
  users: usersReducer,
  exploreFunds: exploreFundsReducers,
  watchList: watchListReducers,
  notification: notificationReducers,
  dashboard : dashbaordReducer,
  calculator: sipCalculatorReducers,
});

export default rootReducer;
