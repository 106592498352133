import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";

import Signature from "../../assets/images/signature-svg.svg"

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Frame from "../../assets/images/Frame.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import CheckIcon from '@mui/icons-material/Check'
import { Link } from 'react-router-dom';

const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const investments = ['Stocks', 'Mutual Funds']


    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">
                        <div className="dob-container">
                            <KeyboardBackspaceIcon className="back-button-kyc" />


                            <Typography className="form-title">
                                Add nominees for your investments
                            </Typography>


                            <div className='d-flex align-items-center justify-content-center' style={{ marginTop: '50px' }}>
                                <img src={Frame} alt='nominee' />
                            </div>


                        </div>
                    </CardContent>

                    <CardActions className="kyc-card-actions">

                        <Stack sx={{ width: '100%' }} direction='column' spacing={3}>


                            <Box className="nominees-container">
                                <Typography className="nominees-title" mb={2}>
                                    Nominees will apply to your investments in
                                </Typography>
                                <Box className="nominees-list">
                                    {investments.map((item) => (
                                        <Box key={item} className="list-item">
                                            <CheckIcon color="primary" fontSize="small" />
                                            <Typography className="list-item-text">{item}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>

                            <Button
                                variant="contained"
                                className="open-camera"
                                component={Link}
                                to="/addnominee"
                            >
                                Add nominees
                            </Button>

                            <Typography className="trading-note text-center">
                                I don’t want to add nominees
                            </Typography>

                        </Stack>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default SelectBank;
