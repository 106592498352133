import axios from "axios";
import { SIP_CALCULATOR, LUMPSUM_CALCULATOR } from "./types";
import { getLocalStorageUserToken } from "../utils/userData";
const token = getLocalStorageUserToken();

export const calculateSip = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SIP_CALCULATOR }); // Dispatching a loading state
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}calc/sip`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch({ type: SIP_CALCULATOR, payload: response.data.data });
    } catch (error) {
      dispatch({ type: SIP_CALCULATOR, payload: error.message });
    }
  };
};

export const calculateLumpsum = (payload) => {
    return async (dispatch) => {
      dispatch({ type: LUMPSUM_CALCULATOR }); // Dispatching a loading state
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}calc/lumpsum`,
          payload,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        dispatch({ type: LUMPSUM_CALCULATOR, payload: response.data.data });
      } catch (error) {
        dispatch({ type: LUMPSUM_CALCULATOR, payload: error.message });
      }
    };
  };

