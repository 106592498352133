import { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Button,
    CardActions,
    Stack,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import "../../assets/css/onbording.css";
import Header2 from "../../pages/home/Appbar2";

import worldIcon from "../../assets/images/world-icon.svg"

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';


const SelectBank = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleNext = () => {
        if (selectedDate) {
            console.log('Selected date:', selectedDate);
        }
    };

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/selfiekyc'); // Navigates to the '/setuppin' route
    };

    return (
        <>
            <div className="pin-setup-header">
                <Header2 />
            </div>

            <div className="bank-selection-container">

                <Card className="bank-selection-card bob-selection-card">

                    <CardContent className="bob-card-content">

                        <Stack
                            direction='column'
                            spacing={3}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >

                            <img src={worldIcon} alt='photo.png' className='verify-location-img' onClick={handleRedirect}
                            />

                            <Typography className="trading-note-text">
                                Verifying India as your current location...
                            </Typography>

                        </Stack>


                    </CardContent>

                    <CardActions className="bob-card-actions">

                        <Stack sx={{ width: '100%' }} direction='column' spacing={3}>

                            <Typography className="trading-note-text">
                                Do not Press the 'back' button or close the app.
                            </Typography>


                        </Stack>
                    </CardActions>
                </Card>
            </div>
        </>
    );
};

export default SelectBank;
