export const USER_LOGIN = "USER_LOGIN";
export const USER_START = "USER_START";

export const DASHBOARD_VALUATION ="DASHBOARD_VALUATION";
export const USER_VERIFY_LOGIN = "USER_VERIFY_LOGIN";
export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const REGISTER_VIA_EMAIL = "REGISTER_VIA_EMAIL";
export const MAIL_OTP_VERIFICATION = "MAIL_OTP_VERIFICATION";
export const SET_USER_PASSWORD = "SET_USER_PASSWORD";
export const RESEND_OTP = "RESEND_OTP";
export const FORGOT_PASSWORD_VERIFY_OTP = "FORGOT_PASSWORD_VERIFY_OTP";
export const USER_SET_PIN = "USER_SET_PIN";
export const REGISTER_VIA_GOOGLE = "REGISTER_VIA_GOOGLE";
export const USER_MOBILE_NUMBER_SENDOTP = "USER_MOBILE_NUMBER_SENDOTP";




export const GET_EXPLORE_FUNDS_LIST = "GET_EXPLORE_FUNDS_LIST";
export const GET_AMC_LISTS = "GET_AMC_LISTS";
export const GET_EXPLORE_FUNDS_DETAIL = "GET_EXPLORE_FUNDS_DETAIL"

export const GET_WATCH_LIST = "GET_WATCH_LIST";
export const DELETE_WATCH_LIST = "DELETE_WATCH_LIST";
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const SIP_CALCULATOR = "SIP_CALCULATOR";
export const LUMPSUM_CALCULATOR = "LUMPSUM_CALCULATOR";
