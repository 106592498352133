import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ELSSDetails = () => {
  const financialYears = [
    "Apr 2023 - Mar 2024",
    "Apr 2024 - Mar 2025",
    "Apr 2025 - Mar 2026",
  ];

  const validationSchema = Yup.object().shape({
    selectedYear: Yup.string().required("Please select a financial year."),
  });

  const initialValues = {
    selectedYear: "",
  };

  const handleSubmit = (values) => {
    console.log("Selected Year:", values.selectedYear);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange }) => (
          <Form>
            <Box className="card-content w-100 watchlist-card">
              <Typography mt={2} className="font-600 align-center d-flex card-padding">
                Mutual Funds - ELSS statement
              </Typography>
              <Divider sx={{ my: 2 }} />
              {/* Fund Information */}
              <Box className="d-flex justify-between watchlist-textpadding mb-150">
                <Box sx={{ width: "100%", margin: "auto" }}>
                  <Typography mb={1} className='font-14'>
                    Choose financial year <span style={{color:'red'}}>*</span>
                  </Typography>
                  <FormControl fullWidth variant="outlined">
                    <Field
                      as={Select}
                      name="selectedYear"
                      value={values.selectedYear}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Choose financial year" }}
                      sx={{
                        backgroundColor: "#fff",
                        border: "1px solid #E0E0E0",
                        borderRadius: "8px",
                        "& .MuiSelect-select": {
                          padding: "10px 16px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="" >
                        Select a financial year
                      </MenuItem>
                      {financialYears.map((year, index) => (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="selectedYear"
                      component="div"
                      style={{ color: "red", fontSize: "16px", marginTop: "4px" }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Divider sx={{ my: 2 }} />
              {/* Transfer Information */}
              <Typography className="card-padding font-14">
                *File password is your PAN Number in capital letters
              </Typography>
              <Box className="card-padding" sx={{ mb: 2 }}>
                <Button
                  type="submit"
                  className="start-sipbtn"
                  variant="contained"
                  fullWidth
                  sx={{ bgcolor: "#1C5DB8", color: "#fff", mt: 2 }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ELSSDetails;
