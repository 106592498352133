import React, { useState } from "react";
import {
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Divider,
    Stack,
    Button,
    Autocomplete,
    InputAdornment,
    Card,
    CardContent,
    CircularProgress

} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVert from "@mui/icons-material/MoreVert";
import InfoIcon from '@mui/icons-material/Info';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CloseIcon from '@mui/icons-material/Close';
import { TextField, Avatar } from "@mui/material";
import Popupverif from "../../assets/images/popup-verify.svg"
import { ChevronRight } from '@mui/icons-material';
import Header2 from "../home/Appbar2";

import "../../assets/css/funddetails.css"
import "../../assets/css/onbording.css"
import "../../assets/css/common.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import hdfcIcon from "../../assets/images/hdfc.svg"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Edit, ContentCopy } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import Pancard from '../../assets/images/pancardimg.svg';
import SbiIcon from "../../assets/images/sbi-icon.svg"
import HdfcIcon from "../../assets/images/hdfc.svg"
import IcicIcon from "../../assets/images/icic-bank.svg"
import KokatIcon from "../../assets/images/kokat.svg"
import AxisBankIcon from "../../assets/images/axis-bank.svg"
import PunjabBankIcon from "../../assets/images/punjab-bank.svg"
import AbhyudayaBankIcon from "../../assets/images/Abhyudaya-bank.svg"

export default function TransactionList() {

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
    }

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuOpen = (event, index) => {
        setMenuAnchorEl({ anchorEl: event.currentTarget, index });
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleInvestClick = () => {
        console.log("Invest action triggered");
        handleMenuClose();
    };

    const handleRedeemClick = () => {
        console.log("Redeem action triggered");
        handleMenuClose();
    };

    const handleViewTransactionsClick = () => {
        console.log("View Transactions action triggered");
        handleMenuClose();
    };

    const handleStartSwpClick = () => {
        console.log("Start SWP action triggered");
        handleMenuClose();
    };

    const handleStartStpClick = () => {
        console.log("Start STP action triggered");
        handleMenuClose();
    };

    const banks = [
        { id: 1, name: "HDFC BANK", number: "XXXX1234", logo: hdfcIcon },
        // Add more bank objects if needed
    ];

    const banks2 = [
        { id: 1, name: "Add Another Bank", },
        // Add more bank objects if needed
    ];

    // -------------//

    const [anchorElAutoPayMenu, setAnchorElAutoPayMenu] = useState(null);

    const handleMenuOpen1 = (event) => {
        setAnchorElAutoPayMenu(event.currentTarget);
    };

    const handleMenuClose1 = () => {
        setAnchorElAutoPayMenu(null);
    };

    const handleAddAutopay = () => {
        console.log("Add Autopay action triggered");
        handleMenuClose();
    };

    const handleEditAutopay = () => {
        console.log("Edit Autopay action triggered");
        handleMenuClose();
    };

    const handleDeleteAutopay = () => {
        console.log("Delete Autopay action triggered");
        handleMenuClose();
    };

    const handlePauseAutopay = () => {
        console.log("Pause Autopay action triggered");
        handleMenuClose();
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const banksinput = [
        { name: "AXIS BANK", logo: AxisBankIcon },
        { name: "ICICI BANK LIMITED", logo: IcicIcon },
        { name: "STATE BANK OF INDIA", logo: SbiIcon },
        { name: "KOTAK MAHINDRA BANK LIMITED", logo: KokatIcon },
    ];

    // popup content state

    // const [pan, setPan] = useState("");
    // const [step, setStep] = useState(2); 
    // const [userName, setUserName] = useState(""); 
    // const handleClear = () => {
    //     setPan(""); 
    // };

    // const handleContinue = () => {
    //     setStep(2); 

    //     setTimeout(() => {
    //         setUserName("ANAND ARJUN RAO"); 
    //         setStep(3); 
    //         setTimeout(() => {
    //             setStep(4); 
    //         }, 3000); 
    //     }, 3000); 
    // };

    const [step, setStep] = useState(2); // Tracks the current step (2: Progress, 4: Verification)

    // Simulate the flow
    React.useEffect(() => {
        if (step === 2) {
            setTimeout(() => {
                setStep(4); // Automatically move to the verification step after 30 seconds
            }, 10000); // 10 seconds delay
        }
    }, [step]);

    return (
        <>
            <Header2 />
            <div className="wrapper border-top pan-card-margin">
                <Box className="bank-mt-17">
                </Box>
                <Grid container spacing={2} className="transaction-container">
                    <Grid item xs={12} md={12}>
                        <Typography className='bank-text-head'> Bank Account Details </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="transaction-container">

                    <Grid item xs={12} md={6} >
                        <div className="bank-table-container">
                            <TableContainer>
                                <Table>

                                    <TableBody>

                                        {banks.map((bank, index) => (
                                            <TableRow
                                                className="bank-row-bg"
                                                key={bank.id}
                                            >
                                                <TableCell >
                                                    <Stack direction='row' spacing={2}>
                                                        <img src={bank.logo} alt={`${bank.name} Logo`} />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell >{bank.number}</TableCell>
                                                <TableCell >
                                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    {menuAnchorEl?.index === index && (
                                                        <Menu
                                                            anchorEl={menuAnchorEl.anchorEl}
                                                            open={Boolean(menuAnchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem onClick={handleInvestClick}>
                                                                <Box display="flex" alignItems="center">
                                                                    {/* <div className="square"></div> */}
                                                                    <DeleteIcon />
                                                                    <Typography>Delete</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        </Menu>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    {/* <TableBody>
                                        {banks2.map((bank, index) => (
                                            <TableRow
                                                key={bank.id}
                                            >
                                                <TableCell
                                                >
                                                    <Stack direction='row' spacing={2}>
                                                        <AddCircleOutlineIcon />
                                                        <Typography> {bank.name} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell ></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody> */}
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} mb={6}>
                        <div className="details-container">
                            <header className="add-bank-header">

                                <div>

                                    <div className="d-flex align-items-center justify-content-between">

                                        <h5 className="bank-verify-title">Verify your bank account</h5>

                                        <ClearIcon className="edit-icon" />

                                    </div>
                                    <p

                                    >
                                        Investments on Misshika Capital can be done using only savings bank accounts</p>
                                </div>
                            </header>
                            <Grid container spacing={2}>
                                <Box ml={1.5} className="sub-details-container">
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <header className="bank-header">
                                            <img
                                                src={SbiIcon}
                                                alt="SbiIcon"
                                                className="sbiIconbank-logo"
                                            />
                                            <h5 className="bank-verify-title">STATE BANK OF INDIA</h5>
                                        </header>
                                        <Button variant="text" className="edit-bank-details">Edit</Button>
                                    </div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className="details-row">
                                                <Stack direction='row' spacing={14.5}>
                                                    <div className="details-label">Branch Name</div>
                                                    <div className="details-value">Wagholi</div>
                                                </Stack>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className="details-row">
                                                <Stack direction='row' spacing={17}>
                                                    <div className="details-label white-space-nowrap">IFSC Code</div>
                                                    <div className="details-value">ABCD1234567</div>
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid item xs={12} sm={12} md={12}>
                                    <lable> Enter bank account of Anand Arjun Rao </lable>
                                    <TextField
                                        sx={{ mt: 3 }}
                                        fullWidth
                                        placeholder="Eg. 12345678901"
                                        variant="outlined"
                                        // className="search-field"
                                        // type="number"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <RemoveRedEyeIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button
                                        variant="contained"
                                        className="next-button "
                                        onClick={handleClickOpen}
                                    >
                                        Verify Bank
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                className="verify-model-radius"
            >
                <DialogTitle className="d-flex align-items-center justify-content-center">
                    <Box>
                        {step === 2 && (
                            <Typography
                                variant="h6"
                                sx={{ fontSize: "16px", textAlign: "center", mt: 3, mb: 3 }}
                            >
                                We are depositing ₹1 in your bank <br /> account to verify it
                            </Typography>
                        )}
                        {step === 4 && (
                            <Stack direction='row' spacing={1}
                                sx={{ justifyContent: "center", alignItems: "center", mt: 3 }}
                            >
                                <div className="verify-model-img">
                                    <img src={SbiIcon} alt="sbi" />
                                </div>
                                <Typography
                                    fontSize='18px'
                                    fontWeight='500'
                                >
                                    STATE BANK OF INDIA
                                </Typography>
                            </Stack>
                        )}
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <Box>
                        {/* Step 2: Loading Spinner */}
                        {step === 2 && (
                            <Box
                                sx={{
                                    // width: 400,
                                    width: { xs: 'auto', sm: 400 },
                                    padding: 4,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    background: "none",
                                }}
                            >
                                <CircularProgress sx={{ color: "#1C5DB8", mb: 2 }} />
                                <Typography variant="body1" color="textSecondary">
                                    This will take 30 seconds
                                </Typography>
                            </Box>
                        )}
                        {/* Step 4: Verification Success */}
                        {step === 4 && (
                            <Box
                                sx={{
                                    // width: 400,
                                    width: { xs: 'auto', sm: 400 },
                                    padding: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    background: "none",
                                }}
                            >
                                <Box>
                                    <Typography variant="subtitle" >
                                        Bank account is verified!
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            mt: 3
                                        }}
                                    >
                                        <img
                                            src={Popupverif}
                                            alt="Verification Success"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

