import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  IconButton,
  Avatar,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sanitizeInput } from "../utils/sanitixeInput";
import OTPInput from "../pages/home/opt";
import { useDispatch } from "react-redux";
import { mailOtpVerification, userMobileNumber } from "../actions/userActions";
import {
  getLocalStorageUserData,
  getLocalStorageUserId,
} from "../utils/userData";
import { toast } from "react-toastify";

const VerifyMobilePopup = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/setuppin"); // Navigates to the '/setuppin' route
  };

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [step, setStep] = useState(1); // Step 1: Enter Mobile, Step 2: Enter OTP, Step 3: Success
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp_time, setOtp_time] = useState("");
  const [attempt, setAttempt] = useState("1");
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const userData = getLocalStorageUserData();
  const handleMobileChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Only take the last digit
    setOtp(newOtp);
    formik.setFieldValue("otp", newOtp);
    if (value && index < otp.length - 1) focusInput(index + 1);
  };

  const sanitizeTest = (fieldName) => ({
    name: `is-sanitized-${fieldName}`,
    test: (value) => {
      const sanitizedValue = sanitizeInput(value);
      return sanitizedValue === value; // Ensure input matches sanitized output
    },
    message: `Input should be sanitized, and no action should occur`,
  });

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      focusInput(index - 1);
    }
  };

  const focusInput = (index) => {
    const input = document.querySelectorAll("input")[index];
    if (input) input.focus();
  };

  const handleSendOtp = () => {
    console.log("OTP sent to:", mobileNumber);
    setOtpSent(true);
    setStep(2); // Move to step 2 (Enter OTP)
  };

  const handleSubmit = () => {
    const otpValue = otp.join("");
    console.log("Submitted OTP:", otpValue);
    setOtpVerified(true);
    setStep(3); // Move to step 3 (Success)
  };

  const handleContinue = () => {
    // Navigate to /setuppin when the "Continue" button is clicked
    navigate("/setuppin");
    // console.log("navigate to /setuppin")
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits") // Ensures only numbers and exactly 10 digits
      .required("Mobile number is required"),
    otp:
      step == 2
        ? Yup.string().when("verifyOtp", (verifyOtp, schema) => {
            return verifyOtp[0] === true
              ? schema
                  .required("OTP is required")
                  .length(6, "OTP must be exactly 6 digits")
              : schema;
          })
        : "",
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      otp: "",
      verifyOtp: false,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisable(true);

      if (step == 1) {
        const payload = {
          id: userData?.id,
          email: userData?.email,
          mobile: values?.mobile,
        };
        dispatch(userMobileNumber(payload))
          .then((response) => {
            if (response?.status == true) {
              toast.success(response?.message);
              setAttempt(response?.attempt);
              setOtp_time(response?.otp_time);
              setStep(2);
              formik.setFieldValue("verifyOtp", true);
            } else {
              toast.error(response?.message);
            }
          })
          .catch((error) => {
            setDisable(false);
          });
      }
      if (step == 2) {
        const mailOtpVerificationPayload = {
          email: userData?.email,
          mobile_verification: 1,
          otp: values?.otp,
          otp_time: otp_time,
          attempt: attempt,
        };
        dispatch(mailOtpVerification(mailOtpVerificationPayload))
          .then((response) => {
            if (response.status) {
              toast.success(response?.message);
              setStep(3); // if user first time login render set password screen
            } else {
              if (response.message == "Invalid OTP") {
                setAttempt((attempt) => attempt + 1);
              }
              toast.error(response?.message);
            }
            setDisable(false);
          })
          .catch((error) => {
            setDisable(false);
          });
      }
    },
  });

  const setPin = () => {
    navigate("/setuppin");
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      className="otppopup"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "1.25rem",
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {step === 3 ? "" : "Verify Mobile Number"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" sx={{ mt: 1 }}>
            {step === 1 && (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Enter Mobile Number
                </Typography>
                <TextField
                  variant="outlined"
                  value={formik?.values?.mobile}
                  name="mobile"
                  onChange={formik?.handleChange}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "rgba(245, 245, 245, 1)",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="text-danger">{formik.errors.mobile}</div>
                ) : null}
              </>
            )}
            {step === 2 && (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Enter Mobile Number
                </Typography>
                <TextField
                  variant="outlined"
                  value={formik?.values?.mobile}
                  onChange={formik?.handleChange}
                  disabled={true}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "rgba(245, 245, 245, 1)",
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="text-danger">{formik.errors.mobile}</div>
                ) : null}
                {/* New label for OTP instruction */}
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Enter a 6-digit OTP sent to this Mobile Number
                </Typography>

                <Box display="flex" gap={1} sx={{ mb: 2 }}>
                  {/* {otp.map((digit, index) => (
                    // <TextField
                    //   key={index}
                    //   variant="standard"
                    //   value={digit}
                    //   onChange={(e) => handleOtpChange(index, e.target.value)}
                    //   inputProps={{
                    //     maxLength: 1,
                    //     style: {
                    //       width: "40px",
                    //       height: "40px",
                    //       fontSize: "1.2rem",
                    //       textAlign: "center",
                    //       color: "#000",
                    //       backgroundColor: "transparent",
                    //       border: "none",
                    //       borderBottom: "2px solid rgba(62, 65, 84, 1)",
                    //     },
                    //   }}
                    //   onKeyDown={(e) => handleKeyDown(e, index)}
                    //   sx={{
                    //     "& .MuiInput-underline:before": {
                    //       borderBottom: "none",
                    //     },
                    //     "& .MuiInput-underline:after": { borderBottom: "none" },
                    //     "& .MuiInputBase-input:focus": {
                    //       borderBottom: "2px solid #0047FF",
                    //       outline: "none",
                    //     },
                    //   }}
                    // />

                  ))} */}
                  <OTPInput
                    value={formik.values.otp}
                    length={6}
                    formik={formik}
                  />
                </Box>
                {formik.touched.otp && formik.errors.otp ? (
                  <div className="text-danger">{formik.errors.otp}</div>
                ) : null}
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Didn’t receive OTP?{" "}
                  <a onClick={handleSendOtp} className="resend-link text-blue">
                    Resend
                  </a>
                </Typography>
              </>
            )}

            {step === 3 && (
              <>
                <Box textAlign="center">
                  <CheckCircleIcon className="check-icon" />

                  <Typography
                    onClick={handleRedirect}
                    sx={{ mt: 3 }}
                    textAlign="center"
                    variant="h6"
                  >
                    Mobile Number Registered
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
          <Button
            // onClick={step === 3 ? handleClose : step === 2 ? handleSubmit : handleSendOtp}
            // onClick={
            //   step === 3
            //     ? handleContinue
            //     : step === 2
            //     ? handleSubmit
            //     : handleSendOtp
            // }
            onClick={() => {
              if (step == 3) {
                setPin();
              }
            }}
            type="submit"
            color="primary"
            variant="contained"
            className="start-sipbtn"
            sx={{
              textTransform: "none",
              width: "40%",
              height: "48px",
              borderRadius: "8px",
            }}
          >
            {step === 3 ? "Continue" : step === 2 ? "Submit" : "Send OTP"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default VerifyMobilePopup;
