import React from 'react';
import { AppBar, Toolbar, Button, Typography, Container, Grid, Avatar, Link, Box, Stack, Paper, ListItem, List, ListItemText } from '@mui/material';
import Header from '../pages/home/Appbar';
import Hero from '../assets/images/abouthero.svg';
import Who1 from '../assets/images/who1.svg';
import Who2 from '../assets/images/who2.svg';
import Who3 from '../assets/images/who3.svg';
import '../assets/css/home.css'; // Import your external CSS
import '../assets/css/common.css';
import '../assets/css/aboutus.css';
import WebFooter from '../components/Web-footer';
import GetStart from '../components/getstart-card-about';

function PrivacyPolicy() {
  return (
    <>
      <div>
        <Header />
        <Container maxWidth="lg" sx={{ mt: 3, mb: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, border: 'none', boxShadow: 'none' }}>

                <Typography
                  // variant="h1" 
                  // component="h1" 
                  align="start"
                  sx={{ fontWeight: 'bold', fontSize: '2.5rem' }}
                  gutterBottom
                >
                  Privacy Policy
                </Typography>
                {/* <Typography 
             variant="h6" 
             component="h1" 
             align="center" 
             sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} 
             gutterBottom >
            //  variant="h5" component="h1" align="start" sx={{ fontWeight: 'bold', fontSize: '1.2rem' }} gutterBottom>
              Terms and Conditions
            </Typography> */}

                {/* Introduction text */}

                {/* List of terms with bold primary text and smaller font size */}
                <List>
                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>1. Acceptance of Terms</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>By using this website, you agree to be bound by these Terms and Conditions and any applicable laws and regulations.</Typography>}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>2. Use of Site</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>You are granted a limited, non-exclusive license to access and use the site. You agree not to misuse or disrupt the site in any way.</Typography>}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>3. Privacy Policy</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>Your use of the website is also governed by our Privacy Policy, which can be accessed separately.</Typography>}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>4. Limitation of Liability</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>We are not liable for any indirect, incidental, or consequential damages arising out of your use or inability to use this website.</Typography>}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>5. Governing Law</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>These terms will be governed by the laws of the jurisdiction in which the website owner is located.</Typography>}
                    />
                  </ListItem>

                  <ListItem>
                    <ListItemText
                      primary={<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>6. Modifications</Typography>}
                      secondary={<Typography variant="body2" sx={{ fontSize: '0.875rem' }}>We may update these terms and conditions from time to time, and your continued use of the site will signify your acceptance of those changes.</Typography>}
                    />
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <WebFooter />
      </div >
    </>
  );
}

export default PrivacyPolicy;
